import DAPsConfig from './_config/daps.config';
import DAPsRoutes from './_config/daps.routes';
import DAPsComponent from './components/daps';
import DAPsService from './services/daps.service';
import DAPsCommunicationService from './services/daps.communication.service';

import DAPFormComponent from './components/dap-form';
import DAPDetailComponent from './components/dap-detail';
import DapEnteteComponent from './components/dap-entete';
import DapGridComponent from './components/dap-grid';
import DAPAccuseAcceptationPopupComponent from './components/dap-accuse-acceptation-popup';
import DapAttenteGridController from './components/dap-attente-grid'
import DapAttenteFormController from './components/dap-attente-form';
import DapValidationModalComponent from './components/dap-validation-modal';

//Validateurs
import DAPValidator from './components/dap-form/dap.validator';
import DapEnteteValidator from './components/dap-entete/dap.entete.validator';

const moduleName = 'app.parametrageGeneral.daps';

angular
    .module(moduleName, [])
    .config(DAPsConfig)
    .config(DAPsRoutes)
    .service('DAPsService', DAPsService)
    .service('DAPsCommunicationService', DAPsCommunicationService)
    .component('daps', DAPsComponent)
    .component('dapForm', DAPFormComponent)
    .component('dapDetail', DAPDetailComponent)
    .component('dapEntete', DapEnteteComponent)
    .component('dapAttenteGrid', DapAttenteGridController)
    .component('dapAttenteForm', DapAttenteFormController)
    .component('dapGrid', DapGridComponent)
    .component('dapAccuseAcceptationPopup', DAPAccuseAcceptationPopupComponent)
    .component('dapValidationModal', DapValidationModalComponent)
    .factory('DAPValidator', DAPValidator)
    .factory('DapEnteteValidator', DapEnteteValidator);

export default moduleName;
