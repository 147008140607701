import { ApplicationFlag } from '../../../../../../core/enum/Application';

export default class AppellationsCommercialesController {
    static $inject = [
        '$state',
        '$stateParams',
        'ProduitsService',
        'ClientsDiversService',
        'ModalService',
        'notification',
        '$translate',
        'AppellationsCommercialesService',
        'MassiaApplicationService',
        '_'
    ];

    constructor(
        $state,
        $stateParams,
        ProduitsService,
        ClientsDiversService,
        ModalService,
        notification,
        $translate,
        AppellationsCommercialesService,
        MassiaApplicationService,
        _
    ) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ProduitsService = ProduitsService;
        this.ClientsDiversService = ClientsDiversService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this.AppellationsCommercialesService = AppellationsCommercialesService;
        this.MassiaApplicationService = MassiaApplicationService;
        this._ = _;
    }

    $onInit() {
        this.tmpAppCom = [];
        this.allSitesComBySociete = {};
        this.getSocietes();
        this.getSitesClients();
        this.getSitesProducteursByProduit();
        if (!this.produit.appelationCom || this.produit.appelationCom.length <= 0) {
            this.addAppellation();
        }
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.coordonnees.codeExists = await this.ProduitsService.codeExists(code);
                }
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                }
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.coordonnees.codeExists = null;
        }
    }
    async getSocietes() {
        //this.startLoading();
        try {
            if (this.app === 'performance') {
                this.listeSocietes = await this.ProduitsService.getSocietesProductrices();
            } else if (this.app === 'laboratoire') {
                this.listeSocietes = await this.ProduitsService.getSocietesLaboratoires();
            } else {
                this.listeSocietes = await this.ProduitsService.getSocietes();
            }
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            }
        } finally {
            //this.stopLoading();
        }
    }

    async getSitesClients() {
        this.listeClients = await this.AppellationsCommercialesService.getSiteClientsByName(null);
    }

	async getNomSitesClients(valeur, societeId) {
		try {
			const res = await this.ClientsDiversService.getNomSitesClients(valeur, societeId);
			return res;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}


    /* async getSitesProducteurs() {
        //this.startLoading();
        try {
            this.listeProducteurs = await this.ProduitsService.getProducteursList();
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            }
        } finally {
            //this.stopLoading();
        }
    } */
    getSitesProducteursByProduit() {
        this.listeProducteurs = this.produit.producteurs;
    }

    async getSitesCommerciaux(appellation) {
        try {
            if (appellation.societe) {
                if (this.allSitesComBySociete[appellation.societe.id]) {
                    this.listeSitesCommerciaux = this.allSitesComBySociete[appellation.societe.id];
                } else {
                    this.listeSitesCommerciaux = await this.ProduitsService.getSitesCommerciaux(appellation.societe.id);
                    this.allSitesComBySociete[appellation.societe.id] = this._.cloneDeep(this.listeSitesCommerciaux);
                }
            }
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            }
        }
    }

    checkApp(appelation, application) {
        return (appelation.application & ApplicationFlag[this._.toUpper(application)]) === ApplicationFlag[this._.toUpper(application)];
    }

    changeApp(variable, index, appli) {
        if (variable) {
            this.produit.appelationCom[index].application |= ApplicationFlag[this._.toUpper(appli)];
        } else {
            this.produit.appelationCom[index].application &= ~ApplicationFlag[this._.toUpper(appli)];
        }
    }

    addAppellation() {
        if (!this.produit.appelationCom) {
            this.produit.appelationCom = [];
        }
        const appelation = {
            isEdit: false,
            application: ApplicationFlag[this._.toUpper(this.app)],
            appelation: this.produit.appelationCom?.length === 0 ? this.produit.libelle : null,
            producteur: this.produit.producteurs?.length === 1 ? this.produit.producteurs[0] : null
        };
        this.produit.appelationCom.push(appelation);
    }

    editAppellation(appellation) {
        this.getSitesCommerciaux(appellation);
        appellation.isEdit = true;
    }

    async saveAppellation(appellation) {
        if (appellation.societe) {
            const r = this.listeSocietes.find((e) => e.id === appellation.societe.id);
            if (r) {
                appellation.societe.code = r.code;
                appellation.societe.libelle = r.libelle;
            }
        }

        if (appellation.siteUtilisateur) {
            const r = this.listeSitesCommerciaux.find((e) => e.id === appellation.siteUtilisateur.id);
            if (r) {
                appellation.siteUtilisateur.code = r.code;
                appellation.siteUtilisateur.libelle = r.libelle;
            }
        }

        if (appellation.siteProducteur) {
            const r = this.listeProducteurs.find((e) => e.id === appellation.siteProducteur.id);
            if (r) {
                appellation.siteProducteur.code = r.code;
                appellation.siteProducteur.libelle = r.libelle;
            }
        }

        appellation.isEdit = false;
    }
    async deleteAppellation(index) {
        this.tmpAppCom.splice(index, 1);
        this.produit.appelationCom.splice(index, 1);
    }
    /* startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    } */

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
