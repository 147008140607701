import angular from 'angular';

FacturesController.$inject = [
	'$http',
	'$window',
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'FacturesService',
	'notification',
	'_',
	'$uibModal',
	'DataShareService',
	'moment',
	'globalizationManagementService',
	'$transitions',
	'MOPService'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function FacturesController(
	$http,
	$window,
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	FacturesService,
	notification,
	_,
	$uibModal,
	DataShareService,
	moment,
	globalizationManagementService,
	$transitions,
	MOPService
) {
	const vm = this;
	const watchers = [];

	$scope.caseCocheePrint = false;
	$scope.caseCochee = false;

	let previousTableState;
	vm.previousTableStateForPrint = null;
	vm.tableState = null;
	let selectedFactureId = undefined;

	vm.itemsByPageOptions = [20, 100, 200, 1000];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm._ = _;
	vm.DataShareService = DataShareService;
	vm.factures = [];
	vm.isCheckAllFactures = false;
	vm.moment = moment;

	// initialisation de l'onglet recherche
	vm.isSearchOngletOpen = true;
	vm.searchingFormFactures = {};
	vm.searchingFormFactures.entity = 'facture';
	vm.savedPagination = {};
	vm.savedPagination.entity = 'pagination';
	vm.societesComLoaded = [];
	vm.sitesComLoaded = [];
	vm.searchingFormFactures.societeComIdSelected = null;
	vm.searchingFormFactures.siteComIdSelected = null;
	vm.MOPService = MOPService;

	vm.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;

	// initialise les dates de debut et fin au premier et dernier jour du mois en cours
	const maDate = new Date();
	const y = maDate.getFullYear();
	const m = maDate.getMonth();
	const firstDay = new Date(y, m, 1);
	const lastDay = new Date(y, m + 1, 0);
	vm.searchingFormFactures.dateDebut = vm.moment(firstDay).format(vm.dateFormat);
	vm.searchingFormFactures.dateFin = vm.moment(lastDay).format(vm.dateFormat);

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right'
	};
	vm.filters = [];
	vm.sorts = [];

	vm.selectFacture = selectFacture;
	vm.isFactureSelected = isFactureSelected;
	vm.showDetail = showDetail;
	// vm.closeDetail = closeDetail;
	vm.loadFactures = loadFactures;
	vm.deleteFacture = deleteFacture;
	vm.declotureFacture = declotureFacture;
	vm.clotureFacture = clotureFacture;
	vm.setSelected = setSelected;
	vm.checkAllFactures = checkAllFactures;
	vm.checkAllFacturesToPrint = checkAllFacturesToPrint;
	vm.deleteChecked = deleteChecked;
	vm.closeChecked = closeChecked;
	vm.checkPont = checkPont;
	vm.avoirRefactAutomatique = avoirRefactAutomatique;
	vm.$uibModal = $uibModal;
	// vm.printChecked = printChecked;
	vm.print = print;
	vm.reset = reset;
	vm.edit = edit;
	vm.exporter = exporter;
	vm.getSitesCom = getSitesCom;
	vm.cancel = cancel;

	vm.canAddFacture = false;
	vm.canAvoirAuto = false;
	vm.isLoadCloture = false;
	vm.getFacturesToPrint = getFacturesToPrint;
	vm.listFactureIdsToPrint = [];
	vm.previousvmSearchingFormFactures = null;
	vm.caseCochee = false;
	vm.checkFacturesToPrintWhenReloadGrille = checkFacturesToPrintWhenReloadGrille;
	vm.resetAllPrintSelection = resetAllPrintSelection;
	vm.getFactureIsAlreadyPrint = getFactureIsAlreadyPrint;
	vm.genererTraites = genererTraites;

	async function init() {
		vm.MOPService.setMop([{ title: 'FACTURES.BREADCRUMBS.FACTURES_LIST', filename: 'Facturation.pdf', application: 'gestion' }]);
		//écouter l'event lié au changement de route
		//une fois on change l'url, et on arrive sur la page "factures.list", on désélectionne la ligne sélectionnée (s'il y en a)
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		//$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		//quand on est sur la page ayant la vue détail ouverte, on présélectionne la ligne
		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}

		// s'il y a quelque chose dans le DataShare
		if (_.isEmpty(vm.DataShareService.savedData) == false) {
			// on cherche l'entité facture
			const item = _.find(vm.DataShareService.savedData, { entity: 'facture' });
			if (item != null) {
				await updateSearchForm();
			}
		}

		getDroits();
		getEtats();
		getTypes();
		await getSocietesCom();
		await getSitesCom();

		// si en revenant d'une facture on a des données, on reload les factures
		if (vm.searchingFormFactures.societeComIdSelected != 0 && vm.searchingFormFactures.siteComIdSelected != 0) {
			await vm.loadFactures(null);
		}
	}

	init();

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	// recuperation des donnees du DataShare
	async function updateSearchForm() {
		vm.searchingFormFactures = vm.DataShareService.getDataShare('facture');
	}

	async function getSocietesCom() {
		vm.societesComLoaded = await FacturesService.getSocietesCom();
		// SL Mantis 387 RG 1
		if (vm.societesComLoaded.length == 1) {
			vm.searchingFormFactures.societeComIdSelected = vm.societesComLoaded[0].id;
		}
	}

	async function getSitesCom() {
		vm.sitesComLoaded = await FacturesService.getSitesCom(vm.searchingFormFactures.societeComIdSelected);
		// SL Mantis 387 RG 2
		// if (vm.sitesComLoaded.length == 1) {
		//     vm.searchingFormFactures.siteComIdSelected = vm.sitesComLoaded[0].id;
		// }
	}

	async function getEtats() {
		startLoading();
		try {
			const etattmp = await FacturesService.getEtats();
			const index = etattmp.findIndex((x) => x.id === 2); //enlever etat facture imprimée
			etattmp.splice(index, 1);
			vm.listeEtats = etattmp;
		} catch (err) {
			if (err.data) {
				notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			stopLoading();
		}
	}

	async function getTypes() {
		startLoading();
		try {
			vm.listeTypes = await FacturesService.getTypes();
		} catch (err) {
			if (err.data) {
				notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			stopLoading();
		}
	}

	async function getDroits() {
		startLoading();
		try {
			const droits = await FacturesService.getDroits();
			if (droits.hasAvoirManuel || droits.hasFactureManuel) {
				vm.canAddFacture = true;
			}
			if (droits.hasAvoirAuto) {
				vm.canAvoirAuto = true;
			}
		} catch (err) {
			if (err.data) {
				notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			stopLoading();
		}
	}

	function stateChangeSuccessFunc(event, toState) {
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	function setSelected(facture) {
		if (facture && facture.id) {
			selectedFactureId = $stateParams.id;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			selectedFactureId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return $state && $state.current && $state.current.name && $state.current.name === 'factures.list.detail' && $stateParams && $stateParams.id;
	}

	function selectFacture(facture) {
		if (facture && facture.id) {
			//si c'est une nouvelle ligne qui a été sélectionnée, on update la variable locale selectedFactureId, et charger la vue détail pour le facture sélectionné
			if (vm.selectedFactureId !== facture.id) {
				vm.selectedFactureId = facture.id;
				showDetail(facture.id);
			} else {
				//si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
				vm.selectedFactureId = undefined;
				// closeDetail();
			}
		}
	}

	function isFactureSelected(facture) {
		return facture && facture.id && vm.selectedFactureId === facture.id;
	}

	function showDetail(factureId) {
		// updateDataShare
		vm.DataShareService.setDataShare(vm.searchingFormFactures, 'facture');
		$state.go('factures.detail', { id: factureId });
	}

	function edit(facture) {
		// updateDataShare
		vm.DataShareService.setDataShare(vm.searchingFormFactures, 'facture');
		$state.go('factures.edit', { id: facture.id });
	}

	async function loadFactures(tableState) {
		startLoading();

		//reset des FacturesToPrint si nouvelle recherche
		const isEqualSearchingForm = vm._.isEqual(vm.previousvmSearchingFormFactures, vm.searchingFormFactures);
		if (!isEqualSearchingForm) {
			vm.resetAllPrintSelection();
		}

		if (tableState) {
			vm.tableState = tableState;
		}
		vm.caseCochee = false;

		// gestion de la sauvegarde et de la restitution de la pagination
		const objectToManage = {};
		objectToManage.tableState = tableState;
		objectToManage.savedPagination = vm.savedPagination;
		objectToManage.itemsByPageOptions = vm.itemsByPageOptions;
		objectToManage.itemsByPage = vm.itemsByPage;
		const resultObject = await vm.DataShareService.managePaginationContextuelle(objectToManage);
		if (resultObject) {
			tableState = resultObject.tableState;
			vm.savedPagination = resultObject.savedPagination;
			vm.itemsByPageOptions = resultObject.itemsByPageOptions;
			vm.itemsByPage = resultObject.itemsByPage;
		}

		// updateDataShare
		vm.DataShareService.setDataShare(vm.searchingFormFactures, 'facture');

		if ($state.params.obj) {
			try {
				const data = await FacturesService.getFacturesFromFacturation($state.params.obj);
				vm.factures = data;

				if (previousTableState) {
					PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
				}
			} catch (ex) {
				notification.error(ex.data);
			} finally {
				stopLoading();
			}
		} else if ($state) {
			if (tableState) {
				previousTableState = tableState;
			}

			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const pagination = PaginationService.getPagination(previousTableState);

			vm.factures = [];

			try {
				vm.filters = filters;
				vm.sorts = sorts;
				const data = await FacturesService.getFactures(
					filters,
					sorts,
					pagination,
					vm.searchingFormFactures.societeComIdSelected,
					vm.searchingFormFactures.siteComIdSelected,
					vm.searchingFormFactures.dateDebut,
					vm.searchingFormFactures.dateFin
				);

				vm.factures = data.items;

				const isFaturesToPrint = vm.listFactureIdsToPrint.length > 0;
				if (isFaturesToPrint) {
					vm.checkFacturesToPrintWhenReloadGrille();
				}

				if (previousTableState) {
					PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
				}
				vm.totalFacturesCount = data.total;
			} catch (ex) {
				notification.error(ex.data);
			} finally {
				stopLoading();
			}
		}

		//sauvegardede la recherche courant pour print
		vm.previousvmSearchingFormFactures = angular.copy(vm.searchingFormFactures);
	}

	async function deleteFacture(facture) {
		if (facture && facture.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('FACTURES.DELETE.TITLE', { code: facture.numero }),
				modalMsg: $translate.instant('FACTURES.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await FacturesService.deleteFactureById(facture.id);
					notification.success($translate.instant('FACTURES.DELETE.SUCCESS'));

					selectedFactureId = undefined;

					// si jamais la vue détail est affichée,
					// on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
					if (vm.params.id) {
						vm.state.go('factures.list');
					}

					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

					await vm.loadFactures(null);
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function declotureFacture(facture) {
		if (facture && facture.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('FACTURES.DECLOTURE.TITLE', { code: facture.numero }),
				modalMsg: $translate.instant('FACTURES.DECLOTURE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await FacturesService.declotureFactureById(facture.id);
					notification.success($translate.instant('FACTURES.DECLOTURE.SUCCESS'));

					selectedFactureId = undefined;

					// si jamais la vue détail est affichée,
					// on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
					if (vm.params.id) {
						vm.state.go('factures.list');
					}

					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

					await vm.loadFactures(null);
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function clotureFacture(facture, isRecloture) {
		if (facture && facture.id) {
			let message = $translate.instant('FACTURES.CLOTURE.MESSAGE');
			if (isRecloture) {
				message = $translate.instant('FACTURES.CLOTURE.MESSAGE_DECLOTURE');
			}
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('FACTURES.CLOTURE.TITLE', { code: facture.numero }),
				modalMsg: message,
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					const listFactureIdsToClose = [];
					listFactureIdsToClose.push(facture.id);
					const result = await FacturesService.closeFacturesChecked(
						listFactureIdsToClose,
						true,
						vm.searchingFormFactures.societeComIdSelected
					);
					notification.success($translate.instant('FACTURES.CLOTURE.SUCCESS'));

					selectedFactureId = undefined;

					// si jamais la vue détail est affichée,
					// on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
					if (vm.params.id) {
						vm.state.go('factures.list');
					}

					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

					await vm.loadFactures(null);
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}

	function print() {
		const _this = vm;

		vm.$uibModal
			.open({
				template: '<factures-print source="$ctrl.listFactureIdsToPrint" modal-instance="$ctrl.uibModalInstance"></factures-print>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.listFactureIdsToPrint = _this.listFactureIdsToPrint;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'l'
			})
			.result.then(
				function (result) {
					if (result) {
						vm.getFactureIsAlreadyPrint();
					}
					// console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
					// console.info(result);
				},
				function (reason) {
					vm.cancel();
					// console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
				}
			);
	}

	async function cancel() {
		try {
			await FacturesService.cancelPrint();
		} catch (e) {
			notification.error(e);
		}
	}

	//lors du rechargement de la liste
	function checkFacturesToPrintWhenReloadGrille() {
		let areAllSelected; //Flag pour la checkbox

		vm._.forEach(vm.factures, (f) => {
			areAllSelected = false;

			const isSelectedtoPrint = vm.listFactureIdsToPrint.some((idFactureToPrint) => idFactureToPrint === f.id);
			if (isSelectedtoPrint && f.isPrintable) {
				f.isCheckedToPrint = true;
				areAllSelected = true;
			}
		});
		$scope.caseCocheePrint = areAllSelected;
	}

	//lors de la selection manuelle
	function getFacturesToPrint(facture) {
		const isAllreadySelected = vm.listFactureIdsToPrint.some((idFactureToPrint) => idFactureToPrint === facture.id);

		//facture deja dans la liste et selectionnée lors d'un checkAllfactures // On ne change rien
		if (isAllreadySelected && facture.isCheckedToPrint) {
			return;
		}

		//facture deja dans la liste mais pas selectionnée // On supprimme
		if (isAllreadySelected && !facture.isCheckedToPrint) {
			vm.listFactureIdsToPrint = vm.listFactureIdsToPrint.filter((idFactureToPrint) => idFactureToPrint !== facture.id);
			$scope.caseCocheePrint = false;
		} else {
			//facture pas selectionné lors d'un checkAllfactures // On ajoute a la liste
			vm.listFactureIdsToPrint.push(facture.id);
		}
	}

	//lors de la selection avec la case select all facture
	function checkAllFacturesToPrint(isChecked) {
		vm._.forEach(vm.factures, (facture) => {
			if (facture.isPrintable) {
				//coche les checkbox prints des factures affichées
				facture.isCheckedToPrint = isChecked;

				//copie toutes les ids des facture pour le print
				vm.getFacturesToPrint(facture);
			}
		});
	}

	function resetAllPrintSelection() {
		vm.listFactureIdsToPrint = [];
		$scope.caseCocheePrint = false;
	}

	function checkAllFactures(value) {
		vm.factures.forEach(function (element) {
			if (element.isModifiable) {
				element.isChecked = value;
			}
		}, this);

		this.isCheckAllFactures = value;
	}

	function getFactureIsAlreadyPrint() {
		vm.listFactureIdsToPrint.forEach(function (element) {
			const factureSelected = vm.factures.find((x) => x.id == element);
			if (factureSelected.isCloture || factureSelected.isComptabilise) {
				const test = factureSelected;
				test.isAlreadyPrint = true;
			}
		}, this);
	}

	function deleteChecked() {
		const listFactureIdsToDelete = [];
		vm.factures.forEach(function (element) {
			if (element.isChecked == true) {
				listFactureIdsToDelete.push(element.id);
			}
		}, this);

		if (listFactureIdsToDelete.length > 0) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('FACTURES.DELETECHECKED.TITLE'),
				modalMsg: $translate.instant('FACTURES.DELETECHECKED.MESSAGE'),
				headerClass: 'modal-danger'
			});
			modalInstance.result.then(async function () {
				startLoading();
				try {
					await FacturesService.deleteFacturesChecked(listFactureIdsToDelete);
					notification.success($translate.instant('FACTURES.DELETECHECKED.SUCCESS'));

					if (vm.params.id) {
						vm.state.go('factures.list');
					}

					await vm.loadFactures(null);
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		} else {
			notification.info($translate.instant('FACTURES.DELETECHECKED.NOCHECKED'));
		}
	}

	function closeChecked() {
		const listFactureIdsToClose = [];
		vm.factures.forEach(function (element) {
			if (element.isChecked == true) {
				listFactureIdsToClose.push(element.id);
			}
		}, this);

		if (listFactureIdsToClose.length > 0) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('FACTURES.CLOSECHECKED.TITLE'),
				modalMsg: $translate.instant('FACTURES.CLOSECHECKED.MESSAGE'),
				headerClass: 'modal-danger'
			});
			modalInstance.result.then(async function () {
				startLoading();
				vm.isLoadCloture = true;
				try {
					const result = await FacturesService.closeFacturesChecked(
						listFactureIdsToClose,
						true,
						vm.searchingFormFactures.societeComIdSelected
					);
					vm.isLoadCloture = false;
					if (result.isPontOk) {
						notification.success($translate.instant('FACTURES.CLOSECHECKED.SUCCESS'));
						if (vm.params.id) {
							vm.state.go('factures.list');
						}

						await vm.loadFactures(null);
					} else {
						// ouvrir la pop up
						// using result.listeArticlesToFix
						const res = await open(result.listeArticlesToFix, true);
					}
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		} else {
			notification.info($translate.instant('FACTURES.CLOSECHECKED.NOCHECKED'));
		}
	}

	async function checkPont() {
		const listFactureIdsToClose = [];
		vm.factures.forEach(function (element) {
			if (element.idEtatFacture == 0) {
				listFactureIdsToClose.push(element.id);
			}
		}, this);

		startLoading();
		try {
			const result = await FacturesService.closeFacturesChecked(
				listFactureIdsToClose,
				false,
				vm.searchingFormFactures.societeComIdSelected,
				vm.searchingFormFactures.siteComIdSelected,
				vm.searchingFormFactures.dateDebut,
				vm.searchingFormFactures.dateFin
			);

			if (result.isPontOk) {
				notification.info($translate.instant('FACTURES.PONT_OK'));
			} else {
				// ouvrir la pop up
				// using result.listeArticlesToFix
				const res = await open(result.listeArticlesToFix, false);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	function open(listeArticlesToFix, withCloture) {
		const _this = vm;
		_this.source = {};
		_this.source.listeArticlesToFix = listeArticlesToFix;
		_this.source.idSiteCom = vm.searchingFormFactures.siteComIdSelected;
		_this.source.withCloture = withCloture;

		vm.$uibModal
			.open({
				template: '<factures-checkpont source="$ctrl.source" modal-instance="$ctrl.uibModalInstance"></factures-checkpont>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.source = _this.source;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			})
			.result.then(
				function (result) {
					// console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
					// console.info(result);
				},
				function (reason) {
					// console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
				}
			);
	}

	function avoirRefactAutomatique(facture, type) {
		const _this = this;
		_this.type = type;
		_this.dateGenFactOrig = facture.dateGeneration;

		if (facture && facture.id) {
			this.$uibModal
				.open({
					template:
						'<facture-popup id-societe="$ctrl.idSociete" numero="$ctrl.numero" client-id="$ctrl.clientId" client="$ctrl.client" chantier="$ctrl.chantier" chantier-id="$ctrl.chantierId" date-gen-fact-orig="$ctrl.dateGenFactOrig" type="$ctrl.type" modal-instance="$ctrl.uibModalInstance"></facture-popup>',
					controller: [
						'$uibModalInstance',
						function ($uibModalInstance) {
							const $ctrl = this;
							$ctrl.uibModalInstance = $uibModalInstance;
							$ctrl.type = type;
							$ctrl.dateGenFactOrig = facture.dateGeneration;
							$ctrl.idSociete = facture.idSociete;
							$ctrl.numero = facture.code;
							$ctrl.clientId = facture.idClient;
							$ctrl.client = facture.nomClient;
							$ctrl.chantier = facture.nomChantier;
							$ctrl.chantierId = facture.idChantier;
						}
					],
					controllerAs: '$ctrl',
					size: 'xl',
					backdrop: false
				})
				.result.then(
					async function (result) {
						const data = {};
						data.idFacture = facture.id;
						data.dateAvoir = result.dateAvoir;
						data.dateRefacturation = result.dateRefacturation;
						data.idClient = result.idClient;
						data.idChantier = result.idChantier;
						data.canEdit = result.canEdit;
						if (type == 'refacturation') {
							data.refacturationToDo = true;
							data.idTypeFacture = 3; // Avoir Automatique sur Quantité
						} else {
							data.refacturationToDo = false;
							data.idTypeFacture = result.idTypeFacture; // celui qu'on choisit sur la pop-up
						}

						if (result) {
							try {
								const idNewFacture = await FacturesService.avoirRefactAutomatique(data);
								if (data.refacturationToDo) {
									notification.success($translate.instant('FACTURES.POPUP.SUCCESSREFACT'));
									$state.go('factures.edit', { id: idNewFacture });
								} else {
									notification.success($translate.instant('FACTURES.POPUP.SUCCESSAVOIR'));
									if (result.canEdit) {
										$state.go('factures.edit', { id: idNewFacture });
										console.log('🚀 ~ file: factures.controller.js:812 ~ idNewFacture:', idNewFacture);
									}
								}
								await vm.loadFactures(null);
							} catch (ex) {
								notification.error(ex.data);
							} finally {
							}
						}
					},
					function (reason) {}
				);
		}
	}

	function reset() {
		vm.resetAllPrintSelection();

		vm.searchingFormFactures.societeComIdSelected = null;
		vm.searchingFormFactures.siteComIdSelected = null;

		// initialise les dates de debut et fin au premier et dernier jour du mois en cours
		const maDate = new Date();
		const y = maDate.getFullYear();
		const m = maDate.getMonth();
		const firstDay = new Date(y, m, 1);
		const lastDay = new Date(y, m + 1, 0);
		vm.searchingFormFactures.dateDebut = vm.moment(firstDay).format(vm.dateFormat);
		vm.searchingFormFactures.dateFin = vm.moment(lastDay).format(vm.dateFormat);
		vm.factures = [];
		getSocietesCom();

		// updateDataShare
		vm.DataShareService.setDataShare(vm.searchingFormFactures, 'facture');
	}

	async function exporter(modeImpression) {
		try {
			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const fileName = 'Liste_factures';
			let resultat = undefined;
			try {
				resultat = await FacturesService.exportListeFactures(
					modeImpression,
					vm.searchingFormFactures.societeComIdSelected,
					vm.searchingFormFactures.siteComIdSelected,
					vm.searchingFormFactures.dateDebut,
					vm.searchingFormFactures.dateFin,
					filters,
					sorts
				);
			} catch (ex) {
				//si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
				const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
				notification.error('FACTURES.' + msgException);
				return false;
			}
			if (resultat) {
				const data = resultat.data;
				const status = resultat.status;
				let headers = resultat.headers;
				headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');
				try {
					const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
					const url = window.URL.createObjectURL(blob);
					linkElement.setAttribute('href', url);
					if (modeImpression == 0) {
						linkElement.setAttribute('download', fileName + '.pdf');
					} else {
						linkElement.setAttribute('download', fileName + '.xlsx');
					}

					const clickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false
					});
					linkElement.dispatchEvent(clickEvent);
				} catch (ex) {
				} finally {
				}
				return true;
			}
		} catch (ex) {
			notification.error(ex.data);
			return false;
		}
	}

	async function genererTraites() {
		startLoading();
		try {
			console.log('test');
			const fileName = Date.now();
			const resultat = await FacturesService.genererTraites(
				vm.filters,
				vm.sorts,
				vm.searchingFormFactures.societeComIdSelected,
				vm.searchingFormFactures.siteComIdSelected,
				vm.searchingFormFactures.dateDebut,
				vm.searchingFormFactures.dateFin
			);

			const data = resultat.data;
			const status = resultat.status;
			let headers = resultat.headers;

			headers = headers();

			const contentType = headers['content-type'];

			const linkElement = document.createElement('a');

			const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
			const url = window.URL.createObjectURL(blob);
			linkElement.setAttribute('href', url);
			linkElement.setAttribute('download', fileName + '.txt');

			const clickEvent = new MouseEvent('click', {
				view: window,
				bubbles: true,
				cancelable: false
			});
			linkElement.dispatchEvent(clickEvent);
		} catch (ex) {
			console.log(ex.data);
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}
}
