export default class FactureComposantController {
	static $inject = ['$scope', '$stateParams', 'FacturesCommunicationService', 'FacturesService', 'notification', '$uibModal'];

	constructor($scope, $stateParams, FacturesCommunicationService, FacturesService, notification, $uibModal) {
		this.$scope = $scope;
		this.$stateParams = $stateParams;
		this.FacturesCommunicationService = FacturesCommunicationService;
		this.FacturesService = FacturesService;
		this.notification = notification;
		this.$uibModal = $uibModal;
	}

	async $onInit() {
		this.unregister = this.$scope.$watch(
			() => this.isOpen,
			() => {
				if (this.isOpen && !this.factures) {
					this.loadFactures();
				}
			},
			true
		);
	}

	$onDestroy() {
		this.unregister();
	}

	async loadFactures() {
		if (this.id) {
			this.startLoading();
			try {
				const data = await this.FacturesService.getFactureComposant(this.id, this.domaine);
				this.factures = data.factures;
				// this.poidsTotalEntree = data.poidsTotalEntree;
				// this.poidsTotalSortie = data.poidsTotalSortie;
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
			this.stopLoading();
		}
	}

	// async exporter() {
	//     try {
	//         this.startLoading();
	//         this.isLoadExport = true;
	//         const fileName = 'Liste_tickets';
	//         let resultat = undefined;
	//         try {
	//             resultat = await this.TicketsService.exportTicketComposants(this.id, this.domaine);
	//         } catch (ex) {
	//             //si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
	//             const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
	//             this.notification.error('TICKETS.' + msgException);
	//             return false;
	//         }
	//         if (resultat) {
	//             const data = resultat.data;
	//             const status = resultat.status;
	//             let headers = resultat.headers;
	//             headers = headers();

	//             const contentType = headers['content-type'];

	//             const linkElement = document.createElement('a');
	//             try {
	//                 const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
	//                 const url = window.URL.createObjectURL(blob);
	//                 linkElement.setAttribute('href', url);
	//                 linkElement.setAttribute('download', fileName + '.xlsx');

	//                 const clickEvent = new MouseEvent('click', {
	//                     view: window,
	//                     bubbles: true,
	//                     cancelable: false
	//                 });
	//                 linkElement.dispatchEvent(clickEvent);
	//             } catch (ex) {
	//             } finally {
	//             }
	//             return true;
	//         }
	//     } catch (ex) {
	//         this.notification.error(ex.data);
	//         return false;
	//     } finally {
	//         this.stopLoading();
	//         this.isLoadExport = false;
	//     }
	// }

	openFacture(facture) {
		this.$uibModal
			.open({
				template: '<facture-detail modal-instance="$ctrl.uibModalInstance" id="$ctrl.id"></facture-detail>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.uibModalInstance = $uibModalInstance;
						$ctrl.id = facture;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			})
			.result.then(
				async function (result) {
					// console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
					// console.info(result);
					if (result) {
					}
				},
				function (reason) {}
			);
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}
