import FactureComposantController from './facture.composant.controller';

export default {
	bindings: {
		id: '=',
		domaine: '=',
		isOpen: '=',
		onUpdate: '&'
	},
	template: require('./facture.composant.html'),
	controller: FactureComposantController
};
