import DapValidationModalController from './dap-validation-modal.controller';

export default {
    bindings: {
        modalInstance: '=',
        resolve: '<',
    },
    template: require('./dap-validation-modal.html'),
    controller: DapValidationModalController
};
