export default class TicketDetailController {
	static $inject = [
		'$stateParams',
		'$state',
		'TicketsService',
		'notification',
		'TicketsCommunicationService',
		'DataShareService',
		'ModalService',
		'$translate',
		'$uibModal'
	];

	constructor(
		$stateParams,
		$state,
		TicketsService,
		notification,
		TicketsCommunicationService,
		DataShareService,
		ModalService,
		$translate,
		$uibModal
	) {
		this.$stateParams = $stateParams;
		this.$state = $state;
		this.TicketsService = TicketsService;
		this.notification = notification;
		this.TicketsCommunicationService = TicketsCommunicationService;
		this.DataShareService = DataShareService;
		this.ModalService = ModalService;
		this.$translate = $translate;
		this.$uibModal = $uibModal;
		this.hasCustomFieldEnabled = false;
		this.customFieldHeader = undefined;
	}

	$onInit() {
		this.loading = false;
		this.ticket = {};
		this.idTicket = this.$stateParams.id;
		if (this.ticketFromPreFa) {
			this.idTicket = this.ticketFromPreFa;
		}

		this.newPrestation = {
			idTicket: this.idTicket
		};

		this.openAllOnglets();

		this.initTicket();
	}

	async initTicket() {
		this.ticket = {
			id: this.idTicket
		};

		if (this.ticketFromPreFa) {
			this.ticket.id = this.ticketFromPreFa;
		}

		if (this.ticket.id) {
			this.startLoading();
			this.customFieldHeader = await this.TicketsService.getCustomFieldHeader();
			if (this.customFieldHeader) {
				this.hasCustomFieldEnabled = true;
			}

			try {
				// on ajoute await afin de ne pas passer trop rapidement dans le finally
				// et ainsi on garde le loading le temps nécessaire
				this.ticket.dataById = await this.TicketsService.getTicketById(this.ticket.id);
				console.log('TICKET:', this.ticket.dataById);
				// SL pour gerer le champs pesee automatique/manuelle
				const a = this.ticket.dataById.isAutomatique;
				const b = this.ticket.dataById.isAutomatiqueBorne;
				if (a || b) {
					this.ticket.dataById.peseeAutomatique = true;
				}
				this.producteurs = await this.TicketsService.getSiteProducteurs();

				this.isEditRemarqueClient = false;
				this.isEditRemarqueUtilisateur = false;
				this.newRemarqueClient = this.ticket.dataById.remarqueClient;
				this.newRemarqueUtilisateur = this.ticket.dataById.remarqueUtilisateur;

				this.isTicketModifiable = true;
				this.isTicketFacture = false;
				if (this.ticket.dataById.isFacture) {
					this.isTicketModifiable = false;
					this.isTicketFacture = true;
				}
				if (this.ticket.dataById.isAnnule) {
					this.isTicketModifiable = false;
				}
			} catch (ex) {
				if (ex.status === 404) {
					this.goListTicket();
				}
				this.notification.error(ex.data);
			} finally {
				this.stopLoading();
			}
		} else {
			this.goListTicket();
		}
	}

    startEditRemarqueClient(){
      this.isEditRemarqueClient = true;
    }

    async saveEditRemarqueClient(){
      this.isEditRemarqueClient = false;
	  this.ticket.dataById.remarqueClient = this.newRemarqueClient;
	  await this.updateRemarques();
    }

    cancelEditRemarqueClient(){
      this.isEditRemarqueClient = false;
	  this.newRemarqueClient = this.ticket.dataById.remarqueRemarqueClient;
	  this.initTicket();
    }

    startEditRemarqueUtilisateur(){
      this.isEditRemarqueUtilisateur = true;
    }

    async saveEditRemarqueUtilisateur(){
      this.isEditRemarqueUtilisateur = false;
	  this.ticket.dataById.remarqueUtilisateur = this.newRemarqueUtilisateur;
	  await this.updateRemarques();
    }

    cancelEditRemarqueUtilisateur(){
      this.isEditRemarqueUtilisateur = false;
	  this.newRemarqueUtilisateur = this.ticket.dataById.remarqueUtilisateur;
	  this.initTicket();
    }

	goListTicket() {
		this.$state.go('tickets.list', {}, { reload: true });
	}

	async changeType() {
		let type = 'prestation';
		if (this.newPrestation.type == 1) {
			type = 'transport';
		}
		this.prestations = await this.TicketsService.getPrestations(type);
	}

	initUpdateDap() {
		this.getDap();
		this.isDapUpdate = true;
	}

	async updateDap() {
		if (this.idTicket) {
			try {
				await this.TicketsService.updateDap(this.idTicket, this.ticket.dataById.idDap);
				this.notification.success(this.$translate.instant('TICKETS.UPDATE_DAP'));
			} catch (ex) {
				this.notification.error(ex.data);
			} finally {
				this.initTicket();
				this.isDapUpdate = false;
			}
		}
	}

	annuleDap() {
		this.isDapUpdate = false;
	}

	async addPrestation() {
		if (this.newPrestation && this.newPrestation.id && this.newPrestation.quantite) {
			this.startLoading();
			try {
				await this.TicketsService.createPrestation(this.newPrestation);
				this.newPrestation = {
					idTicket: this.idTicket
				};
			} catch (ex) {
				this.notification.error(ex.data);
				return false;
			} finally {
				this.initTicket();
				this.stopLoading();
			}
		}
	}

	async deletePrestation(prestation) {
		if (prestation && prestation.id) {
			const vm = this;
			const modalInstance = this.ModalService.confirm({
				modalTitle: this.$translate.instant('TICKETS.PRESTATION_DELETE.TITLE'),
				modalMsg: this.$translate.instant('TICKETS.PRESTATION_DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				try {
					await vm.TicketsService.deletePrestationById(prestation.id);
					vm.notification.success(vm.$translate.instant('TICKETS.PRESTATION_DELETE.SUCCESS'));
					vm.initTicket();
				} catch (ex) {
					if (ex.status == 500) {
						vm.notification.error('TICKETS.PRESTATION_DELETE.NOT_DELETABLE');
					}
				} finally {
				}
			});
		}
	}

	async deleteLienTicketRechargement() {
		if (this.ticket && this.ticket.id) {
			const vm = this;
			const modalInstance = this.ModalService.confirm({
				modalTitle: this.$translate.instant('TICKETS.RECHARGEMENT_DELETE.TITLE'),
				modalMsg: this.$translate.instant('TICKETS.RECHARGEMENT_DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				try {
					await vm.TicketsService.deleteLienTicketRechargement(vm.ticket.id);
					vm.notification.success(vm.$translate.instant('TICKETS.RECHARGEMENT_DELETE.SUCCESS'));
					vm.initTicket();
				} catch (ex) {
					if (ex.status == 500) {
						vm.notification.error('TICKETS.RECHARGEMENT_DELETE.NOT_DELETABLE');
					}
				} finally {
				}
			});
		}
	}

	activeEditMode(factProd) {
		if (this.isTicketFacture) {
			factProd.isEditModeFacture = true;
		} else {
			factProd.isEditMode = true;
		}
		this.getTva();
		this.getTypePrix();
	}

	desactiveEditMode(factProd) {
		if (this.isTicketFacture) {
			factProd.isEditModeFacture = false;
		} else {
			factProd.isEditMode = false;
		}
		this.initTicket();
	}

	changePrixUnitaire(factProd) {
		factProd.isPrixUnitaireUpdated = true;
	}

	async getDap() {
		if (!this.daps) {
			this.startLoading();
			try {
				this.daps = await this.TicketsService.getDap(
					this.ticket.dataById.idTransporteur,
					this.ticket.dataById.idChantier,
					this.ticket.dataById.idLieu
				);
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
				this.stopLoading();
			}
		}
	}

	async getTva() {
		if (!this.tvas) {
			this.startLoading();
			try {
				this.tvas = await this.TicketsService.getTva();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
				this.stopLoading();
			}
		}
	}

	async getTypePrix() {
		if (!this.typePrix) {
			this.startLoading();
			try {
				this.typePrix = await this.TicketsService.getTypePrix();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
				this.stopLoading();
			}
		}
	}

	async updatePrix(factProd) {
		if (factProd && factProd.id) {
			this.startLoading();
			try {
				await this.TicketsService.updatePrix(factProd, this.idTicket);
				this.notification.success(this.$translate.instant('TICKETS.PRESTATION_UPDATE.SUCCESS'));
				this.initTicket();
			} catch (ex) {
				this.notification.error(ex.data);
			} finally {
				this.stopLoading();
			}
		}
	}

	async updateRemarques() {
		try {
			await this.TicketsService.updateRemarques(this.idTicket, this.ticket.dataById.remarqueClient, this.ticket.dataById.remarqueUtilisateur);
			this.notification.success(this.$translate.instant('TICKETS.REMARQUE_UPDATED'));
			this.initTicket();
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	openFacture(idFacture) {
		this.$uibModal
			.open({
				template: '<facture-detail modal-instance="$ctrl.uibModalInstance" id="$ctrl.idFacture"></facture-detail>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.uibModalInstance = $uibModalInstance;
						$ctrl.idFacture = idFacture;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			})
			.result.then(
				async function (result) {
					// console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
					// console.info(result);
					if (result) {
					}
				},
				function (reason) {}
			);
	}

	openFirstOnglet() {
		this.ongletOpen = {
			isEnteteOpen: true,
			isDestinataireOpen: false,
			isTransportOpen: false,
			isValorisationOpen: false
		};
	}
	closeAllOnglets() {
		this.ongletOpen = {
			isEnteteOpen: false,
			isDestinataireOpen: false,
			isTransportOpen: false,
			isValorisationOpen: false
		};
	}
	openAllOnglets() {
		this.ongletOpen = {
			isEnteteOpen: true,
			isDestinataireOpen: true,
			isTransportOpen: true,
			isValorisationOpen: true
		};
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}
