import * as angular from 'angular';
import { Chart } from 'chart.js';
import numeral from 'numeral';
import { Socket } from 'socket.io-client';

export default class DynamicFormController {
	static $inject = [
		'$scope',
		'$timeout',
		'$translate',
		'_',
		'NatureInputTypeMapping',
		'FormulesService',
		'MesuresService',
		'DocumentsService',
		'ColorPickerService',
		'$uibModal',
		'NatureEnum',
		'notification',
		'SocketService',
		'Interpreteur',
		'MassiaRightsService',
		'ModalService'
	];

	constructor(
		$scope,
		$timeout,
		$translate,
		_,
		NatureInputTypeMapping,
		FormulesService,
		MesuresService,
		DocumentsService,
		ColorPickerService,
		$uibModal,
		NatureEnum,
		notification,
		SocketService,
		Interpreteur,
		MassiaRightsService,
		ModalService
	) {
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$translate = $translate;
		this._ = _;
		this.NatureInputTypeMapping = NatureInputTypeMapping;
		this.FormulesService = FormulesService;
		this.MesuresService = MesuresService;
		this.DocumentsService = DocumentsService;
		this.ColorPickerService = ColorPickerService;
		this.$uibModal = $uibModal;
		this.timer = null;
		this.enumNature = NatureEnum;
		this.notification = notification;
		this.SocketService = SocketService;
		this.Interpreteur = Interpreteur;
		this.MassiaRightsService = MassiaRightsService;
		this.ModalService = ModalService;
	}

	async $onInit() {
		this.graphId = 'granulograph';
		this.setGranuloGraphId();
		this.dragOptions = {
			placeholder: 'table-row-ui-placeholder',
			revert: true,
			animation: 200,
			axis: 'y',
			'ui-preserve-size': true
		};
		this.unregister = [];
		this.seuilsRefus = false;

		if (this.field) {
			this.fieldId = this.field.id;

			this.field.labelCls = this.field.labelCls || 'col-xs-4 col-sm-3';
			this.field.inputCls = this.field.inputCls || 'col-xs-6 col-sm-7';
		}
		if (this.field && this.model) {
			if (!this.model.hasOwnProperty(this.fieldId) && this.model.hasOwnProperty('_' + this.fieldId)) {
				this.fieldId = '_' + this.fieldId;
			}

			this.setModelConfig();
			await this.setModelValue();
		}

		if (this.displayLabel === undefined) {
			this.displayLabel = true;
		}
		if (this.nature === 'AnalyseGranulo' && this.rightImportGranulo) {
			this.unregister.push(
				this.$scope.$watch(
					() => this.model[this.fieldId],
					async () => {
						this.recalculeGranulo(true);
					}
				)
			);
		}
		this.accesBalance();
		this.oldModel = angular.copy(this.model);
	}

	async accesBalance() {
		this.colonneTamis = [];
		this.canAccessBalance = await this.MassiaRightsService.userHasRight('configbalance', 'read', 'laboratoire');
		/*  if (this.canAccessBalance) {
             let data = await this.ColonneTamisService.getAll([], [], { skip: 0, take: 100000 });
             this.colonneTamis = data.items;
         } */
	}

	$onDestroy() {
		if (this.timer !== undefined) {
			this.$timeout.cancel(this.timer);
			this.timer = undefined;
		}

		for (let i = 0; i < this.unregister.length; i++) {
			this.unregister[i]();
		}
	}

	getDynamicTpl() {
		let templateUrl = 'form-dynamic/readonly.tpl.html';

		if (!this.readonly) {
			const templateInputName = this.getInputType();
			if (templateInputName) {
				templateUrl = `form-dynamic/field-template.${templateInputName}.html`;
			}
		}
		return templateUrl;
	}

	setModelConfig() {
		switch (this.getInputType()) {
			case 'multidate':
				// on ajoute la config du datepicker pour permettre la validation
				// des dates à l'intérieur du champ multiple
				this.dateConfig = {
					onDateSelected: () => this.validate()
				};
				break;
			case 'number':
			case 'multinumber':
				this.getTooltipForNumeric();
				break;
			default:
				break;
		}
	}

	onExitNumeric() {
		let valeurActu = this.model.hasOwnProperty('data') ? this.model[this.fieldId] : this.model[this.fieldId].value;
		if (valeurActu !== null && valeurActu !== undefined) {
			const grandeurMin = this.field.numericAdditionalInformation ? this.field.numericAdditionalInformation.grandeurMinimum : null;
			const grandeurMax = this.field.numericAdditionalInformation ? this.field.numericAdditionalInformation.grandeurMaximum : null;
			if (grandeurMin != undefined && grandeurMin != null && valeurActu < grandeurMin) {
				valeurActu = grandeurMin;
				this.notification.info(this.$translate.instant('PRELEVEMENTS.ERROR_BELOW_VAL_MIN_ESS'));
			}
			if (grandeurMax != undefined && grandeurMax != null && valeurActu > grandeurMax) {
				valeurActu = grandeurMax;
				this.notification.info(this.$translate.instant('PRELEVEMENTS.ERROR_OVER_VAL_MAX_ESS'));
			}

			if (this.model.hasOwnProperty('data')) {
				this.model[this.fieldId] = valeurActu;

				if (!this.model.data[this.fieldId]) {
					this.model.data[this.fieldId] = { id: this.fieldId, value: '' };
				}
				this.model.data[this.fieldId].value = valeurActu;
			} else {
				this.model[this.fieldId].value = valeurActu;
			}
		}

		this.onExitMesure();
	}

	async setModelValue() {
		const type = this.getInputType();
		switch (type) {
			case 'multitext':
			case 'multidate':
			case 'multinumber':
				if (!this.model.hasOwnProperty('data')) {
					this.model[this.fieldId].value = this.getDataValues(this.fieldId) || this.field.default || [];
					if (Array.isArray(this.model[this.fieldId].value)) {
						this.model[this.fieldId].value[0] = this.model[this.fieldId].value[0] || null;
					}
				} else {
					this.model[this.fieldId] = this.getDataValues(this.fieldId) || this.field.default || [];
					if (Array.isArray(this.model[this.fieldId])) {
						this.model[this.fieldId][0] = this.model[this.fieldId][0] || null;
					}
				}
				break;
			case 'multikeyvalue':
				if (!this.model.hasOwnProperty('data')) {
					this.model[this.fieldId].value = this.model[this.fieldId].value || this.field.default;
					if (Array.isArray(this.model[this.fieldId].value)) {
						this.model[this.fieldId].value[0] = this.model[this.fieldId].value[0] || { key: '', value: '', ordre: 0 };
					}
				} else {
					this.model[this.fieldId] = this.model[this.fieldId] || this.field.default;
					if (Array.isArray(this.model[this.fieldId])) {
						this.model[this.fieldId][0] = this.model[this.fieldId][0] || { key: '', value: '', ordre: 0 };
					}
				}

				break;
			case 'multiselect':
				if (!this.field.content) {
					this.field.content = this.field.listeAdditionalInformation.elements;
				}
				if (!this.model.hasOwnProperty('data')) {
					this.selectMultiselectValues(this.model[this.fieldId].valeurs);
				} else {
					this.selectMultiselectValues(this.model[this.fieldId]);
				}
				break;
			case 'date':
				if (!this.model.hasOwnProperty('data')) {
					this.model[this.fieldId].value = this.getDataValue(this.fieldId, type) || this.field.default;

					this.unregister.push(
						this.$scope.$watch(
							() => this.model[this.fieldId].value,
							async () => {
								this.onExitMesure();
							}
						)
					);
				} else {
					this.model[this.fieldId] = this.getDataValue(this.fieldId, type) || this.field.default;

					this.unregister.push(
						this.$scope.$watch(
							() => this.model[this.fieldId],
							async () => {
								this.onExitMesure();
							}
						)
					);
				}

				break;
			case 'heure':
				if (!this.model.hasOwnProperty('data')) {
					this.model[this.fieldId].value = this.getHourValue(this.fieldId) || this.field.default;

					this.unregister.push(
						this.$scope.$watch(
							() => this.model[this.fieldId].value,
							async () => {
								this.onExitMesure();
							}
						)
					);
				} else {
					this.model[this.fieldId] = this.getHourValue(this.fieldId) || this.field.default;
					this.unregister.push(
						this.$scope.$watch(
							() => this.model[this.fieldId],
							async () => {
								this.onExitMesure();
							}
						)
					);
				}
				break;
			/*case "number"://test SP pour afficher le "0", fonctionne pas
                let dataVal =this.getDataValue(this.fieldId);
                if(dataVal!=undefined && dataVal!=null)
                    this.model[this.fieldId] = dataVal;
                else
                    this.model[this.fieldId] = this.field.default;
                break;*/
			case 'granulo':
				this.isTest = true;
				this.recalculGranuloEnCours = false;
				this.displayGraph = false;
				this.paramGranulo = await this.MesuresService.getCourbeGranulo(this.fieldIdEssai || this.field.essai.id, null, null);
				await this.recalculeGranulo(true);
				this.displayGraph = true;

				if (!this.model.hasOwnProperty('data')) {
					if (this.field.granuloAdditionalInformation.sousEssaiMasse.id != null) {
						this.unregister.push(
							this.$scope.$watch(
								() => this.model['_' + this.field.granuloAdditionalInformation.sousEssaiMasse.id],
								async (newValue, oldValue) => {
									if (newValue !== oldValue) {
										this.displayGraph = false;
										await this.recalculeGranulo(true, this.booRecMasSec);
										this.displayGraph = true;
									}
								},
								true
							)
						);
					}

					for (let i = 0; i < this.field.granuloAdditionalInformation.tamis.length; i++) {
						this.unregister.push(
							this.$scope.$watch(
								() => this.field.granuloAdditionalInformation.tamis[i].bornes,
								async () => {
									this.displayGraph = false;
									await this.courbeGranulo();
									this.displayGraph = true;
								}
							)
						);
					}
				} else {
					this.unregister.push(
						this.$scope.$watch(
							() => this.model[this.field.idSsEssaiMasse],
							async () => {
								this.displayGraph = false;
								await this.recalculeGranulo(true);
								this.displayGraph = true;
							}
						)
					);

					this.unregister.push(
						this.$scope.$watch(
							() => this.bornes,
							async () => {
								this.displayGraph = false;
								await this.courbeGranulo(true);
								this.displayGraph = true;
							}
						)
					);
				}
				break;
			default:
				const dataValue = this.getDataValue(this.fieldId, type);
				if (!this.model.hasOwnProperty('data')) {
					this.model[this.fieldId].value = !dataValue && dataValue !== 0 ? this.field.default : dataValue;
				} else {
					this.model[this.fieldId] = !dataValue && dataValue !== 0 ? this.field.default : dataValue;
				}

				break;
		}
	}

	getDataValues(id) {
		// on va chercher les données relatives à ce champ
		let data = null;
		if (!this.model.hasOwnProperty('data')) {
			data = this.model[id];
		} else {
			data = this._.find(this.model.data, { id: id });
		}

		if (data && this._.isArray(data.value)) {
			// si elles existent, on trie par position (SP 12/06/17 tri par valeur, c'est la seule chose qu'on a)
			// puis on aplatit le tableau
			// dans le but d'avoir un tableau de valeurs
			// ['value1', 'value2']
			if (!this.model.hasOwnProperty('data')) {
				return this._.chain(data.value).value();
			}
			return this._.chain(data.value).sortBy('value').map('value').value();
		}
	}

	getHourValue(id) {
		let data = null;
		if (!this.model.hasOwnProperty('data')) {
			data = this.model[id];
		} else {
			data = this._.find(this.model.data, { id: id });
		}

		if (data && data.value) {
			if (typeof data.value === 'string') {
				const hour = data.value.split(':');
				return new Date(1970, 1, 1, hour[0], hour[1], hour[2]);
			}
		}
		return undefined;
	}

	getDataValue(id, type) {
		let value = undefined;
		let data = null;
		if (!this.model.hasOwnProperty('data')) {
			data = this.model[id];
		} else {
			data = this._.find(this.model.data, { id: id });
		}
		if (data && data.hasOwnProperty('value') && this._.isArray(data.value) && type !== 'granulo') {
			const val = data.value[data.value.length - 1];
			value = val && val.hasOwnProperty('value') ? val.value : val;
		} else if (data && data.hasOwnProperty('value')) {
			value = data.value;
		} else {
			value = data;
		}

		return value;
	}

	selectMultiselectValues(model) {
		// le multiselect a un fonctionnement particulier,
		// il nécessite de sélectionner dans le tableau source (ici field.listeAdditionalInformation.elements)
		this._.forEach(model, (selected) => {
			let toSelect;
			// si le modèle d'entrée est un tableau key value, on sélectionne selon la clé
			if (selected && selected.hasOwnProperty('key')) {
				toSelect = this._.find(this.field.content, { key: selected.key });
			}
			// sinon, on sait que c'est un tableau de key (tableau de string)
			else {
				toSelect = this._.find(this.field.content, { key: selected });
			}
			if (toSelect) {
				// on a récupéré l'objet à sélectionner par référence (via _.find)
				// donc le modifier ici modifie l'objet dans la source
				// et sélectionne, par conséquent, l'item dans la liste envoyée à la vue
				toSelect.selected = true;
			}
		});
	}

	getInputType() {
		if (this.nature !== undefined && typeof this.nature !== 'number') {
			return this.NatureInputTypeMapping[this.nature.toLowerCase()];
		}
		return this.NatureInputTypeMapping[this.enumNature[this.nature].toLowerCase()];
	}

	getValidationType() {
		switch (this.getInputType()) {
			case 'date':
				return 'date';
			case 'number':
				return 'negative-decimal';
			case 'multidate':
			case 'multinumber':
			case 'multitext':
				return this.field.isMandatory ? 'multiple' : '';
			default:
				return '';
		}
	}

	asUniqueKey(key) {
		const elements = this.model.hasOwnProperty('data') ? this.model[this.fieldId] : this.model[this.fieldId].value;

		let result = null;
		if (elements && elements.length > 0 && key && key.length > 0) {
			const keys = elements.map(function (x) {
				if (x && x.key) {
					return x.key;
				}
			});
			const counts = {};
			if (keys.length > 0) {
				result = true;
			}
			keys.forEach(function (x) {
				if (x === key) {
					counts[x] = (counts[x] || 0) + 1;
					if (counts[x] > 1) {
						result = false;
					}
				}
			});
		}
		return result;
	}

	getTooltipForNumeric() {
		if (
			this.field.numericAdditionalInformation
			/*&& (this.field.numericAdditionalInformation.unite || this.field.numericAdditionalInformation.nombreDeDecimales)*/
		) {
			this.TooltipNumerics = '<ul class="text-left">';
			/*if (this.field.numericAdditionalInformation.unite) {
                this.TooltipNumerics += '<li>' + this.$translate.instant('CARACTERISTIQUES.FIELDS.UNITE') + ' : ' + this.field.numericAdditionalInformation.unite + '</li>';
            }*/
			//if (this.field.numericAdditionalInformation.nombreDeDecimales) {
			this.TooltipNumerics +=
				'<li>' +
				this.$translate.instant('CARACTERISTIQUES.FIELDS.DECIMALE') +
				' : ' +
				this.field.numericAdditionalInformation.nombreDeDecimales +
				'</li>';
			//}
			this.TooltipNumerics += '</ul>';
		}
	}

	addInput() {
		let input = null;

		switch (this.getInputType()) {
			case 'multikeyvalue':
				input = {
					key: '',
					value: ''
				};
				break;
		}

		const model = this.model.hasOwnProperty('data') ? this.model[this.fieldId] : this.model[this.fieldId].value;
		model.push(input);
		//if(this.getInputType()!='multikeyvalue'){
		this.validate();
		//}
	}

	removeInput(index) {
		const model = this.model.hasOwnProperty('data') ? this.model[this.fieldId] : this.model[this.fieldId].value;
		model.splice(index, 1);
		//if(this.getInputType()!='multikeyvalue'){
		this.validate();
		//}
	}

	removeAllInputs() {
		const model = this.model.hasOwnProperty('data') ? this.model[this.fieldId] : this.model[this.fieldId].value;
		model.splice(0, model.length);
		this.addInput();
	}

	readonlyDisplayValue(value) {
		let result = value;

		//quand on a une valeur non vide pour un type bool, on va traduire la valeur true/false par oui/non
		if (value && this.nature.toLowerCase() === 'booleen') {
			result = this.$translate.instant(value.toLowerCase() === 'true' ? 'OUI' : 'NON');
		}

		//pour une valeur sous forme de <key, value>, on recupere sa valeur
		if (result && result.key && result.value) {
			result = result.value;
		}

		return result;
	}

	isLienWeb(val) {
		if (val == null || val == undefined) {
			return false;
		}
		if (typeof val === 'string') {
			return val.startsWith('http://') || val.startsWith('https://');
		}
		return false;
	}

	onItemClick(d, id) {
		let model = this.model.hasOwnProperty('data') ? this.model[id] : this.model[id].value;

		if (d.key === model) {
			model = null;
		} else {
			model = d.key;
		}

		if (this.model.hasOwnProperty('data')) {
			this.model[id] = model;

			if (!this.model.data[id]) {
				this.model.data[id] = { id: id, value: '' };
			}
			this.model.data[id].value = model;
		} else {
			this.model[id].value = model;
		}

		if (this.onExitMesure) {
			this.onExitMesure();
		}

		return model;
	}

	validate() {
		// pour être certain qu'on a bien la dernière valeur du modèle, on timeout
		this.timer = this.$timeout(() => {
			const splited = this.modelName.split('.');

			if (splited[splited.length - 1]) {
				this.$scope.$broadcast(splited[splited.length - 1] + '.' + this.fieldId + 'PropertyValidations');
			}
		});
	}

	async deleteMesure() {
		await this.onDeleteMesure();
	}

	changeNiveauVisibilite() {
		if (this.niveauVisibilite == undefined) {
			this.niveauVisibilite = 0;
		}
	}

	async waitCourbeGranulo() {
		this.$timeout(() => {
			if (this.isLocked) {
				this.waitCourbeGranulo();
			} else {
				this.courbeGranulo();
			}
		}, 0); //attend le prochain cycle
	}

	async courbeGranulo() {
		this.isEntitySec =
			((this.saisie.isFocusRef || this.saisie.isFocusRefSec) &&
				this.saisie.reference &&
				'id' in this.saisie.reference &&
				this.saisie.reference.id &&
				this.saisie.referenceSec &&
				'id' in this.saisie.referenceSec &&
				this.saisie.referenceSec.id) ||
			((this.saisie.isFocusFtpa || this.saisie.isFocusFtpaSec) &&
				this.saisie.ftpa &&
				'id' in this.saisie.ftpa &&
				this.saisie.ftpa.id &&
				this.saisie.ftpaSec &&
				'id' in this.saisie.ftpaSec &&
				this.saisie.ftpaSec.id)
				? true
				: false;
		//gestion multiwatcher
		if (!this.isLocked) {
			this.isLocked = true;
		} else {
			this.waitCourbeGranulo();
		}

		try {
			if (this.paramGranulo) {
				let keyOne = null;
				let keyTwo = null;
				if ((!this.saisie.reference || !this.saisie.reference.id) && (!this.saisie.referenceSec || !this.saisie.referenceSec.id)) {
					keyOne = 'ftpa';
					keyTwo = 'ftpaSec';
				} else if ((!this.saisie.ftpa || !this.saisie.ftpa.id) && (!this.saisie.ftpaSec || !this.saisie.ftpaSec.id)) {
					keyOne = 'reference';
					keyTwo = 'referenceSec';
				}
				this.labels = [];
				this.series = [];
				this.data = [];
				this.datasetOverride = [];
				const nb = this.isEntitySec ? 1 : 0;
				//OPTIONS : légende et axes
				let positionLegende = this.getPositionLegende(this.paramGranulo.positionLegende);
				let displayLegende = true;
				let typeAxeX = 'logarithmic';
				let typeAxeY = 'linear';
				let displayAxeX = true;
				let displayAxeY = true;
				let inverserX = false;
				let inverserY = false;
				let lisser = 0;
				let minPassant = 100;
				let maxPassant = 0;
				const dataVal = [];
				for (let index = 0; index <= nb; index++) {
					const data = [];
					const keyEntity = index === 0 ? keyOne : keyTwo;
					const libRef = this.saisie[keyEntity].libelle;
					//données
					const dataMin = [];
					const dataMax = [];
					const dataLI = [];
					const dataLS = [];
					const dataMinU = [];
					const dataMaxU = [];
					minPassant = 100;
					maxPassant = 0;
					let tracesReferences = [];
					let idxVal = -1;
					let idxVsi = -1;
					let idxVss = -1;
					let idxVsiU = -1;
					let idxVssU = -1;
					let idxLi = -1;
					let idxLs = -1;
					if (index === 0 && this.paramGranulo.listeTracesResultats) {
						idxVal = this._.findIndex(this.paramGranulo.listeTracesResultats, { formule1: '@VAL' });
					}
					if (this.paramGranulo.listeIndicesReferences && this.paramGranulo.listeIndicesReferences.length > 0) {
						tracesReferences = this.paramGranulo.listeIndicesReferences[index].listeTracesReferences;
						idxVsi = this._.findIndex(tracesReferences, { formule1: '@VSI' });
						idxVss = this._.findIndex(tracesReferences, { formule1: '@VSS' });
						if (idxVss == -1) {
							idxVss = this._.findIndex(tracesReferences, { formule2: '@VSS' });
						}
						idxVsiU = this._.findIndex(tracesReferences, { formule1: '@VSI-@UNORME' });
						idxVssU = this._.findIndex(tracesReferences, { formule1: '@VSS+@UNORME' });
						if (idxVssU == -1) {
							idxVssU = this._.findIndex(tracesReferences, { formule2: '@VSS+@UNORME' });
						}
						idxLi = this._.findIndex(tracesReferences, { formule1: '@LI' });
						idxLs = this._.findIndex(tracesReferences, { formule1: '@LS' });
						if (idxLs == -1) {
							idxLs = this._.findIndex(tracesReferences, { formule2: '@LS' });
						}
					}
					const lstTamis = this.model[this.fieldId].granuloAdditionalInformation.tamis;
					this.seuilsRefus = false;
					for (let t = lstTamis.length - 1; t >= 0; t--) {
						if (lstTamis[t].isDisplay === true) {
							if (!this.seuilsRefus && lstTamis[t].seuilRefus) {
								this.seuilsRefus = true;
							}
							const ouverture = lstTamis[t].ouverture;
							if (lstTamis[t].isDisplay === true) {
								if (!this.paramGranulo.deriverCourbe) {
									if (lstTamis[t].pourcPassantArrondi != undefined) {
										dataVal.push({ x: ouverture, y: lstTamis[t].pourcPassantArrondi });
										if (this.paramGranulo.limiterPassants) {
											if (lstTamis[t].pourcPassant > maxPassant) {
												maxPassant = lstTamis[t].pourcPassantArrondi;
											}
											if (lstTamis[t].pourcPassant < minPassant) {
												minPassant = lstTamis[t].pourcPassantArrondi;
											}
										}
										if (/*!this.paramGranulo.tousTamis &&*/ this.labels.findIndex((label) => label === ouverture) === -1) {
											this.labels.push(ouverture);
										}
									}
								} else {
									if (lstTamis[t].pourcRefPartielArrondi != undefined) {
										dataVal.push({ x: ouverture, y: lstTamis[t].pourcRefPartielArrondi });
										if (/*!this.paramGranulo.tousTamis &&*/ this.labels.findIndex((label) => label === ouverture) === -1) {
											this.labels.push(ouverture);
										}
									}
								}
								const serieTamis = lstTamis[t]; // await this.getSerieTamis(lstTamis[t]);
								if (serieTamis && serieTamis.bornes) {
									// On change les bornes en fonction du focus des boutons et de
									// s'il y a un 2eme choix
									let bornes = [];
									if (this.isEntitySec) {
										if (index === 0) {
											if (this.saisie.isFocusFtpa || this.saisie.isFocusRef) {
												bornes = serieTamis.bornes;
											} else {
												bornes = serieTamis.bornesSec;
											}
										} else {
											if (this.saisie.isFocusFtpa || this.saisie.isFocusRef) {
												bornes = serieTamis.bornesSec;
											} else {
												bornes = serieTamis.bornes;
											}
										}
									} else {
										bornes = serieTamis.bornes && 'idEssai' in serieTamis.bornes > 0 ? serieTamis.bornes : serieTamis.bornesSec;
									}
									const doubleTest = 3.0 / 2.0;
									const strTest = doubleTest.toString();
									const separateurOK = strTest.substring(1, 2);
									let separateurNOK = ',';
									if (separateurOK == ',') {
										separateurNOK = '.';
									}

									//VSI
									if (bornes.borneSuperieure && (bornes.borneInferieure === undefined || bornes.borneInferieure === null)) {
										dataMin.push({ x: ouverture, y: 0 });

										if (this.paramGranulo.limiterPassants) {
											if (maxPassant < 0) {
												maxPassant = 0;
											}
											if (minPassant > 0) {
												minPassant = 0;
											}
											if (/*!this.paramGranulo.tousTamis &&*/ !~this.labels.findIndex((label) => label === ouverture)) {
												this.labels.push(ouverture);
											}
										}
									}
									if ((bornes.borneInferieure || typeof bornes.borneInferieure === 'number') /*  || firstVsi */ && idxVsi > -1) {
										let bi;
										// if (firstVsi) {
										//     bi = 0;
										//     firstVsi = false;
										// }
										if (bornes.borneInferieure || typeof bornes.borneInferieure === 'number') {
											bi = numeral(bornes.borneInferieure.toString().replace(separateurNOK, separateurOK))._value;
										}

										if (typeof bi !== 'undefined') {
											dataMin.push({ x: ouverture, y: bi });
											if (this.paramGranulo.limiterPassants) {
												if (bi < maxPassant) {
													maxPassant = bi;
												}
												if (bi > maxPassant) {
													minPassant = bi;
												}
											}

											if (/*!this.paramGranulo.tousTamis &&*/ !~this.labels.findIndex((label) => label === ouverture)) {
												this.labels.push(ouverture);
											}
										}
									}

									//VSS
									if (bornes.borneInferieure && (bornes.borneSuperieure === undefined || bornes.borneSuperieure === null)) {
										dataMax.push({ x: ouverture, y: 100 });
										if (this.paramGranulo.limiterPassants) {
											if (maxPassant < 100) {
												maxPassant = 100;
											}
											if (minPassant > 100) {
												minPassant = 100;
											}
										}
										if (/*!this.paramGranulo.tousTamis &&*/ !~this.labels.findIndex((label) => label === ouverture)) {
											this.labels.push(ouverture);
										}
									}
									if ((bornes.borneSuperieure || typeof bornes.borneSuperieure === 'number') /*  || firstVss */ && idxVss > -1) {
										let bs;
										// if (firstVss) {
										//     bs = 0;
										//     firstVss = false;
										// }
										if (bornes.borneSuperieure || typeof bornes.borneSuperieure === 'number') {
											bs = numeral(bornes.borneSuperieure.toString().replace(separateurNOK, separateurOK))._value;
										}

										if (typeof bs !== 'undefined') {
											dataMax.push({ x: ouverture, y: bs });
											if (this.paramGranulo.limiterPassants) {
												if (bs > maxPassant) {
													maxPassant = bs;
												}
												if (bs < minPassant) {
													minPassant = bs;
												}
											}

											if (/*!this.paramGranulo.tousTamis &&*/ !~this.labels.findIndex((label) => label === ouverture)) {
												this.labels.push(ouverture);
											}
										}
									}

									//VSI-U
									if (
										bornes.toleranceSuperieure &&
										(bornes.toleranceInferieure === undefined || bornes.toleranceInferieure === null)
									) {
										dataMinU.push({ x: ouverture, y: 0 });
										if (this.paramGranulo.limiterPassants) {
											if (maxPassant < 0) {
												maxPassant = 0;
											}
											if (minPassant > 0) {
												minPassant = 0;
											}
										}
										if (/*!this.paramGranulo.tousTamis &&*/ !~this.labels.findIndex((label) => label === ouverture)) {
											this.labels.push(ouverture);
										}
									}

									if ((bornes.toleranceInferieure || typeof bornes.toleranceInferieure === 'number') && idxVsiU > -1) {
										const biU = numeral(bornes.toleranceInferieure.toString().replace(separateurNOK, separateurOK))._value;
										dataMinU.push({ x: ouverture, y: biU });
										if (this.paramGranulo.limiterPassants) {
											if (biU > maxPassant) {
												maxPassant = biU;
											}
											if (biU < minPassant) {
												minPassant = biU;
											}
										}
										if (/*!this.paramGranulo.tousTamis &&*/ !~this.labels.findIndex((label) => label === ouverture)) {
											this.labels.push(ouverture);
										}
									}

									//VSS+U
									if (
										bornes.toleranceInferieure &&
										(bornes.toleranceSuperieure === undefined || bornes.toleranceSuperieure === null)
									) {
										dataMaxU.push({ x: ouverture, y: 100 });
										if (this.paramGranulo.limiterPassants) {
											if (maxPassant < 100) {
												maxPassant = 100;
											}
											if (minPassant > 100) {
												minPassant = 100;
											}
										}
										if (/*!this.paramGranulo.tousTamis &&*/ !~this.labels.findIndex((label) => label === ouverture)) {
											this.labels.push(ouverture);
										}
									}

									if ((bornes.toleranceSuperieure || typeof bornes.toleranceSuperieure === 'number') && idxVssU > -1) {
										const bsU = numeral(bornes.toleranceSuperieure.toString().replace(separateurNOK, separateurOK))._value;
										dataMaxU.push({ x: ouverture, y: bsU });
										if (this.paramGranulo.limiterPassants) {
											if (bsU > maxPassant) {
												maxPassant = bsU;
											}
											if (bsU < minPassant) {
												minPassant = bsU;
											}
										}
										if (/*!this.paramGranulo.tousTamis &&*/ !~this.labels.findIndex((label) => label === ouverture)) {
											this.labels.push(ouverture);
										}
									}
									//LI
									if ((bornes.limiteInferieure || typeof bornes.limiteInferieure === 'number') && idxLi > -1) {
										const li = numeral(bornes.limiteInferieure.toString().replace(separateurNOK, separateurOK))._value;
										dataLI.push({ x: ouverture, y: li });
										if (this.paramGranulo.limiterPassants) {
											if (li > maxPassant) {
												maxPassant = li;
											}
											if (li < minPassant) {
												minPassant = li;
											}
										}
										if (/*!this.paramGranulo.tousTamis &&*/ !~this.labels.findIndex((label) => label === ouverture)) {
											this.labels.push(ouverture);
										}
									}
									//LS
									if ((bornes.limiteSuperieure || typeof bornes.limiteSuperieure === 'number') && idxLs > -1) {
										const ls = numeral(bornes.limiteSuperieure.toString().replace(separateurNOK, separateurOK))._value;
										dataLS.push({ x: ouverture, y: ls });
										if (this.paramGranulo.limiterPassants) {
											if (ls > maxPassant) {
												maxPassant = ls;
											}
											if (ls < minPassant) {
												minPassant = ls;
											}
										}
										if (/*!this.paramGranulo.tousTamis &&*/ !~this.labels.findIndex((label) => label === ouverture)) {
											this.labels.push(ouverture);
										}
									}
								}
							}
						}
					}
					/*if (this.paramGranulo.tousTamis) {//SP 10/01/24 commmenté, fait planter la courbe granulo sur prelevment Attention à remettre les this.paramGranulo.tousTamis && + haut quand traité
                        for (var t = 0; t < this.field.serieTamis.length; t++) {
                            this.labels.push(this.field.serieTamis[t].ouverture);
                        } //label des tamis
                    }*/
					if (positionLegende == '') {
						positionLegende = 'bottom';
						displayLegende = false;
					}
					if (this.paramGranulo.echelleAbscisses === 0) {
						typeAxeX = 'linear';
					}
					if (this.paramGranulo.echelleOrdonnees === 1) {
						typeAxeY = 'logarithmic';
					}
					if (this.paramGranulo.afficheAbscisses === false) {
						displayAxeX = false;
					}
					if (this.paramGranulo.afficheOrdonnees === false) {
						displayAxeY = false;
					}
					if (this.paramGranulo.inverserX === true) {
						inverserX = true;
					}
					if (this.paramGranulo.inverserY === true) {
						inverserY = true;
					}
					if (this.paramGranulo.lisser === true) {
						lisser = 0.4;
					}
					//TODO taille légende
					let indexCourant = 1;
					//VALEUR
					if (index === 0 && dataVal.length > 0 && idxVal > -1) {
						if (!this.paramGranulo.deriverCourbe) {
							this.series.push(this.$translate.instant('MESURES.POURC_PASSANTS'));
						} else {
							this.series.push(this.$translate.instant('MESURES.POURC_REFUS_CUMUL'));
						}
						const paramTrace = this.paramGranulo.listeTracesResultats[idxVal];
						const override = this.getParamTrace(paramTrace, false);
						this.datasetOverride.push(override);
						const dataset = {
							label: this.$translate.instant('MESURES.POURC_PASSANTS'),
							data: dataVal.map(({ x, y }) => ({ x: parseFloat(x), y: parseFloat(y) })),
							...override
						};
						data.push(dataset);
						indexCourant++;
					}
					//VSS
					if (dataMax.length > 0 && idxVss > -1) {
						const lib = !this.isEntitySec
							? this.$translate.instant('MESURES.VSS')
							: `[${libRef}] - [${this.$translate.instant('MESURES.VSS')}]`;
						this.series.push(lib);
						const paramTrace = tracesReferences[idxVss];
						let fillToVSI = undefined;
						if (dataMin.length > 0 && idxVsi > -1) {
							fillToVSI = indexCourant;
						} else {
							fillToVSI = false;
						}
						const override = this.getParamTrace(paramTrace, fillToVSI);
						if (paramTrace.formePoint === 10) {
							override.rotation = 60;
						}
						this.datasetOverride.push(override);
						const dataset = {
							label: lib,
							data: dataMax.map(({ x, y }) => ({ x: parseFloat(x), y: parseFloat(y) })),
							...override
						};
						data.push(dataset);
						indexCourant++;
					}
					//VSI
					if (dataMin.length > 0 && idxVsi > -1) {
						const lib = !this.isEntitySec
							? this.$translate.instant('MESURES.VSI')
							: `[${libRef}] - [${this.$translate.instant('MESURES.VSI')}]`;
						this.series.push(lib);
						const paramTrace = tracesReferences[idxVsi];
						let fillToVSS = undefined;
						if (dataMax.length > 0 && idxVss > -1) {
							fillToVSS = indexCourant - 1;
						} else {
							fillToVSS = false;
						}
						const override = this.getParamTrace(paramTrace, fillToVSS);
						this.datasetOverride.push(override);
						const dataset = {
							label: lib,
							data: dataMin.map(({ x, y }) => ({ x: parseFloat(x), y: parseFloat(y) })),
							...override
						};
						data.push(dataset);
						indexCourant++;
					}
					//VSS+U
					if (dataMaxU.length > 0 && idxVssU > -1) {
						const lib = !this.isEntitySec
							? this.$translate.instant('MESURES.VSS_PLUS_U')
							: `[${libRef}] - [${this.$translate.instant('MESURES.VSS_PLUS_U')}]`;
						this.series.push(lib);
						const paramTrace = tracesReferences[idxVssU];
						let fillToVSIU = undefined;
						if (dataMinU.length > 0 && idxVsiU > -1) {
							fillToVSIU = indexCourant;
						} else {
							fillToVSIU = false;
						}
						const override = this.getParamTrace(paramTrace, fillToVSIU);
						if (paramTrace.formePoint === 10) {
							override.rotation = 60;
						}
						this.datasetOverride.push(override);
						const dataset = {
							label: lib,
							data: dataMaxU.map(({ x, y }) => ({ x: parseFloat(x), y: parseFloat(y) })),
							...override
						};
						data.push(dataset);
						indexCourant++;
					}
					//VSI-U
					if (dataMinU.length > 0 && idxVsiU > -1) {
						const lib = !this.isEntitySec
							? this.$translate.instant('MESURES.VSI_MOINS_U')
							: `[${libRef}] - [${this.$translate.instant('MESURES.VSI_MOINS_U')}]`;
						this.series.push(lib);
						const paramTrace = tracesReferences[idxVsiU];
						let fillToVSSU = undefined;
						if (dataMaxU.length > 0 && idxVssU > -1) {
							fillToVSSU = indexCourant - 1;
						} else {
							fillToVSSU = false;
						}
						const override = this.getParamTrace(paramTrace, fillToVSSU);
						this.datasetOverride.push(override);
						const dataset = {
							label: lib,
							data: dataMinU.map(({ x, y }) => ({ x: parseFloat(x), y: parseFloat(y) })),
							...override
						};
						data.push(dataset);
						indexCourant++;
					}
					//LS
					if (dataLS.length > 0 && idxLs > -1) {
						const lib = !this.isEntitySec
							? this.$translate.instant('MESURES.LS')
							: `[${libRef}] - [${this.$translate.instant('MESURES.LS')}]`;
						this.series.push(lib);
						const paramTrace = tracesReferences[idxLs];
						let fillToLI = undefined;
						if (dataLI.length > 0 && idxLi > -1) {
							fillToLI = indexCourant;
						} else {
							fillToLI = false;
						}
						const override = this.getParamTrace(paramTrace, fillToLI);
						if (paramTrace.formePoint === 10) {
							override.rotation = 60;
						}
						this.datasetOverride.push(override);
						const dataset = {
							label: lib,
							data: dataLS.map(({ x, y }) => ({ x: parseFloat(x), y: parseFloat(y) })),
							...override
						};
						data.push(dataset);
						indexCourant++;
					}
					//LI
					if (dataLI.length > 0 && idxLi > -1) {
						const lib = !this.isEntitySec
							? this.$translate.instant('MESURES.LI')
							: `[${libRef}] - [${this.$translate.instant('MESURES.LI')}]`;
						this.series.push(lib);
						const paramTrace = tracesReferences[idxLi];
						let fillToLS = undefined;
						if (dataLS.length > 0 && idxLs > -1) {
							fillToLS = indexCourant - 1;
						} else {
							fillToLS = false;
						}
						const override = this.getParamTrace(paramTrace, fillToLS);
						this.datasetOverride.push(override);
						const dataset = {
							label: lib,
							data: dataLI.map(({ x, y }) => ({ x: parseFloat(x), y: parseFloat(y) })),
							...override
						};
						data.push(dataset);
						indexCourant++;
					}
					this.data = _.concat(this.data, data);
				}
				if (!this.paramGranulo.limiterPassants) {
					minPassant = 0;
					maxPassant = 100;
				}
				const titreAxeX = this.$translate.instant('MESURES.TAMIS') + ' (' + this.field.granuloAdditionalInformation.uniteTamis + ')';
				this.graphGran = {
					type: 'line',
					data: {
						//labels: this.labels,
						datasets: this.data
					},
					//chartSeries: this.series,
					options: {
						// Défini le pixel ratio du graph -> + élevé = meilleure qualité
						devicePixelRatio: this.paramGranulo.ratioGraph ? this.paramGranulo.ratioGraph : 1,
						// Paramétrage de transition pour Activat°/Des des courbes
						transitions: {
							show: {
								animation: {
									duration: 1000
								},
								animations: {
									x: {
										from: 0
									},
									y: {
										from: 0
									}
								}
							},
							hide: {
								animation: {
									duration: 1000
								},
								animations: {
									x: {
										to: 0
									},
									y: {
										to: 0
									}
								}
							}
						},
						clip: false,
						plugins: {
							filler: {
								propagate: false
							},
							title: {
								display: true
							},
							tooltip: {
								enable: true,
								position: 'nearest',
								usePointStyle: true
							},
							legend: {
								display: displayLegende,
								position: positionLegende,
								// Watcher Montrer/cacher les Data
								onClick(e, legendItem, legend) {
									const index = legendItem.datasetIndex;
									const ci = legend.chart;
									if (ci.isDatasetVisible(index)) {
										ci.hide(index);
										legendItem.hidden = true;
									} else {
										ci.show(index);
										legendItem.hidden = false;
									}
								},
								labels: {
									usePointStyle: true,
									padding: this.paramGranulo.tailleLegende <= 5 ? 10 : 20,
									font: {
										size: this.MesuresService.getFontSize(this.paramGranulo.tailleLegende)
									},
									generateLabels: (chart) => {
										return chart.data.datasets.map((dataset, index) => ({
											datasetIndex: index,
											lineWidth: 2,
											text: dataset.label,
											fillStyle: dataset.backgroundColor,
											strokeStyle: dataset.pointBorderColor,
											pointStyle:
												dataset.pointStyle && dataset.pointStyle !== '' ? dataset.pointStyle : dataset.fill ? 'rect' : 'line',
											hidden: !chart.getDatasetMeta(index).visible,
											rotation: dataset.rotation
										}));
									}
								}
							}
						},
						interaction: {
							intersect: false,
							mode: 'x'
						},
						elements: {
							line: {
								tension: lisser //ne pas lisser : Mantis 3432//sur paramétrage Mantis 4192
							}
						},
						responsive: true,
						scales: {
							x: {
								axis: 'x',
								display: true,
								type: typeAxeX,
								grid: {
									display: displayAxeX
								},
								position: 'bottom',
								title: {
									display: true,
									text: titreAxeX
								},
								min: this.labels[0], //this.model[this.fieldId][this.model[this.fieldId].length - 1].value.ouverture,
								max: this.labels[this.labels.length - 1], //this.model[this.fieldId][0].value.ouverture,
								reverse: inverserX,
								ticks: {
									autoSkip: false,
									maxRotation: this.xaxeAngleRotation ? this.xaxeAngleRotation : 0,
									minRotation: this.xaxeAngleRotation ? this.xaxeAngleRotation : 0,
									callback: (value, index, values) => value.toString(10)
								}, //personnaliser les ticks
								afterBuildTicks: (myChart) => {
									myChart.ticks = this.labels.map((x) => {
										return { value: x };
									});
								}
							},
							y: {
								axis: 'y',
								type: typeAxeY,
								display: true,
								grid: {
									display: displayAxeY
								},
								position: 'left',
								title: {
									display: true,
									text: this.$translate.instant('MESURES.POURCENT_PASS_CUMULES')
								},
								min: 0,
								max: 100,
								reverse: inverserY,
								ticks: {
									autoSkip: false,
									maxRotation: this.yaxeAngleRotation ? this.yaxeAngleRotation : 0,
									minRotation: this.yaxeAngleRotation ? this.yaxeAngleRotation : 0
								}
							}
						}
					}
				};
			}
		} finally {
			this.isLocked = false;
		}
	}

	getParamTrace(paramTrace, fill) {
		const stylePoint = this.getStylePoint(paramTrace.formePoint);
		const styleTrace = this.getStyleTrace(paramTrace.styleTrace);
		let taillePoint = paramTrace.taillePoint;
		if (stylePoint == '') {
			taillePoint = 0;
		}
		const override = {
			fill: fill,
			backgroundColor: paramTrace.couleurFuseau ? this.getColorFromHSL(paramTrace.couleurFuseau) : this.getColorFromHSL(null),
			borderColor: styleTrace && paramTrace.couleurTrace ? this.getColorFromHSL(paramTrace.couleurTrace) : this.getColorFromHSL(null),
			fillColor: this.getColorFromHSL(null),
			borderWidth: paramTrace.epaisseurTrace,
			pointBorderColor: this.setParamColor(paramTrace, stylePoint),
			pointBackgroundColor: this.setParamColor(paramTrace, stylePoint),
			pointBorderWidth: paramTrace.taillePoint,
			pointStyle: stylePoint,
			pointRadius: taillePoint,
			borderDash: styleTrace,
			pointHoverBorderColor: this.setParamColor(paramTrace, stylePoint),
			pointHoverBackgroundColor: this.setParamColor(paramTrace, stylePoint),
			datalabels: {
				anchor: 'start',
				align: 'end',
				display: paramTrace.valeurPoint,
				formatter: (value, context) => value.y
				//color: '#FFCE56'
			}
		};
		if (paramTrace.styleTrace == 0) {
			override.showLine = false;
		}
		// Si le fuseau n'est pas en style "rempli" on ne le rempli pas
		if (paramTrace.styleFuseau == 0) {
			override.fill = false;
		}
		return override;
	}

	setParamColor(paramTrace, stylePoint) {
		return stylePoint
			? this.getColorFromHSL(paramTrace.couleurPoint)
			: paramTrace.styleTrace !== 0
			? this.getColorFromHSL(paramTrace.couleurTrace)
			: this.getColorFromHSL(paramTrace.couleurFuseau);
	}

	getColorFromHSL(coul) {
		if (coul) {
			const hexa = this.ColorPickerService.hslToRgb(coul.hue, coul.saturation, coul.luminosity);
			return 'rgba(' + hexa.red + ',' + hexa.green + ',' + hexa.blue + ', ' + (coul.alpha || coul.alpha === 0 ? coul.alpha : '1') + ')';
		}
		return 'rgb(0,0,0)';
	}

	getPositionLegende(position) {
		switch (position) {
			case 0: //'pas_legende',
				return '';
			case 1: //'droite',
				return 'right';
			case 2: //'gauche',
				return 'left';
			case 3: //'haut',
				return 'top';
			case 4: //'bas'
				return 'bottom';
			default:
				'bottom';
				break;
		}
	}

	getStyleTrace(style) {
		switch (style) {
			case 0:
			case 1:
				return [];
			case 2:
				return [2, 2];
			case 3:
				return [6, 6];
			case 5:
				return [10, 6];
			case 5:
				return [10, 15];
		}
		return [];
	}

	getStylePoint(style) {
		let stylePoint = 'circle;';
		switch (style) {
			case 0:
				stylePoint = '';
				break;
			case 1:
				stylePoint = 'circle';
				break;
			case 2:
				stylePoint = 'cross';
				break;
			case 3:
				stylePoint = 'crossRot';
				break;
			case 4:
				stylePoint = 'dash';
				break;
			case 5:
				stylePoint = 'line';
				break;
			case 6:
				stylePoint = 'rect';
				break;
			case 7:
				stylePoint = 'rectRounded';
				break;
			case 8:
				stylePoint = 'rectRot';
				break;
			case 9:
				stylePoint = 'star';
				break;
			case 10:
				stylePoint = 'triangle';
				break;
		}
		return stylePoint;
	}

	async recalculeGranulo(rafraichiCourbeGranulo, booRecMasSec = false) {
		if (this.model[this.fieldId]) {
			this.recalculGranuloEnCours = true;
			for (let i = 0; i < this.model[this.fieldId].granuloAdditionalInformation.tamis.length; i++) {
				const tamis = this.model[this.fieldId].granuloAdditionalInformation.tamis[i];
				if (tamis && tamis.isDisplay === true && tamis.pourcPassant != undefined && tamis.pourcPassant != null) {
					//recalculer en fonction de la masse sèche
					if (booRecMasSec && tamis.ready && this.loading === false) {
						await this.keepCumRefus(tamis.ouverture);
					} else {
						await this.changePourcentPassant(tamis.ouverture);
					}
					//calculer les arrondis
					this.arrondiTamis(i);
				}
			}
			this.recalculGranuloEnCours = false;

			if (rafraichiCourbeGranulo) {
				await this.courbeGranulo();
			}
		}
	}

	getValeurMasseGranulo() {
		const idSsEssMasse =
			this.model[this.fieldId] &&
			this.model[this.fieldId].granuloAdditionalInformation &&
			this.model[this.fieldId].granuloAdditionalInformation.sousEssaiMasse
				? this.model[this.fieldId].granuloAdditionalInformation.sousEssaiMasse.id
				: 0;
		if (idSsEssMasse) {
			return this.model['_' + idSsEssMasse].value;
		}

		return 0;
	}

	getLibelleMasseGranulo() {
		const idSsEssMasse =
			this.model[this.fieldId] &&
			this.model[this.fieldId].granuloAdditionalInformation &&
			this.model[this.fieldId].granuloAdditionalInformation.sousEssaiMasse
				? this.model[this.fieldId].granuloAdditionalInformation.sousEssaiMasse.id
				: 0;
		if (idSsEssMasse) {
			return this.model['_' + idSsEssMasse].libelle;
		}
		return 0;
	}

	getIndexTamis(ouverture) {
		const idxTamis = this.model[this.fieldId].granuloAdditionalInformation.tamis.findIndex((e) => e.ouverture === ouverture);
		return idxTamis;
	}

	getIndexTamisPrecedentSaisi(idxTamis) {
		if (idxTamis == 0) {
			return -1;
		}

		let idxPrec = idxTamis - 1;
		while (idxPrec > -1 && !this.model[this.fieldId].granuloAdditionalInformation.tamis[idxPrec].isDisplay === true) {
			idxPrec--;
		}
		return idxPrec;
	}

	getNextTamis(idxTamis) {
		if (idxTamis <= 0) {
			return -1;
		}
		let idxNext = idxTamis + 1;
		while (
			idxNext < this.model[this.fieldId].granuloAdditionalInformation.tamis.length - 1 &&
			!this.model[this.fieldId].granuloAdditionalInformation.tamis[idxNext].isDisplay === true
		) {
			idxNext++;
		}
		return idxNext;
	}

	videLigneTamis(indexTam) {
		const lstTamis = this.model[this.fieldId].granuloAdditionalInformation.tamis;
		lstTamis[indexTam].cumRefus = null;
		lstTamis[indexTam].passant = null;
		lstTamis[indexTam].pourcPassant = null;
		lstTamis[indexTam].refus = null;
		lstTamis[indexTam].pourcRefPartiel = null;
		this.arrondiTamis(indexTam);
		this.recalculeGranulo(true);
	}

	async changeCumRefus(ouverture) {
		const idxTamis = this.getIndexTamis(ouverture);
		const masse = this.getValeurMasseGranulo();
		const idxTamisPrec = this.getIndexTamisPrecedentSaisi(idxTamis);
		let message = '';
		const lstTamis = this.model[this.fieldId].granuloAdditionalInformation.tamis;
		//const oldListTamis = this.oldModel[this.fieldId].granuloAdditionalInformation.tamis;
		if (parseInt(lstTamis[idxTamis].cumRefus) > parseInt(masse)) {
			const libelle = this.getLibelleMasseGranulo();
			message = this.getMessage('cumRefSupMassSec', libelle);
		}
		if (lstTamis[idxTamis].cumRefus /*Arrondi*/ === '') {
			this.videLigneTamis(idxTamis);
		} else {
			lstTamis[idxTamis].cumRefus = this.FormulesService.strEnDouble(lstTamis[idxTamis].cumRefus /*Arrondi*/);
			if (isNaN(lstTamis[idxTamis].cumRefus)) {
				lstTamis[idxTamis].cumRefus /*Arrondi*/ = '';
				this.videLigneTamis(idxTamis);
			} else {
				lstTamis[idxTamis].passant =
					lstTamis[idxTamis].cumRefus === null ? null : this.FormulesService.soustraction(masse, lstTamis[idxTamis].cumRefus);
				lstTamis[idxTamis].pourcPassant =
					lstTamis[idxTamis].cumRefus === null
						? null
						: this.FormulesService.division(this.FormulesService.multiplication(lstTamis[idxTamis].passant, 100), masse);
				if (idxTamisPrec > -1) {
					lstTamis[idxTamis].refus =
						lstTamis[idxTamis].cumRefus === null
							? null
							: this.FormulesService.soustraction(lstTamis[idxTamis].cumRefus, lstTamis[idxTamisPrec].cumRefus);
					lstTamis[idxTamis].pourcRefPartiel =
						lstTamis[idxTamis].cumRefus === null
							? null
							: this.FormulesService.soustraction(lstTamis[idxTamisPrec].pourcPassant, lstTamis[idxTamis].pourcPassant);
				} else {
					lstTamis[idxTamis].refus = lstTamis[idxTamis].cumRefus === null ? null : lstTamis[idxTamis].cumRefus;
					lstTamis[idxTamis].pourcRefPartiel =
						lstTamis[idxTamis].cumRefus === null ? null : this.FormulesService.soustraction(100, lstTamis[idxTamis].pourcPassant);
				}
				await this.recalculeTamisSuivants(idxTamis);
			}
		}
		this.changePourcentPassant(ouverture, message, 'changeCumRefus');
		this.onExitMesure();
	}

	async changePourcentPassant(ouverture, message = '', parent = '') {
		try {
			const masse = this.getValeurMasseGranulo();
			const idxTamis = this.getIndexTamis(ouverture);
			const lstTamis = this.model[this.fieldId].granuloAdditionalInformation.tamis;
			const oldListTamis = this.oldModel
				? this.oldModel[this.fieldId].granuloAdditionalInformation.tamis
				: this.model[this.fieldId].granuloAdditionalInformation.tamis;
			if (lstTamis[idxTamis].pourcPassant === '') {
				this.videLigneTamis(idxTamis);
			} else {
				if (lstTamis[idxTamis].pourcPassant < 0) {
					lstTamis[idxTamis].pourcPassant = 0;
				}
				if (lstTamis[idxTamis].pourcPassant > 100) {
					lstTamis[idxTamis].pourcPassant = 100;
				}
				const idxTamisPrec = this.getIndexTamisPrecedentSaisi(idxTamis);
				const idxNextTamis = this.getNextTamis(idxTamis);
				if (this.typAlertErrGran && this.typAlertErrGran !== 1 && masse) {
					this.checkValuesGranulo(oldListTamis, lstTamis, idxTamis, idxTamisPrec, idxNextTamis, message, parent);
				}
				lstTamis[idxTamis].pourcPassant = this.FormulesService.strEnDouble(lstTamis[idxTamis].pourcPassant);
				if (isNaN(lstTamis[idxTamis].pourcPassant)) {
					lstTamis[idxTamis].pourcPassant = '';
					this.videLigneTamis(idxTamis);
				} else {
					lstTamis[idxTamis].passant = this.FormulesService.division(
						this.FormulesService.multiplication(masse, lstTamis[idxTamis].pourcPassant),
						100
					);
					lstTamis[idxTamis].cumRefus = this.FormulesService.soustraction(masse, lstTamis[idxTamis].passant);
					if (idxTamisPrec > -1) {
						lstTamis[idxTamis].refus = this.FormulesService.soustraction(lstTamis[idxTamis].cumRefus, lstTamis[idxTamisPrec].cumRefus);
						lstTamis[idxTamis].pourcRefPartiel = this.FormulesService.soustraction(
							lstTamis[idxTamisPrec].pourcPassant,
							lstTamis[idxTamis].pourcPassant
						);
					} else {
						lstTamis[idxTamis].refus = lstTamis[idxTamis].cumRefus;
						lstTamis[idxTamis].pourcRefPartiel = this.FormulesService.soustraction(100, lstTamis[idxTamis].pourcPassant);
					}
					await this.recalculeTamisSuivants(idxTamis);
				}
				oldListTamis[idxTamis].pourcPassant = lstTamis[idxTamis].pourcPassant;
			}
			lstTamis[idxTamis].ready = true;
			this.onExitMesure();
		} catch (error) {
			console.error(error);
		}
	}

	async keepCumRefus(ouverture) {
		try {
			const masse = this.getValeurMasseGranulo();
			const idxTamis = this.getIndexTamis(ouverture);
			const lstTamis = this.model[this.fieldId].granuloAdditionalInformation.tamis;
			const oldListTamis = this.oldModel
				? this.oldModel[this.fieldId].granuloAdditionalInformation.tamis
				: this.model[this.fieldId].granuloAdditionalInformation.tamis;
			if (lstTamis[idxTamis].pourcPassant === '') {
				this.videLigneTamis(idxTamis);
			} else {
				if (lstTamis[idxTamis].pourcPassant < 0) {
					lstTamis[idxTamis].pourcPassant = 0;
				}
				if (lstTamis[idxTamis].pourcPassant > 100) {
					lstTamis[idxTamis].pourcPassant = 100;
				}
				const idxTamisPrec = this.getIndexTamisPrecedentSaisi(idxTamis);
				const idxNextTamis = this.getNextTamis(idxTamis);
				lstTamis[idxTamis].pourcPassant = this.FormulesService.strEnDouble(lstTamis[idxTamis].pourcPassant);
				if (isNaN(lstTamis[idxTamis].pourcPassant)) {
					lstTamis[idxTamis].pourcPassant = '';
					this.videLigneTamis(idxTamis);
				} else {
					lstTamis[idxTamis].passant = this.FormulesService.soustraction(masse, lstTamis[idxTamis].cumRefus);
					lstTamis[idxTamis].pourcPassant = this.FormulesService.division(
						this.FormulesService.multiplication(lstTamis[idxTamis].passant, 100),
						masse
					);
					if (idxTamisPrec > -1) {
						lstTamis[idxTamis].refus = this.FormulesService.soustraction(lstTamis[idxTamis].cumRefus, lstTamis[idxTamisPrec].cumRefus);
						lstTamis[idxTamis].pourcRefPartiel = this.FormulesService.soustraction(
							lstTamis[idxTamisPrec].pourcPassant,
							lstTamis[idxTamis].pourcPassant
						);
					} else {
						lstTamis[idxTamis].refus = angular.copy(lstTamis[idxTamis].cumRefus);
						lstTamis[idxTamis].pourcRefPartiel = this.FormulesService.soustraction(100, lstTamis[idxTamis].pourcPassant);
					}
				}
				oldListTamis[idxTamis].pourcPassant = lstTamis[idxTamis].pourcPassant;
			}
			lstTamis[idxTamis].ready = true;
			this.onExitMesure();
		} catch (error) {
			console.error(error);
		}
	}

	async changePourcentRefPartiel(ouverture) {
		const masse = this.getValeurMasseGranulo();
		const idxTamis = this.getIndexTamis(ouverture);
		const lstTamis = this.model[this.fieldId].granuloAdditionalInformation.tamis;
		if (lstTamis[idxTamis].pourcRefPartiel === '') {
			this.videLigneTamis(idxTamis);
		} else {
			if (lstTamis[idxTamis].pourcRefPartiel < 0) {
				lstTamis[idxTamis].pourcRefPartiel = 0;
			}
			if (lstTamis[idxTamis].pourcRefPartiel > 100) {
				lstTamis[idxTamis].pourcRefPartiel = 100;
			}
			const idxTamisPrec = this.getIndexTamisPrecedentSaisi(idxTamis);
			lstTamis[idxTamis].pourcRefPartiel = this.FormulesService.strEnDouble(lstTamis[idxTamis].pourcRefPartiel);
			if (isNaN(lstTamis[idxTamis].pourcRefPartiel)) {
				lstTamis[idxTamis].pourcRefPartiel = '';
				this.videLigneTamis(idxTamis);
			} else {
				if (idxTamisPrec > -1) {
					lstTamis[idxTamis].pourcPassant = this.FormulesService.soustraction(
						lstTamis[idxTamisPrec].pourcPassant,
						lstTamis[idxTamis].pourcRefPartiel
					);
					lstTamis[idxTamis].passant = this.FormulesService.division(
						this.FormulesService.multiplication(masse, lstTamis[idxTamis].pourcPassant),
						100
					);
					lstTamis[idxTamis].cumRefus = this.FormulesService.soustraction(masse, lstTamis[idxTamis].passant);
					lstTamis[idxTamis].refus = this.FormulesService.soustraction(lstTamis[idxTamis].cumRefus, lstTamis[idxTamisPrec].cumRefus);
				} else {
					lstTamis[idxTamis].pourcPassant = this.FormulesService.soustraction(100, lstTamis[idxTamis].pourcRefPartiel);
					lstTamis[idxTamis].passant = this.FormulesService.division(
						this.FormulesService.multiplication(masse, lstTamis[idxTamis].pourcPassant),
						100
					);
					lstTamis[idxTamis].cumRefus = this.FormulesService.soustraction(masse, lstTamis[idxTamis].passant);
					lstTamis[idxTamis].refus = lstTamis[idxTamis].cumRefus;
				}
				await this.recalculeTamisSuivants(idxTamis);
			}
		}
		this.changePourcentPassant(ouverture, '', 'changePouCenRefPar');
		this.onExitMesure();
	}

	async changePassant(ouverture) {
		const masse = this.getValeurMasseGranulo();
		const idxTamis = this.getIndexTamis(ouverture);
		const idxTamisPrec = this.getIndexTamisPrecedentSaisi(idxTamis);

		const lstTamis = this.model[this.fieldId].granuloAdditionalInformation.tamis;
		let message = '';
		if (parseInt(lstTamis[idxTamis].passant) > parseInt(masse)) {
			const libelle = this.getLibelleMasseGranulo();
			message = this.getMessage('passSupMassSec', libelle);
		}
		if (lstTamis[idxTamis].passant === '') {
			this.videLigneTamis(idxTamis);
		} else {
			lstTamis[idxTamis].passant = this.FormulesService.strEnDouble(lstTamis[idxTamis].passant);
			if (isNaN(lstTamis[idxTamis].passant)) {
				lstTamis[idxTamis].passant = '';
				this.videLigneTamis(idxTamis);
			} else {
				lstTamis[idxTamis].pourcPassant = this.FormulesService.division(
					this.FormulesService.multiplication(lstTamis[idxTamis].passant, 100),
					masse
				);
				lstTamis[idxTamis].cumRefus = this.FormulesService.soustraction(masse, lstTamis[idxTamis].passant);
				if (idxTamisPrec > -1) {
					lstTamis[idxTamis].refus = this.FormulesService.soustraction(lstTamis[idxTamis].cumRefus, lstTamis[idxTamisPrec].cumRefus);
					lstTamis[idxTamis].pourcRefPartiel = this.FormulesService.soustraction(
						lstTamis[idxTamisPrec].pourcPassant,
						lstTamis[idxTamis].pourcPassant
					);
				} else {
					lstTamis[idxTamis].refus = lstTamis[idxTamis].cumRefus;
					lstTamis[idxTamis].pourcRefPartiel = this.FormulesService.soustraction(100, lstTamis[idxTamis].pourcPassant);
				}
				await this.recalculeTamisSuivants(idxTamis);
			}
		}
		this.changePourcentPassant(ouverture, message, 'changePass');
		this.onExitMesure();
	}

	async changeRefus(ouverture) {
		const masse = this.getValeurMasseGranulo();
		const idxTamis = this.getIndexTamis(ouverture);
		const idxTamisPrec = this.getIndexTamisPrecedentSaisi(idxTamis);
		const lstTamis = this.model[this.fieldId].granuloAdditionalInformation.tamis;
		let message = '';
		if (parseInt(lstTamis[idxTamis].passant) > parseInt(masse)) {
			const libelle = this.getLibelleMasseGranulo();
			message = this.getMessage('refSupMassSec', libelle);
		}
		if (lstTamis[idxTamis].refus === '') {
			this.videLigneTamis(idxTamis);
		} else {
			lstTamis[idxTamis].refus = this.FormulesService.strEnDouble(lstTamis[idxTamis].refus);
			if (isNaN(lstTamis[idxTamis].refus)) {
				lstTamis[idxTamis].refus = '';
				this.videLigneTamis(idxTamis);
			} else {
				if (idxTamisPrec > -1) {
					lstTamis[idxTamis].cumRefus = this.FormulesService.somme(lstTamis[idxTamis].refus, lstTamis[idxTamisPrec].cumRefus);
					lstTamis[idxTamis].passant = this.FormulesService.soustraction(masse, lstTamis[idxTamis].cumRefus);
					lstTamis[idxTamis].pourcPassant = this.FormulesService.division(
						this.FormulesService.multiplication(lstTamis[idxTamis].passant, 100),
						masse
					);
					lstTamis[idxTamis].pourcRefPartiel = this.FormulesService.soustraction(
						lstTamis[idxTamisPrec].pourcPassant,
						lstTamis[idxTamis].pourcPassant
					);
				} else {
					lstTamis[idxTamis].cumRefus = lstTamis[idxTamis].refus;
					lstTamis[idxTamis].passant = this.FormulesService.soustraction(masse, lstTamis[idxTamis].cumRefus);
					lstTamis[idxTamis].pourcPassant = this.FormulesService.division(
						this.FormulesService.multiplication(lstTamis[idxTamis].passant, 100),
						masse
					);
					lstTamis[idxTamis].pourcRefPartiel = this.FormulesService.soustraction(100, lstTamis[idxTamis].pourcPassant);
				}
				await this.recalculeTamisSuivants(idxTamis);
			}
		}
		this.changePourcentPassant(ouverture, message, 'changeRef');
		this.onExitMesure();
	}

	async recalculeTamisSuivants(idxTamis, recalcCumRef = true) {
		this.arrondiTamis(idxTamis);

		const lstTamis = this.model[this.fieldId].granuloAdditionalInformation.tamis;
		let passantPrec = lstTamis[idxTamis].passant;
		let cumRefusPrec = lstTamis[idxTamis].cumRefus;
		let pourcPassantPrec = lstTamis[idxTamis].pourcPassant;
		for (let j = idxTamis + 1; j < lstTamis.length; j++) {
			if (lstTamis[j].isDisplay === true && lstTamis[j].pourcPassant) {
				lstTamis[j].refus = this.FormulesService.soustraction(passantPrec, lstTamis[j].passant);
				lstTamis[j].cumRefus = recalcCumRef ? this.FormulesService.somme(cumRefusPrec, lstTamis[j].refus) : lstTamis[j].cumRefus;
				lstTamis[j].pourcRefPartiel = this.FormulesService.soustraction(pourcPassantPrec, lstTamis[j].pourcPassant);
				this.arrondiTamis(j);
				passantPrec = lstTamis[j].passant;
				cumRefusPrec = lstTamis[j].cumRefus;
				pourcPassantPrec = lstTamis[j].pourcPassant;
			}
		}
		if (!this.recalculGranuloEnCours) {
			await this.courbeGranulo();
		}
	}

	arrondiTamis(idxTamis) {
		const lstTamis = this.model[this.fieldId].granuloAdditionalInformation.tamis;

		lstTamis[idxTamis].cumRefusArrondi = this.arrondiValeur(lstTamis[idxTamis], false, lstTamis[idxTamis].cumRefus);
		lstTamis[idxTamis].passantArrondi = this.arrondiValeur(lstTamis[idxTamis], false, lstTamis[idxTamis].passant);
		lstTamis[idxTamis].pourcPassantArrondi = this.arrondiValeur(lstTamis[idxTamis], true, lstTamis[idxTamis].pourcPassant);
		lstTamis[idxTamis].pourcRefPartielArrondi = this.arrondiValeur(lstTamis[idxTamis], true, lstTamis[idxTamis].pourcRefPartiel);
		lstTamis[idxTamis].refusArrondi = this.arrondiValeur(lstTamis[idxTamis], false, lstTamis[idxTamis].refus);
	}

	getSerieTamis(tamis) {
		if (tamis) {
			return this.field.serieTamis.find((e) => e.ouverture === tamis.ouverture);
		}
		return null;
	}

	getArrondi(tamis, pourcent) {
		const serieTamis = tamis;
		if (pourcent) {
			return serieTamis.arrondiPourcent;
		}
		return serieTamis.arrondiPoids;
	}

	arrondiValeur(tamis, pourcent, valeur) {
		const arrondi = this.getArrondi(tamis, pourcent);
		const x = this.FormulesService.strEnDouble(valeur);
		if (!isNaN(x)) {
			const objetToRound = [{ key: x, val: x, rounder: arrondi }];
			return this.Interpreteur.getRoundedValue(objetToRound);
		}
		return null;
	}

	async deleteTamis(tamis) {
		const idxTamis = this.getIndexTamis(tamis.ouverture);
		this.model[this.fieldId].granuloAdditionalInformation.tamis[idxTamis].isDisplay = false;

		if (idxTamis == 0) {
			await this.recalculeGranulo(true);
		} else {
			await this.recalculeTamisSuivants(this.getIndexTamisPrecedentSaisi(idxTamis));
		}

		this.onExitMesure();
	}

	ajoutTamis = function (size, parentSelector) {
		const _this = this;
		const modalInstance = this.$uibModal.open({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-body',
			templateUrl: 'ajoutTamisModalContent',
			controllerAs: '$ctrl',
			controller: [
				'$uibModalInstance',
				'_',
				function ($uibModalInstance, _) {
					const $ctrl = this;
					this.tamisPresents = [];
					this.tamisAAjouter = [];
					this.idEssai = _this.fieldIdEssai || _this.field.essai.id;
					this.allTamis = _this.model[_this.fieldId].granuloAdditionalInformation.tamis;

					const lstTamis = this.allTamis.filter((e) => e.isDisplay === true);

					if (!lstTamis) {
						lstTamis = [];
					}

					for (let i = 0; i < lstTamis.length; i++) {
						this.tamisPresents.push({
							ouverture: lstTamis[i].ouverture
						});
					}

					this.$uibModalInstance = $uibModalInstance;
					this._ = _;

					this.selectTamis = function (tamis) {
						const idx = this._.findIndex(this.tamisAAjouter, { idTamis: tamis.idTamis });
						if (idx == -1) {
							this.tamisAAjouter.push(tamis);
						}
					};
					this.onDelete = function (id) {
						const idx = this._.findIndex(this.tamisAAjouter, { idTamis: id });
						if (idx > -1) {
							this.tamisAAjouter.splice(idx, 1);
						}
					};
					this.onDeleteAll = function (id) {
						this.tamisAAjouter = [];
					};

					this.ok = function () {
						this.$uibModalInstance.close({ tamis: this.tamisAAjouter });
					};
					this.cancel = function () {
						this.$uibModalInstance.close();
					};
				}
			]
		});

		const that = this;
		modalInstance.result.then(function (result) {
			if (result && result.tamis) {
				result.tamis.forEach((e) => that.addTamis(e));
			}
		});
	};

	async addTamis(tamis) {
		const idxTamis = this.getIndexTamis(tamis.ouverture);
		this.model[this.fieldId].granuloAdditionalInformation.tamis[idxTamis].isDisplay = true;
		const idxTamisPrec = this.getIndexTamisPrecedentSaisi(idxTamis);
		if (idxTamisPrec >= 0) {
			const tamisPrec = this.model[this.fieldId].granuloAdditionalInformation.tamis[idxTamisPrec];
			this.model[this.fieldId].granuloAdditionalInformation.tamis[idxTamis].cumRefus = tamisPrec.cumRefus;
			this.model[this.fieldId].granuloAdditionalInformation.tamis[idxTamis].passant = tamisPrec.passant;
			this.model[this.fieldId].granuloAdditionalInformation.tamis[idxTamis].pourcPassant = tamisPrec.pourcPassant;
			this.model[this.fieldId].granuloAdditionalInformation.tamis[idxTamis].pourcRefPartiel = tamisPrec.pourcRefPartiel;
			this.model[this.fieldId].granuloAdditionalInformation.tamis[idxTamis].refus = tamisPrec.refus;
		} else {
			this.model[this.fieldId].granuloAdditionalInformation.tamis[idxTamis].cumRefus = 0;
			this.model[this.fieldId].granuloAdditionalInformation.tamis[idxTamis].passant = 100;
			this.model[this.fieldId].granuloAdditionalInformation.tamis[idxTamis].pourcPassant = 100;
			this.model[this.fieldId].granuloAdditionalInformation.tamis[idxTamis].pourcRefPartiel = 0;
			this.model[this.fieldId].granuloAdditionalInformation.tamis[idxTamis].refus = 0;
		}
		this.arrondiTamis(idxTamis);
		this.onExitMesure();
	}

	exportGraphique() {
		const url = this.graphGran.toBase64Image();
		document.getElementById('url').src = url;
		//this.courbeGranulo();
	}

	checkEnabled(field) {
		//renvoyer true si on doit désactiver le composant
		const forceEditSessPrincipale = this.principal && field.forceSaisie;
		return this.lecture || (field.formule && !forceEditSessPrincipale && !field.isNotDefaultFormule);
	}

	debug(item) {
		console.log(item);
	}

	async downloadDocument(fileName, extension, code) {
		try {
			const resultat = await this.DocumentsService.print(fileName, extension, code);

			const data = resultat.data;
			const status = resultat.status;
			let headers = resultat.headers;

			headers = headers();

			const contentType = headers['content-type'];

			const linkElement = document.createElement('a');

			const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
			const url = window.URL.createObjectURL(blob);
			linkElement.setAttribute('href', url);
			linkElement.setAttribute('download', fileName + '.' + extension);

			const clickEvent = new MouseEvent('click', {
				view: window,
				bubbles: true,
				cancelable: false
			});
			linkElement.dispatchEvent(clickEvent);
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	setGranuloGraphId() {
		if (this.nature.toLowerCase() == 'analysegranulo') {
			if (this.saisie) {
				const arrayEssaiGran = this.saisie.mesures
					.map((x) => {
						const granulo = Object.values(x.sousEssais).find((x) => x.idNature == 8);
						if (granulo) {
							return {
								id: x.id,
								granulo: Object.values(x.sousEssais)
							};
						}
					})
					.filter((x) => x);

				if (arrayEssaiGran.length > 1) {
					const current = this.field.essai.id;
					const indexOfCurrent = arrayEssaiGran.findIndex((x) => x.id == current);
					if (indexOfCurrent > 0) {
						this.graphId = this.graphId + '_' + indexOfCurrent;
					}
				}
			}
		}
	}
	/** GESTION COLONNE DE TAMIS */
	async openModalColonneTamis() {
		try {
			let res = null;
			this.colonneSelected = this.saisie.mesures.find((x) => x.colonneTamis)?.colonneTamis?.id;
			if (this.nature.toLowerCase() == 'analysegranulo') {
				const modalInstance = this.$uibModal.open({
					component: 'prelevementGestionColonneTamisComponent',
					animation: true,
					size: 'lg',
					resolve: {
						prelevement: () => this.saisie,
						mesure: () => this.field,
						colonneSelected: () => this.colonneSelected,
						configurationBalance: () => this.configuration
					}
				});

				res = await modalInstance.result;
				if (res && res.colonne && res.colonne.tamis && res.colonne.tamis.length > 0) {
					this.colonneSelected = res.colonne.id;
					res.colonne.tamis = res.colonne.tamis.sort((a, b) => {
						return b.tamis.ouverture - a.tamis.ouverture;
					});
					for (let i = 0; i < res.colonne.tamis.length; i++) {
						const tamis = res.colonne.tamis[i];
						if (tamis.asChanged) {
							if (tamis.tamis.ouverture) {
								const idxTamis = this.getIndexTamis(tamis.tamis.ouverture);
								if (tamis.newRefus < 0) {
									this.field.granuloAdditionalInformation.tamis[idxTamis].refus = 0;
									this.model[this.fieldId].granuloAdditionalInformation.tamis[idxTamis].refus = 0;
								} else {
									this.field.granuloAdditionalInformation.tamis[idxTamis].refus = tamis.newRefus;
									this.model[this.fieldId].granuloAdditionalInformation.tamis[idxTamis].refus = tamis.newRefus;
								}
								this.field.colonneTamis = res.colonne;
								await this.changeRefus(tamis.tamis.ouverture);
							}
						}
					}
					if (res && res.message) {
						this.notification.success('COLONNE_TAMIS.EVENTS.UPDATE_SUCCESS');
					}
				} else {
					this.colonneSelected = undefined;
				}

				const idx = this.saisie.mesures.findIndex((x) => x.id === this.field.id);
				if (idx > -1) {
					this.saisie.mesures[idx].colonneTamis = { id: this.colonneSelected };
				}
			}
		} catch (ex) {}
	}

	checkValuesGranulo(oldListTamis, lstTamis, idxTamis, idxTamisPrec, idxNextTamis, mess = '', parent = '') {
		const isFoundPrevTam = idxTamisPrec > -1 ? true : false;
		const isFoundNextTam = idxNextTamis > -1 ? true : false;
		let message = mess;
		let inconsistency = false;
		if (isFoundPrevTam) {
			if (lstTamis[idxTamis].pourcPassant > lstTamis[idxTamisPrec].pourcPassant) {
				inconsistency = true;
				if (mess === '') {
					const code =
						parent === ''
							? 'pourCPassSupTamInf'
							: parent === 'changeCumRefus'
							? 'cumRefSupTamInf'
							: parent === 'changePouCenRefPar'
							? 'PouCenRefParInf'
							: parent === 'changePass'
							? 'passInf'
							: parent === 'changeRef'
							? 'refInf'
							: '';
					message = this.getMessage(code);
				}
			}
		}
		if (isFoundNextTam) {
			if (lstTamis[idxTamis].pourcPassant < lstTamis[idxNextTamis].pourcPassant) {
				inconsistency = true;
				if (mess === '') {
					const code =
						parent === ''
							? 'pourCPassSupTamSup'
							: parent === 'changeCumRefus'
							? 'cumRefSupTamSup'
							: parent === 'changePouCenRefPar'
							? 'PouCenRefParSup'
							: parent === 'changePass'
							? 'passSup'
							: parent === 'changeRef'
							? 'refSup'
							: '';
					message = this.getMessage(code);
				}
			}
		}
		if (((isFoundPrevTam || isFoundNextTam) && inconsistency) || message !== '') {
			if (!this.modInstance) {
				this.openModalAlertGran(message);
				if (this.typAlertErrGran === 3) {
					this.notification.info(this.$translate.instant('PRELEVEMENTS.GRANULO.PREVIOUS_VALUE_CONSERVED'));
					lstTamis[idxTamis].pourcPassant = angular.copy(oldListTamis[idxTamis].pourcPassant);
				}
			}
		}
	}

	async openModalAlertGran(message) {
		try {
			let res = null;
			this.modInstance = this.ModalService.warning({
				modalTitle: this.$translate.instant('PRELEVEMENTS.GRANULO.ERROR_MESSAGE'),
				modalMsg: message,
				headerClass: 'modal-danger',
				backdrop: false
			});
			res = await this.modInstance.result;
			if (!res) {
				this.modInstance = null;
			}
		} catch (error) {
			console.error(error);
		}
	}

	getMessage(code, libelle = '') {
		let message = '';
		const temp =
			this.typAlertErrGran === 3
				? this.$translate.instant('PRELEVEMENTS.GRANULO.PROHIBITION_MESSAGE')
				: this.$translate.instant('PRELEVEMENTS.GRANULO.ALERT_MESSAGE');
		switch (code) {
			case 'cumRefSupMassSec':
				message =
					temp +
					this.$translate.instant('PRELEVEMENTS.CUMUL_REFUS') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.PASSANT_OR_REFUS_SUP_POIDS_SEC') +
					this.$translate.instant('PRELEVEMENTS.SOUS_ESSAI') +
					libelle;
				break;
			case 'passSupMassSec':
				message =
					temp +
					this.$translate.instant('PRELEVEMENTS.PASSANT') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.PASSANT_OR_REFUS_SUP_POIDS_SEC') +
					this.$translate.instant('PRELEVEMENTS.SOUS_ESSAI') +
					libelle;
				break;
			case 'pourCPassSupTamInf':
				message =
					temp +
					this.$translate.instant('PRELEVEMENTS.POURCENT_PASSANT') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.PREVIOUS_INF') +
					this.$translate.instant('PRELEVEMENTS.TAMIS') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.HIGHER') +
					(this.typAlertErrGran === 3 ? '' : this.$translate.instant('PRELEVEMENTS.GRANULO.INCORRECT'));
				break;
			case 'pourCPassSupTamSup':
				message =
					temp +
					this.$translate.instant('PRELEVEMENTS.POURCENT_PASSANT') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.PREVIOUS_SUP') +
					this.$translate.instant('PRELEVEMENTS.TAMIS') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.LOWER') +
					(this.typAlertErrGran === 3 ? '' : this.$translate.instant('PRELEVEMENTS.GRANULO.INCORRECT'));
				break;
			case 'cumRefSupTamSup':
				message =
					temp +
					this.$translate.instant('PRELEVEMENTS.CUMUL_REFUS') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.PREVIOUS_INF') +
					this.$translate.instant('PRELEVEMENTS.TAMIS') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.LOWER') +
					(this.typAlertErrGran === 3 ? '' : this.$translate.instant('PRELEVEMENTS.GRANULO.INCORRECT'));
				break;
			case 'cumRefSupTamInf':
				message =
					temp +
					this.$translate.instant('PRELEVEMENTS.CUMUL_REFUS') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.PREVIOUS_SUP') +
					this.$translate.instant('PRELEVEMENTS.TAMIS') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.HIGHER') +
					(this.typAlertErrGran === 3 ? '' : this.$translate.instant('PRELEVEMENTS.GRANULO.INCORRECT'));
				break;
			case 'PouCenRefParSup':
				message =
					temp +
					this.$translate.instant('PRELEVEMENTS.POURCENT_REFUS_PARTIEL') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.CALCULATED') +
					this.$translate.instant('PRELEVEMENTS.POURCENT_PASSANT') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.PREVIOUS_SUP') +
					this.$translate.instant('PRELEVEMENTS.TAMIS') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.LOWER') +
					(this.typAlertErrGran === 3 ? '' : this.$translate.instant('PRELEVEMENTS.GRANULO.INCORRECT'));
				break;
			case 'PouCenRefParInf':
				message =
					temp +
					this.$translate.instant('PRELEVEMENTS.POURCENT_REFUS_PARTIEL') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.CALCULATED') +
					this.$translate.instant('PRELEVEMENTS.POURCENT_PASSANT') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.PREVIOUS_INF') +
					this.$translate.instant('PRELEVEMENTS.TAMIS') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.HIGHER') +
					(this.typAlertErrGran === 3 ? '' : this.$translate.instant('PRELEVEMENTS.GRANULO.INCORRECT'));
				break;
			case 'passSup':
				message =
					temp +
					this.$translate.instant('PRELEVEMENTS.PASSANT') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.CALCULATED') +
					this.$translate.instant('PRELEVEMENTS.POURCENT_PASSANT') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.PREVIOUS_SUP') +
					this.$translate.instant('PRELEVEMENTS.TAMIS') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.LOWER') +
					(this.typAlertErrGran === 3 ? '' : this.$translate.instant('PRELEVEMENTS.GRANULO.INCORRECT'));
				break;
			case 'passInf':
				message =
					temp +
					this.$translate.instant('PRELEVEMENTS.PASSANT') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.CALCULATED') +
					this.$translate.instant('PRELEVEMENTS.POURCENT_PASSANT') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.PREVIOUS_INF') +
					this.$translate.instant('PRELEVEMENTS.TAMIS') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.HIGHER') +
					(this.typAlertErrGran === 3 ? '' : this.$translate.instant('PRELEVEMENTS.GRANULO.INCORRECT'));
				break;
			case 'refSup':
				message =
					temp +
					this.$translate.instant('PRELEVEMENTS.REFUS') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.CALCULATED') +
					this.$translate.instant('PRELEVEMENTS.POURCENT_PASSANT') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.PREVIOUS_SUP') +
					this.$translate.instant('PRELEVEMENTS.TAMIS') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.LOWER') +
					(this.typAlertErrGran === 3 ? '' : this.$translate.instant('PRELEVEMENTS.GRANULO.INCORRECT'));
				break;
			case 'refInf':
				message =
					temp +
					this.$translate.instant('PRELEVEMENTS.REFUS') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.CALCULATED') +
					this.$translate.instant('PRELEVEMENTS.POURCENT_PASSANT') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.PREVIOUS_INF') +
					this.$translate.instant('PRELEVEMENTS.TAMIS') +
					this.$translate.instant('PRELEVEMENTS.GRANULO.HIGHER') +
					(this.typAlertErrGran === 3 ? '' : this.$translate.instant('PRELEVEMENTS.GRANULO.INCORRECT'));
				break;
			default:
				break;
		}
		return message;
	}
}
