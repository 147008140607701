// import AvEsController from './pont.comptable.model';

export default class AvEsController {
	static $inject = ['$state', '$stateParams', 'CentreGestionService', 'ModalService', 'notification', '$translate', '$filter'];

	constructor($state, $stateParams, CentreGestionService, ModalService, notification, $translate, $filter) {
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.CentreGestionService = CentreGestionService;
		this.ModalService = ModalService;
		this.notification = notification;
		this.$translate = $translate;
		this.$filter = $filter;
		this.nonEligible = [
			'prestationsLiees',
			'transportsLies',
			'produitsLies',
			'tvaProduits',
			'tvaPresta',
			'tvaTransp',
			'compteTiers',
			'modePaiement',
			//'compteAnalytiqueVenteAchat',
			//'compteVenteAchat',
			//'compteComplVenteAchat',
			'compteStock',
			'compteConsommation',
			'protocoleSecurite'
		];
	}

	$onInit() {
		this.codeParam = this.parametre.codeParametre;
		this.parametre = this.parametre;
		this.changedValue = [false, false];
	}

	hasFiltre(parametre, filtre) {
		if (parametre[filtre] != null) {
			return true;
		}
		return false;
	}

	eligibleAvEs(codeParametre) {
		return this.nonEligible.indexOf(codeParametre) !== -1 ? false : true;
	}

	getHeritage(parent) {
		if (parent == null) {
			return '<span class="text-success">' + this.$translate.instant('CENTRE_GESTION.VAL_PROPRE') + '</span>';
		} else if (parent.idParent != -1 && parent.domaine != null) {
			return '<span class="text-info">' + this.$translate.instant('CENTRE_GESTION.HERITAGE', { libelle: parent.parentLibelle }) + '</span>';
		} else if (parent.domaine == 'defaultValue') {
			return '<span>' + this.$translate.instant('CENTRE_GESTION.DEFAULTVALUE') + '</span>';
		}

		this.notification.error("Une erreur d'héritage a été détectée.");
	}

	async reinit(objetChamps) {
		// SL cas particulier : le champs est input avec la valeur 0
		if (objetChamps.id) {
			try {
				await this.CentreGestionService.effacer(objetChamps.id);
				this.onDelete();
				// this.parametre.donnees[objetChamps.entreeSortie || objetChamps.achatVente].valeurs[0] = retour.valeurs[0];
				// this.parametre.donnees[objetChamps.entreeSortie || objetChamps.achatVente].parent = retour.parent;
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
			this.onDelete();
		}
	}

	async upsertElement(objet, es, groupIndex) {
		const element = objet.valeurs[0];
		const parent = objet.parent;
		// cas ou on sélectionne "Sélectionner", avoir le même comportement que la gomme
		// il faut que le type soit int parce que, par exemple le mode de tarage envoie 0 sous forme de string, donc impossible de le sélectionner
		// if (objet.valeurs[0].valeur == 0 && objet.valeurs[0].elementType == "int") {
		//     this.reinit(objet.valeurs[0]);
		// } else {
		if (this.frontFilters) {
			if (this.filterToBack) {
				const entries = Object.entries(this.filterToBack);
				for (let i = 0; i < entries.length; i++) {
					element[entries[i][0]] = entries[i][1];
				}
			}

			element.dateApplication = this.dateApplication;
			element.codeDomaine = this.domaine;
			element.entreeSortie = es;
		}
		try {
			if (parent == null) {
				await this.CentreGestionService.update(element);
			} else {
				const nouvelId = await this.CentreGestionService.insert(element);
				// SL pour la maj des données de l'objet,
				// il ne faut pas passer par la référence, mais par l'objet directement
				objet.valeurs[0].id = nouvelId;
				objet.parent = null;
			}
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			this.changedValue[es] = true;
			this.activeTab = groupIndex;
		}
		//}
	}

	// SL factorisation + modification recuperation de l'objet filtre
	getFilterObjectSL(thisDotFiltres, element) {
		// this.monFiltre = {};
		const pourFiltreListing = this.getListingPropFiltre(thisDotFiltres);
		for (let index = 0; index < pourFiltreListing.length; index++) {
			const item = pourFiltreListing[index];
			if (item.id != null) {
				element[this.getProperty(item.domaine)] = item.id;
			} else {
				// _.remove(this.monFiltre, { id: null });
			}
		}
	}

	getListingPropFiltre(filtres) {
		const listing = [];
		const keyNames = Object.keys(filtres);
		for (let i = 0; i < keyNames.length; i++) {
			const element = keyNames[i];
			switch (keyNames[i]) {
				case 'SiteCommercial':
				case 'siteCommercial':
					this.domaineFiltre = 'SiteCommercial';
					var id = null;
					if (this.frontFilters.siteCommercial) {
						id = this.frontFilters.siteCommercial.id;
					}
					break;
				case 'Societes':
				case 'societes':
					this.domaineFiltre = 'Societes';
					var id = this.frontFilters.societes.id;
					break;
				// case 'producteur':
				//     this.domaineFiltre = "producteur";
				//     var id = this.frontFilters.producteur.id
				//     break;
				// case 'tva':
				//     this.domaineFiltre = "Tva";
				//     var id = this.frontFilters.tva.id
				//     break;
				case 'siteTransporteur':
					// this.domaineFiltre = "SiteTransporteur";
					// var id = this.frontFilters.siteTransporteur.id
					break;
				case 'siteClient':
					this.domaineFiltre = 'Client';
					var id = this.frontFilters.siteClient.id;
					break;
				case 'typeClient':
					// this.domaineFiltre = "";
					// var id = this.frontFilters.typeClient
					break;
				case 'chantier':
					this.domaineFiltre = 'Chantier';
					var id = this.frontFilters.chantier.id;
					break;
				case 'produits':
					this.domaineFiltre = 'produits';
					var id = this.frontFilters.produit.id;
					break;
				default:
				// this.domaineFiltre = "";
				// var id = this.frontFilters.dateApplication
			}
			// add object into the listing
			listing.push({ domaine: this.domaineFiltre, id: id });
		}
		return listing;
	}

	isDependingOnTypeBenne() {
		if (this.parametre.codeParametre == 'quantiteTransportMinimumBenne') {
			return true;
		}
		return false;
	}

	isPontComptable() {
		if (
			this.codeParam == 'compteStock' ||
			this.codeParam == 'compteConsommation' ||
			this.codeParam == 'compteAnalytiqueVenteAchat' ||
			this.codeParam == 'compteVenteAchat' ||
			this.codeParam == 'compteComplVenteAchat'
		) {
			return true;
		}
		return false;
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	startCodeLoading() {
		this.codeLoading = true;
	}

	stopCodeLoading() {
		this.codeLoading = false;
	}
}
