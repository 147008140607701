//Config
import FacturesConfig from './_config/factures.config';
import FacturesRoutes from './_config/factures.routes';
//Components
import FacturesComponent from './components/factures';
import FacturesPontComponent from './components/factures-pont';
import FacturesCheckpontComponent from './components/factures-checkpont';
// import FactureEnteteComponent from './components/factures';
// services
import FacturesService from './services/factures.service';
import ArticlesService from './services/articles.service';
import ReglementsService from './services/reglements.service';
import FacturesCommunicationService from './services/factures.communication.service';

import FactureFormComponent from './components/facture-form';
import FactureFormEnteteComponent from './components/facture-form/entete';
import FactureFormRecapComponent from './components/facture-form/recap';
import FactureFormArticlesChantierComponent from './components/facture-form/articles-chantier';
import FactureArticlesBlockComponent from './components/facture-form/articles-block';
import FactureFormReglementComponent from './components/facture-form/reglements';
import FactureComposantComponent from './components/facture-composant/';

import FactureDetailComponent from './components/facture-detail';

import FactureValidator from './components/facture-form/facture.validator';
import FactureEnteteValidator from './components/facture-form/entete/facture.entete.validator';
import FactureArticleValidator from './components/facture-form/articles-chantier/articles.validator';

import FacturePopupComponent from './components/facture-popup';
import FacturesPrintComponent from './components/factures-print';

import ModaleUpdateComptantComponent from './components/facture-detail/modale-update-comptant';
const moduleName = 'app.massia.common.factures';

angular
	.module(moduleName, [])
	.config(FacturesConfig)
	.config(FacturesRoutes)
	.service('FacturesService', FacturesService)
	.service('ArticlesService', ArticlesService)
	.service('ReglementsService', ReglementsService)
	.service('FacturesCommunicationService', FacturesCommunicationService)
	.component('factures', FacturesComponent)
	.component('facturesPont', FacturesPontComponent)
	.component('facturesCheckpont', FacturesCheckpontComponent)
	.component('factureForm', FactureFormComponent)
	.component('factureFormEntete', FactureFormEnteteComponent)
	.component('factureFormRecap', FactureFormRecapComponent)
	.component('factureFormArticlesChantier', FactureFormArticlesChantierComponent)
	.component('factureArticlesBlock', FactureArticlesBlockComponent)
	.component('factureFormReglement', FactureFormReglementComponent)
	.component('factureDetail', FactureDetailComponent)
	.component('facturePopup', FacturePopupComponent)
	.component('facturesPrint', FacturesPrintComponent)
	.component('modaleUpdateComptant', ModaleUpdateComptantComponent)
	.component('factureComposant', FactureComposantComponent)
	.factory('FactureValidator', FactureValidator)
	.factory('FactureEnteteValidator', FactureEnteteValidator)
	.factory('FactureArticleValidator', FactureArticleValidator);

export default moduleName;
