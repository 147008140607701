EtatReglementsController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'EtatReglementsService',
	'notification'
];

export default function EtatReglementsController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	EtatReglementsService,
	notification
) {
	const vm = this;

	let previousTableState;
	const watchers = [];

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.$translate = $translate;
	vm.etatReglements = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.loadEtatReglements = loadEtatReglements;
	vm.print = print;
	vm.selectEtatReglement = selectEtatReglement;
	vm.selectItem = selectItem;

	async function init() {
		vm.etat = '-1';
		vm.societes = await EtatReglementsService.getSocietes();
		vm.clients = await EtatReglementsService.getClients();
		vm.produits = await EtatReglementsService.getProduits();
		getEtats();
		getEnumEtatReglements();
		if (localStorage.getItem('etatReglementStorage')) {
			loadEtatReglements();
		}
	}

	init();

	async function loadEtatReglements(tableState) {
		startLoading();

		const storage = JSON.parse(localStorage.getItem('etatReglementStorage'));

		if (storage) {
			vm.idSociete = storage.idSociete;
			vm.siteClient = {
				id: storage.idClient,
				libelle: storage.client
			};
			vm.dateDebut = storage.dateDebut;
			vm.dateFin = storage.dateFin;
			vm.idProduits = storage.idProduits;
			vm.montant = storage.montant;
			vm.etat = storage.etat;
			vm.produits.forEach((x) => {
				x.selected = typeof storage.idProduits.find((y) => y.id === x.id) !== 'undefined';
			});
		}
		vm.etatReglements = [];
		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		try {
			if (vm.idSociete) {
				const idClient = vm.siteClient ? vm.siteClient.id : null;
				const idProduits = vm.idProduits ? vm.idProduits.map((x) => x.id) : [];
				const etat = vm.etat === -1 ? null : vm.etat;
				const tmp = await EtatReglementsService.getEtatReglements(
					filters,
					sorts,
					pagination,
					vm.idSociete,
					idClient,
					vm.dateDebut,
					vm.dateFin,
					idProduits,
					vm.montant,
					etat
				);
				const data = tmp.table;
				vm.etatReglements = data.items;
				vm.total = tmp.total;

				if (previousTableState) {
					PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
				}
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			localStorage.removeItem('etatReglementStorage');
			stopLoading();
		}
	}

	async function selectItem() {
		const storage = JSON.parse(localStorage.getItem('etatReglementStorage'));
		if (storage) {
			storage.idProduits = vm.idProduits.map((x) => x.id);
		}
		vm.produits.forEach((x) => {
			x.selected = typeof storage.idProduits.find((y) => y.id === x.id) !== 'undefined';
		});
		localStorage.setItem('etatReglementStorage', JSON.stringify(storage));
	}

	async function print() {
		//let vm = vm;

		const modalInstance = ModalService.confirm({
			modalTitle: vm.$translate.instant('ETAT_REGLEMENTS.PRINTCHECKED.TITLE'),
			modalMsg: vm.$translate.instant('ETAT_REGLEMENTS.PRINTCHECKED.MESSAGE'),
			headerClass: 'modal-warning'
		});
		modalInstance.result.then(async function () {
			//this.startLoading();
			const fileName = Date.now();

			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const pagination = PaginationService.getPagination(previousTableState);

			const idClient = vm.siteClient ? vm.siteClient.id : null;
			const idProduits = vm.idProduits ? vm.idProduits.map((x) => x.id) : [];
			const etat = vm.etat === -1 ? null : vm.etat;
			const resultat = await EtatReglementsService.print(
				filters,
				sorts,
				pagination,
				vm.idSociete,
				idClient,
				vm.dateDebut,
				vm.dateFin,
				idProduits,
				vm.montant,
				etat
			);

			const data = resultat.data;
			const status = resultat.status;
			let headers = resultat.headers;

			headers = headers();

			const contentType = headers['content-type'];

			const fileExtention = '.xlsx';
			const linkElement = document.createElement('a');
			try {
				const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
				const url = window.URL.createObjectURL(blob);
				linkElement.setAttribute('href', url);
				linkElement.setAttribute('download', fileName + fileExtention);

				const clickEvent = new MouseEvent('click', {
					view: window,
					bubbles: true,
					cancelable: false
				});
				linkElement.dispatchEvent(clickEvent);
			} catch (ex) {
			} finally {
				//this.stopLoading();
			}
		});
	}

	async function getEtats() {
		startLoading();
		try {
			vm.listeEtats = await EtatReglementsService.getEtats();
		} catch (err) {
			if (err.data) {
				notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			stopLoading();
		}
	}

	async function getEnumEtatReglements() {
		startLoading();
		try {
			vm.listeEtatReglements = await EtatReglementsService.getEnumEtatReglements();
		} catch (err) {
			if (err.data) {
				notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			stopLoading();
		}
	}

	function selectEtatReglement(etatReglement) {
		if (etatReglement && etatReglement.idFacture) {
			showDetail(etatReglement.idFacture);
		}
	}

	function showDetail(id) {
		const etatReglementSearch = {
			idClient: vm.siteClient ? vm.siteClient.id : null,
			client: vm.siteClient ? vm.siteClient.libelle : null,
			idSociete: vm.idSociete,
			dateDebut: vm.dateDebut,
			dateFin: vm.dateFin,
			idProduits: vm.idProduits ? vm.idProduits : [],
			montant: vm.montant,
			etat: vm.etat === -1 ? null : vm.etat
		};
		localStorage.setItem('etatReglementStorage', JSON.stringify(etatReglementSearch));
		$state.go('factures.detail', { id: id });
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
