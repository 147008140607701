export default function Configure($stateProvider) {
	$stateProvider.state('contacts', {
		parent: 'common',
		url: '/contacts',
		//abstract: true,
		views: {
			common: {
				template: '<ui-view></ui-view>'
			}
		},
		ncyBreadcrumb: {
			// le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
			//skip: true
			label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
		}
	});

	$stateProvider.state('contacts.list', {
		url: '/:genre/list',
		template: '<personnes></personnes>',
		rights: { domain: 'contacts', right: 'read' },
		ncyBreadcrumb: {
			parent: 'contacts',
			label: '{{ "PERSONNES.BREADCRUMBS.CONTACTS_LIST" | translate}}'
		},
		// // on affiche la liste des personnes dans la navigation
		// navigation: {
		//     menu: 'donnees_parametres_donnees',
		//     translate: 'PERSONNES.BREADCRUMBS.PERSONNES_LIST'
		// },
		// // la liste des personnes fait partie des points d'entrée recherchables dans la navigation
		search: 'PERSONNES.BREADCRUMBS.CONTACTS_LIST'
	});

	$stateProvider.state('contacts.list.detail', {
		url: '/{id}',
		template: '<personne-detail></personne-detail>',
		rights: { domain: 'contacts', right: 'read' },
		ncyBreadcrumb: {
			parent: 'contacts.list',
			label: '{{ "PERSONNES.BREADCRUMBS.CONTACT_DETAIL" | translate }}'
		}
	});

	$stateProvider.state('contacts.new', {
		url: '/:genre/new',
		params: {
			fromDap: false,
			producteur: null,
			siteId: null
		},
		template: '<personnes-form></personnes-form>',
		rights: { domain: 'contacts', right: 'create' },
		ncyBreadcrumb: {
			// on décide que la liste est le parent des autres states
			// afin d'afficher personnes > ajouter
			parent: 'contacts.list({genre: "contact"})',
			label: '{{ "PERSONNES.BREADCRUMBS.CONTACT_NEW" | translate}}'
		},
		// l'ajout d'un arret fait partie des points d'entrée recherchables dans l'application
		search: 'PERSONNES.BREADCRUMBS.CONTACT_NEW'
	});

	$stateProvider.state('contacts.edit', {
		url: '/:genre/{id}/edit',
		template: '<personnes-form></personnes-form>',
		//rights: {domain: 'personnel', right: 'update'},
		ncyBreadcrumb: {
			// on décide que la liste est le parent des autres states
			// afin d'afficher personnes > modifier
			parent: 'contacts.list({genre: "contact"})',
			label: '{{ "PERSONNES.BREADCRUMBS.CONTACT_EDIT" | translate }}'
		}
	});

	/*$stateProvider.state('contacts.newType', {
        url: '/new-type',
        template: '<type-form></type-form>',
        rights: {domain: 'types', right: 'create'},
        params: {parentState:'contacts.new'},
        ncyBreadcrumb: {
            parent: 'contacts.new({genre: "contact"})',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_NEW" | translate}}'
        }
    });*/
}

Configure.$inject = ['$stateProvider'];
