export default function Configure($stateProvider) {
	$stateProvider.state('chantiers', {
		parent: 'common',

		url: '/chantiers',
		//abstract: true,
		views: {
			common: {
				template: '<ui-view></ui-view>'
			}
		},
		ncyBreadcrumb: {
			// le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
			//skip: true
			label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
		}
	});

	$stateProvider.state('chantiers.list', {
		url: '/list',

		template: '<chantiers></chantiers>',
		rights: { domain: 'chantiers', right: 'read' },
		ncyBreadcrumb: {
			parent: 'chantiers',
			label: '{{ "CHANTIERS.BREADCRUMBS.CHANTIERS_LIST" | translate}}'
		}
		// on affiche la liste des chantiers dans la navigation
		// navigation: {
		//     menu: 'donnees_parametres_donnees',
		//     translate: 'CHANTIERS.BREADCRUMBS.CHANTIERS_LIST',
		//     order: 14
		// },
		// // la liste des chantiers fait partie des points d'entrée recherchables dans la navigation
		// search: 'CHANTIERS.BREADCRUMBS.CHANTIERS_LIST'
	});

	$stateProvider.state('chantiers.list.detail', {
		url: '/{id}',
		params: {
			genre: 'chantiers'
		},
		template: '<chantier-detail></chantier-detail>',
		rights: { domain: 'chantiers', right: 'read' },
		ncyBreadcrumb: {
			// on décide que la liste est le parent des autres states
			// afin d'afficher chantiers > détail
			parent: 'chantiers.list',
			label: '{{ "CHANTIERS.BREADCRUMBS.CHANTIER_DETAIL" | translate }}'
		}
	});

	$stateProvider.state('chantiers.new', {
		url: '/new',
		params: {
			genre: 'chantiers',
			dap: null,
			fromDap: false
		},
		template: '<chantier-form></chantier-form>',
		rights: { domain: 'chantiers', right: 'create' },
		ncyBreadcrumb: {
			// on décide que la liste est le parent des autres states
			// afin d'afficher chantiers > ajouter
			parent: 'chantiers.list',
			label: '{{ "CHANTIERS.BREADCRUMBS.CHANTIER_NEW" | translate}}'
		},
		// l'ajout d'un chantier fait partie des points d'entrée recherchables dans l'application
		search: 'CHANTIERS.BREADCRUMBS.CHANTIER_NEW'
	});

	$stateProvider.state('chantiers.edit', {
		url: '/{id}/edit',
		params: {
			genre: 'chantiers'
		},
		template: '<chantier-form></chantier-form>',
		rights: { domain: 'chantiers', right: 'update' },
		ncyBreadcrumb: {
			// on décide que la liste est le parent des autres states
			// afin d'afficher chantiers > modifier
			parent: 'chantiers.list',
			label: '{{ "CHANTIERS.BREADCRUMBS.CHANTIER_EDIT" | translate }}'
		}
	});
}

Configure.$inject = ['$stateProvider'];
