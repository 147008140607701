export default function Configure($stateProvider) {
	$stateProvider.state('daps', {
		parent: 'gestion',
		url: '/daps',
		views: {
			gestion: {
				template: '<ui-view></ui-view>'
			}
		},
		ncyBreadcrumb: {
			label: '{{ "LAYOUT.NAVIGATION.PESEES_FACTURATIONS" | translate }}'
		}
	});

	$stateProvider.state('daps.list', {
		url: '/list',
		template: '<daps></daps>',
		params: {
			fromDapDetail: false
		},
		rights: { domain: 'dap', right: 'read' },
		ncyBreadcrumb: {
			label: '{{ "DAPS.BREADCRUMBS.DAPS_LIST" | translate}}'
		},
		navigation: {
			menu: 'inertes',
			translate: 'DAPS.BREADCRUMBS.DAPS_LIST',
			order: 0
		},
		search: 'DAPS.BREADCRUMBS.DAPS_LIST'
	});

	$stateProvider.state('daps.list.detail', {
		url: '/{id}',
		template: '<dap-detail></dap-detail>',
		rights: { domain: 'dap', right: 'read' },
		ncyBreadcrumb: {
			parent: 'daps.list',
			label: '{{ "DAPS.BREADCRUMBS.DAP_DETAIL" | translate }}'
		}
	});

	$stateProvider.state('daps.new', {
		url: '/new',
		template: '<dap-form></dap-form>',
		rights: { domain: 'dap', right: 'create' },
		ncyBreadcrumb: {
			parent: 'daps.list',
			label: '{{ "DAPS.BREADCRUMBS.DAP_NEW" | translate}}'
		},
		search: 'DAPS.BREADCRUMBS.DAP_NEW'
	});

	$stateProvider.state('daps.edit', {
		url: '/{id}/edit',
		template: '<dap-form></dap-form>',
		rights: { domain: 'dap', right: 'update' },
		ncyBreadcrumb: {
			parent: 'daps.list',
			label: '{{ "DAPS.BREADCRUMBS.DAP_EDIT" | translate }}'
		}
	});

	$stateProvider.state('daps.attente', {
		url: '/attente/{id}',
		params: {
			chantierId: null,
			transporteurId: null,
			transporteurIndex: null,
			producteurId: null
		},
		template: '<dap-attente-form></dap-attente-form>',
		rights: { domain: 'dap', right: 'update' },
		ncyBreadcrumb: {
			parent: 'daps.list',
			label: '{{ "DAPS.BREADCRUMBS.DAP_ATTENTE_DETAIL" | translate }}'
		}
	});

	$stateProvider.state('daps.validate', {
		url: '/validate/{id}',
		template: '<dap-attente-converter></dap-attente-converter>',
		rights: { domain: 'dap', right: 'update' },
		ncyBreadcrumb: {
			parent: 'daps.list',
			label: '{{ "DAPS.BREADCRUMBS.DAP_ATTENTE_VALIDATE" | translate }}'
		}
	});
}

Configure.$inject = ['$stateProvider'];
