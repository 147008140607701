const serviceUrl = `${__configuration.apiUrl}/massia/gestion/etat-reglements`;

export default class EtatReglementsService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
	}

	async getEtatReglements(filters, sorts, pagination, idSociete, idClient, dateDebut, dateFin, idProduits, montant, etat) {
		const filtres = {
			idSociete: idSociete,
			idClient: idClient,
			dateDebut: dateDebut,
			dateFin: dateFin,
			idProduits: idProduits,
			montant: montant,
			etat: etat
		};
		const res = await this.$http.get(serviceUrl, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {}),
				filtres: JSON.stringify(filtres || {})
			}
		});

		return res.data;
	}

	async print(filters, sorts, pagination, idSociete, idClient, dateDebut, dateFin, idProduits, montant, etat) {
		const url = `${serviceUrl}/print`;
		const filtres = {
			idSociete: idSociete,
			idClient: idClient,
			dateDebut: dateDebut,
			dateFin: dateFin,
			idProduits: idProduits,
			montant: montant,
			etat: etat
		};
		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {}),
				filtres: JSON.stringify(filtres || {})
			},
			responseType: 'arraybuffer'
		});
		return res;
	}

	async getSocietes() {
		const siteUrl = `${__configuration.apiUrl}/massia/societes-commerciales`;
		const res = await this.$http.get(siteUrl);

		return res.data;
	}

	async getClients() {
		const siteUrl = `${__configuration.apiUrl}/massia/sites-clients/headers`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}

	async getProduits() {
		const siteUrl = `${__configuration.apiUrl}/massia/produits/allHeader`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}

	async getEtats() {
		const url = 'assets/enum/factures/etat.json';
		const res = await this.$http.get(url);
		return res.data;
	}

	async getEnumEtatReglements() {
		const url = 'assets/enum/factures/etatReglement.json';
		const res = await this.$http.get(url);
		return res.data;
	}
}
