OffreDemandeValidator.$inject = ['validator'];

export default function OffreDemandeValidator(validator) {
	const instance = new validator();

	instance.ruleFor('idSociete').notEmpty().withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('idClient').notEmpty().withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('demandeDateValidite').notEmpty().withMessage('VALIDATION_NOTEMPTY');
	//instance.ruleFor('demandeDateValidite').greaterThan('demandeDateCreation').withMessage('VALIDATION_FAX');

	instance.ruleFor('demandeDateCreation').notEmpty().withMessage('VALIDATION_NOTEMPTY');
	// instance.ruleFor('demandeDateCreation').must(returnTrue).withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('demandeDateDebut').notEmpty().withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('demandeDateFin').notEmpty().withMessage('VALIDATION_NOTEMPTY');

	function getDateValidite(obj, prop) {
		return obj.demandeDateValidite;
	}

	return instance;
}
