SyntheseModelesController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'SyntheseModelesService',
	'notification',
	'MassiaApplicationService',
	'$transitions'
];
export default {
	template: require('./synthesemodeles.tpl.html'), //'app/common/synthese/components/modeles-selection/components/modeles-synthese-list/synthesemodeles.tpl.html',
	controller: SyntheseModelesController
};
function SyntheseModelesController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	SyntheseModelesService,
	notification,
	MassiaApplicationService,
	$transitions
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	let selectedSyntheseModeleId = undefined;

	vm.loading = false;

	vm.params = $stateParams;
	vm.state = $state;
	vm.MassiaApplicationService = MassiaApplicationService;

	vm.syntheseModeles = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectSyntheseModele = selectSyntheseModele;
	vm.isSyntheseModeleSelected = isSyntheseModeleSelected;
	vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	//vm.loadSyntheseModeles = loadSyntheseModeles;
	vm.setSelected = setSelected;
	vm.deleteSyntheseModele = deleteSyntheseModele;

	this.$onInit = () => {
		//écouter l'event lié au changement de route
		//une fois on change l'url, et on arrive sur la page "synthesemodeles.list", on désélectionne la ligne sélectionnée (s'il y en a)
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));
		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}

		vm.activite = vm.MassiaApplicationService.getApplication();
		getTypeSyntheses();
	};

	async function getTypeSyntheses() {
		try {
			vm.listeTypesSynthese = [
				{
					libelle: 'Bon de Livraison',
					id: 14
				},
				{
					libelle: 'Facturation',
					id: 15
				},
				{
					libelle: 'Article',
					id: 16
				}
			];
			//const tempList = await SyntheseModelesService.getTypeSyntheses(vm.activite);
			//vm.listeTypesSynthese = _.filter(tempList, { activite: vm.activite });
		} catch (err) {
			if (err.data) {
				notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
		}
	}

	function stateChangeSuccessFunc(event, toState) {
		/*if (toState.name === 'synthesemodeles.list') {
            selectedSyntheseModeleId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }*/
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	function setSelected(syntheseModele) {
		if (syntheseModele && syntheseModele.id) {
			selectedSyntheseModeleId = $stateParams.id;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			selectedSyntheseModeleId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return (
			$state && $state.current && $state.current.name && $state.current.name === 'synthese.model.list.detail' && $stateParams && $stateParams.id
		);
	}

	function selectSyntheseModele(syntheseModele) {
		if (syntheseModele && syntheseModele.id) {
			if (selectedSyntheseModeleId !== syntheseModele.id) {
				//vm.selectedSyntheseModeleId !== syntheseModele.id) {
				//vm.selectedSyntheseModeleId = syntheseModele.id;
				showDetail(syntheseModele.id);
			} else {
				//vm.selectedSyntheseModeleId = undefined;
				closeDetail();
			}
		}
	}

	function isSyntheseModeleSelected(syntheseModele) {
		return syntheseModele && syntheseModele.id && selectedSyntheseModeleId === syntheseModele.id;
	}

	function showDetail(syntheseModeleId) {
		//vm.slideMargin['margin-right'] = vm.slideWidth;
		selectedSyntheseModeleId = syntheseModeleId;
		$state.go('synthese.model.list.detail', { id: syntheseModeleId });
	}

	function closeDetail() {
		selectedSyntheseModeleId = undefined;
		$state.go('synthese.model.list');
	}

	async function deleteSyntheseModele(syntheseModele) {
		if (syntheseModele && syntheseModele.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('SYNTHMODS.DELETE.TITLE', { code: syntheseModele.libelle }),
				modalMsg: $translate.instant('SYNTHMODS.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await SyntheseModelesService.deleteSyntheseModeleById(syntheseModele.id);
					notification.success($translate.instant('SYNTHMODS.DELETE.SUCCESS'));

					selectedSyntheseModeleId = undefined;

					// si jamais la vue détail est affichée,
					// on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
					if (vm.params.id) {
						$state.go('synthese.model.list');
					}
					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);
					$scope.loadSyntheseModeles();
				} catch (ex) {
					console.error(ex);
					if (ex.data && ex.data.message === 'SQL00001') {
						notification.error($translate.instant('SYNTHMODS.' + ex.data.message));
					} else {
						notification.error(ex.data);
					}
				} finally {
					stopLoading();
				}
			});
		}
	}

	$scope.loadSyntheseModeles = async function (tableState) {
		try {
			startLoading();

			//quand on rafraîchit la grille, on revient sur "synthesemodeles.list", et on ferme la vue détail d'un syntheseModele
			//sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
			if ($state && $state.current && $state.current.name !== 'synthese.model.list') {
				$state.go('synthese.model.list');
			}

			if (tableState) {
				previousTableState = tableState;
			}

			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const pagination = PaginationService.getPagination(previousTableState);

			vm.syntheseModeles = [];
			const data = await SyntheseModelesService.getSyntheseModeles(filters, sorts, pagination, vm.activite);
			vm.syntheseModeles = data.items;
			setLibelleType(vm.syntheseModeles);
			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			console.error(ex);
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	};

	function setLibelleType(list) {
		for (let i = 0; i < list.length; i++) {
			const element = list[i];
			element.libelleType = element.modelSelectionTypes
				.map((x) => {
					return $translate.instant(x.libelle);
				})
				.join(',');
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
