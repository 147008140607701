export default class DAPsController {
	/* @ngInject */
	constructor($scope, $state, $stateParams, $translate, ModalService, PaginationService, DAPsService, notification, $uibModal, $transitions) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$translate = $translate;
		this.index = 0;
	}

	$onInit() {
		if (this.$stateParams.fromDapDetail) {
			this.index = 1;
		}
	}
}
