import DAP from '../dap-form/dap.model';
DapGridController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'DAPsService',
	'notification',
	'$uibModal',
	'$transitions'
];

export default function DapGridController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	DAPsService,
	notification,
	$uibModal,
	$transitions
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	const selectedDAPId = undefined;
	vm.lastSelected = undefined;

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.daps = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectDAP = selectDAP;
	vm.isDAPSelected = isDAPSelected;
	vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	vm.loadDAPs = loadDAPs;
	vm.deleteDAP = deleteDAP;
	vm.updateEtatDAP = updateEtatDAP;
	vm.setSelected = setSelected;
	vm.eraseAllFilters = eraseAllFilters;
	vm.print = print;
	vm.accuseAcceptation = accuseAcceptation;
	vm.exporter = exporter;
	vm.dupliquer = dupliquer;

	function init() {
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}

		getEtats();
	}

	init();

	async function getEtats() {
		vm.etats = await DAPsService.getEtats();
	}

	function stateChangeSuccessFunc(event, toState) {
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	function setSelected(dap) {
		if (dap && dap.id) {
			vm.selectedDAPId = $stateParams.id;
			vm.lastSelected = vm.selectedDAPId;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			vm.selectedDAPId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return $state && $state.current && $state.current.name && $state.current.name === 'daps.list.detail' && $stateParams && $stateParams.id;
	}

	function selectDAP(dap) {
		if (dap && dap.id) {
			if (vm.selectedDAPId !== dap.id) {
				vm.selectedDAPId = dap.id;
				showDetail(dap.id);
			} else {
				vm.selectedDAPId = undefined;
				closeDetail();
			}
		}
	}

	function isDAPSelected(dap) {
		return dap && dap.id && vm.selectedDAPId === dap.id;
	}

	function showDetail(dapId) {
		$state.go('daps.list.detail', { id: dapId });
	}

	function closeDetail() {
		$state.go('daps.list');
	}

	async function loadDAPs(tableState) {
		startLoading();

		if ($state && $state.current && $state.current.name !== 'daps.list') {
			$state.go('daps.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.daps = [];

		try {
			const data = await DAPsService.getDAPs(filters, sorts, pagination);

			vm.daps = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function updateEtatDAP(dap) {
		if (dap && dap.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('DAPS.UPDATE.TITLE'),
				modalMsg: $translate.instant('DAPS.UPDATE.MESSAGE', { etat: dap.isActive ? 'Activer' : 'Désactiver' }),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(
				async function () {
					startLoading();
					try {
						await DAPsService.updateEtatDAPById(dap.id, dap.isActive);
						notification.success($translate.instant('DAPS.UPDATE.SUCCESS'));

						vm.selectedDAPId = undefined;

						if (vm.params.id) {
							vm.state.go('daps.list');
						}

						previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

						loadDAPs();
					} catch (ex) {
						notification.error(ex.data);
					} finally {
						stopLoading();
					}
				},
				() => {
					vm.selectedDAPId = undefined;
					if (vm.params.id) {
						vm.state.go('daps.list');
					}
					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);
					loadDAPs();
				}
			);
		}
	}
	async function deleteDAP(dap) {
		if (dap && dap.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('DAPS.DELETE.TITLE', { code: dap.libelle }),
				modalMsg: $translate.instant('DAPS.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await DAPsService.deleteDAPById(dap.id);
					notification.success($translate.instant('DAPS.DELETE.SUCCESS'));

					vm.selectedDAPId = undefined;

					if (vm.params.id) {
						vm.state.go('daps.list');
					}

					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

					loadDAPs();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function eraseAllFilters() {
		loadDAPs();
	}

	async function print(dap) {
		const fileName = dap.numero;
		const resultat = await DAPsService.print(dap.id);

		const data = resultat.data;
		const status = resultat.status;
		let headers = resultat.headers;

		headers = headers();

		const contentType = headers['content-type'];

		const linkElement = document.createElement('a');
		try {
			const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
			const url = window.URL.createObjectURL(blob);
			linkElement.setAttribute('href', url);
			linkElement.setAttribute('download', fileName + '.pdf');

			const clickEvent = new MouseEvent('click', {
				view: window,
				bubbles: true,
				cancelable: false
			});
			linkElement.dispatchEvent(clickEvent);
		} catch (ex) {
		} finally {
		}
	}

	function accuseAcceptation(dap) {
		const _this = this;
		_this.dap = dap;
		$uibModal
			.open({
				template: '<dap-accuse-acceptation-popup modal-instance="$ctrl.uibModalInstance" dap="$ctrl.dap"></dap-accuse-acceptation-popup>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.dap = _this.dap;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xl',
				backdrop: false
			})
			.result.then(
				function (result) {
					_this.loadDAPs();
					//console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
					//console.info(result);
					//_this.$state.go('gestion.home');
				},
				function (reason) {
					//console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
					//_this.$state.go('gestion.home');
				}
			);
	}

	async function exporter(modeImpression) {
		try {
			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const fileName = 'Liste_daps';
			let resultat = undefined;
			try {
				resultat = await DAPsService.exportListeDaps(modeImpression, filters, sorts);
			} catch (ex) {
				//si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
				const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
				notification.error('DAPS.' + msgException);
				return false;
			}
			if (resultat) {
				const data = resultat.data;
				const status = resultat.status;
				let headers = resultat.headers;
				headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');
				try {
					const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
					const url = window.URL.createObjectURL(blob);
					linkElement.setAttribute('href', url);
					if (modeImpression == 0) {
						linkElement.setAttribute('download', fileName + '.pdf');
					} else {
						linkElement.setAttribute('download', fileName + '.xlsx');
					}

					const clickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false
					});
					linkElement.dispatchEvent(clickEvent);
				} catch (ex) {
				} finally {
				}
				return true;
			}
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		}
	}

	async function dupliquer(dap) {
		const modalInstance = ModalService.confirm({
			modalTitle: $translate.instant('DAPS.DUPLICATE.TITLE', { code: dap.code }),
			modalMsg: $translate.instant('DAPS.DUPLICATE.MESSAGE'),
			headerClass: 'modal-info'
		});

		modalInstance.result.then(async function () {
			startLoading();
			try {
				const duplicatedDapId = await DAPsService.duplicateDap(dap.id);
				$state.go('daps.edit', { id: duplicatedDapId });
			} catch (ex) {
				notification.error(ex.data);
			} finally {
				stopLoading();
			}
		});
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
