import Personne from './personnes.model';

export default class PersonneFormController {
	/* @ngInject */
	constructor(
		_,
		$scope,
		$timeout,
		$state,
		$stateParams,
		PersonnesCommunicationService,
		PersonnesService,
		ModalService,
		notification,
		MassiaApplicationService,
		PersonnesTypesService,
		RouterHistoryService,
		DonneeFiltreeUtilisateursService,
		$translate,
		MOPService
	) {
		this._ = _;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.PersonnesCommunicationService = PersonnesCommunicationService;
		this.PersonnesService = PersonnesService;
		this.ModalService = ModalService;
		this.notification = notification;
		this.MassiaApplicationService = MassiaApplicationService;
		this.PersonnesTypesService = PersonnesTypesService;
		this.DonneeFiltreeUtilisateursService = DonneeFiltreeUtilisateursService;
		this.$translate = $translate;
		this.RouterHistory = RouterHistoryService;
		this.MOPService = MOPService;
		this.fromDap = false;

		this.ongletOpen = {
			isEnteteOpen: true,
			isHoraireOpen: false,
			isAffectationOpen: false,
			isCaracteristiqueOpen: false,
			isSpecificiteOpen: false
		};
		this.ongletOpenVal = {
			isEnteteOpen: 'entete',
			isCaracteristiqueOpen: 'caracteristiques',
			isHoraireOpen: 'horaire',
			isAffectationOpen: 'affectation',
			isSpecificiteOpen: 'specificite'
		};
	}

	async $onInit() {
		this.MOPService.setMop([{ title: 'Gestion du personnel', filename: 'GestionDuPersonnel.pdf', application: 'massia' }]);
		this.personne = {};
		this.isEditMode = false;
		this.types = this.types || [];
		this.sites = this.sites || [];
		this.signatures = this.signatures || [];
		this.affectations = this.affectations || [];
		this.loading = false;
		this.hidePassword = true;
		this.hidePasswordConfirm = true;
		this.isQualib = false;
		this.form = {
			caracteristiques: {}
		};
		/*this.textMaskConfigTel = {
            showMask:false,
            mask: [0, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
        }*/
		this.initForm();
		this.setPersonne(this.$stateParams.id);
		this.unregisterReinit = this.PersonnesCommunicationService.registerReinit(() => this.initForm());
		this.getTypes();
		try {
			await this.getNiveauVisibilite();
		} catch (err) {
			console.error(err);
		}

		console.log(this.$stateParams);
		if (this.$stateParams.fromDap) {
			this.fromDap = true;
			const p = this.$stateParams.producteur;
			this.personne.nom = p.nom;
			this.personne.prenom = p.prenom;
			this.personne.telMobile = p.tel;
			this.personne.mail = p.mail;
			this.personne.idSite = this.$stateParams.siteId;
			this.personne.isContact = true;
			this.personne.raisonSociale = p.libelle;
			this.personne.ville = p.ville;
			this.personne.codePostal = p.codePostal;
			this.personne.adresse = p.adresse;
			this.personne.siret = p.siret;
		}
	}

	async getNiveauVisibilite() {
		if (this.personne.typeId) {
			this.personne.niveauVisibilite = await this.PersonnesTypesService.GetNiveauVisibilite(this.personne.typeId);
		}
	}

	$onDestroy() {
		this.$timeout.cancel(this.updatePersonneTimeout);
		this.unregisterReinit();
		this.MOPService.resetMop();
	}

	initForm() {
		if (this.$stateParams && this.$stateParams.id) {
			this.isEditMode = true;
		} else {
			this.isEditMode = false;
		}
		this.genre = this.$stateParams.genre;
	}

	async getTypes() {
		this.startLoading();
		try {
			this.types = await this.PersonnesService.getPersonneTypes();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getSocietes() {
		this.startLoading();
		try {
			this.societes = await this.PersonnesService.getSocietes(this.personne, this.$stateParams.genre);
			this.loadSites();
			this.getSites();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getSites() {
		this.startLoading();
		try {
			this.sitesCommerciaux = await this.PersonnesService.getSites(this.personne);
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getSignatures() {
		this.startLoading();
		try {
			this.signatures = await this.PersonnesService.getSignatures();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async loadSites() {
		this.sites = await this.PersonnesService.getSitesBySociete(this.personne);
	}

	async loadSiteDeparts() {
		this.siteDeparts = await this.PersonnesService.getSiteDeparts();
	}

	/*async createType() {
        const modalInstance = this.ModalService.confirm({
            modalTitle: 'PERSONNES.CONFIRM_CREATE_TYPE.TITLE',
            modalMsg: 'PERSONNES.CONFIRM_CREATE_TYPE.MESSAGE',
            headerClass: 'modal-warning'
        });
        modalInstance.result.then(() => this.$state.go(this.getUrlNewType()));
    }*/

	async setPersonne(personneId) {
		if (personneId) {
			this.startLoading();
			try {
				this.isEditMode = true;
				const newPersonne = await this.PersonnesService.getPersonneById(personneId);
				newPersonne.caracteristiques = await this.PersonnesService.getPersonneValeursCaracteristiquesAssociationsById(personneId);
				this.personne = new Personne(newPersonne);
				this.horaireDefautDebut = this.personne.horaireDefautDebut;
				this.horaireDefautFin = this.personne.horaireDefautFin;
				// console.log(this.personne);
				this.getSocietes();
				this.getSites();
				this.getSignatures();
				await this.getAffectations();

				const profils = await this.PersonnesService.getAllProfils(this.MassiaApplicationService.getApplication());
				this.hasProfil = profils && profils.items && profils.items.length > 0;
				this.profils = profils.items;

				this.preselectProfils();
			} catch (ex) {
				if (ex.status === 404) {
					this.$state.go(this.getUrlListe(), {}, { reload: true });
				}
				this.notification.error(ex.data);
			} finally {
				this.stopLoading();
			}
		} else {
			this.isEditMode = false;
			this.personne = new Personne();
			this.personne.isPersonne = this.genre == 'personne';
			this.personne.isContact = this.genre == 'contact';
			this.personne.isUtilisateur = this.genre == 'utilisateur';
			this.personne.isChauffeur = this.genre == 'chauffeur';
			this.getSocietes();
			this.getSites();
			this.getSignatures();
			await this.getAffectations();

			const profils = await this.PersonnesService.getAllProfils(this.MassiaApplicationService.getApplication());
			this.hasProfil = profils && profils.items && profils.items.length > 0;
			this.profils = profils.items;
		}

		this.ongletOpen = {
			isEnteteOpen: true,
			isHoraireOpen: false,
			isAffectationOpen: false,
			isCaracteristiqueOpen: false
		};

		this.isQualib = this.personne.isUtilisateur && this.MassiaApplicationService.getApplication() === 'qualib';
		this.loadSiteDeparts();
		await this.getDonneesFiltreesUtilisateur();
		this.$timeout.cancel(this.updatePersonneTimeout);
		this.updatePersonneTimeout = this.$timeout(() => this.stopLoading());
	}

	async getAffectations() {
		if (this.affectationSiteToAdd && this.affectationSiteToAdd.id) {
			this.affectations = await this.PersonnesService.getAffectations(this.affectationSiteToAdd.id);
		} else {
			this.affectations = [];
		}
	}

	deleteAffectation(affectation) {
		const index = this.personne.affectations.indexOf(affectation);
		this.personne.affectations.splice(index, 1);
	}

	addAffectation() {
		if (this.dureeToAdd != null && this.affectationToAdd.id != null) {
			const affectationToAdd = {};
			affectationToAdd.idSite = this.affectationSiteToAdd.id;
			affectationToAdd.site = this.affectationSiteToAdd.libelle;
			affectationToAdd.idAffectation = this.affectationToAdd.id;
			affectationToAdd.libelle = this.affectationToAdd.libelle;
			affectationToAdd.code = this.affectationToAdd.code;
			affectationToAdd.duree = this.dureeToAdd;
			affectationToAdd.isProduction = this.affectationToAdd.isProduction;
			this.personne.affectations.push(affectationToAdd);
			this.dureeToAdd = null;
			this.affectationToAdd = '';
		} else {
			this.notification.error('PERSONNES.AFFECTATIONS.AFFECTATION_ET_DUREE_OBLIGATOIRES');
		}
	}

	initHoraireDebutDefaut(time) {
		this.personne.horaireDefautDebut = time;
	}

	initHoraireFinDefaut(time) {
		this.personne.horaireDefautFin = time;
	}

	initHoraireDebutToAdd(time) {
		this.horaireDebutFormatToAdd = time;
	}

	initHoraireFinToAdd(time) {
		this.horaireFinFormatToAdd = time;
	}

	async deleteHoraire(horaire) {
		const index = this.personne.horaires.indexOf(horaire);
		this.personne.horaires.splice(index, 1);
	}

	async addHoraire() {
		if (this.horaireDateDebutToAdd && this.horaireDateFinToAdd && this.horaireDebutFormatToAdd && this.horaireFinFormatToAdd) {
			const scheduleToAdd = {};
			scheduleToAdd.dateDebut = this.horaireDateDebutToAdd;
			scheduleToAdd.dateFin = this.horaireDateFinToAdd;
			scheduleToAdd.heureDebut = this.horaireDebutFormatToAdd;
			scheduleToAdd.heureFin = this.horaireFinFormatToAdd;
			this.personne.horaires.push(scheduleToAdd);
			this.horaireDebutToAdd = '';
			this.horaireFinToAdd = '';
			this.horaireDateDebutToAdd = '';
			this.horaireDateFinToAdd = '';
		}
	}

	isCodeVarchar(code) {
		return /^[a-zA-Z0-9_]+$/.test(code);
	}

	async checkCodeUnicity(code) {
		if (!code) {
			this.personne.codeExists = null;
			return;
		}
		this.startCodeLoading();
		try {
			this.personne.codeValid = this.isCodeVarchar(code);
			if (this.personne.codeValid) {
				this.personne.codeExists = await this.PersonnesService.codeExists(code);
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopCodeLoading();
		}
	}

	async checkLoginUnicity(login) {
		if (!login) {
			this.personne.loginExists = null;
			return;
		}
		this.startLoginLoading();
		try {
			if (login.match(/^[a-zA-Z0-9_|]*$/)) {
				this.personne.loginExists = await this.PersonnesService.loginExists(this.personne.id, login);
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoginLoading();
		}
	}

	reinit() {
		this.setPersonne(this.$stateParams.id);
		this.PersonnesCommunicationService.raiseReinit();
	}

	checkValidity() {
		if (this.fromDap) {
			return true;
		}
		let validity = true;
		if (!this.personne.isValid()) {
			validity = false;
			this.$scope.$broadcast('personneValidations');
		}
		// if (!this.personne.caracteristiques.isValid()) {
		//     if (firstScroll) {
		//         this.scrollToOnglet('caracteristiques');
		//         firstScroll = false;
		//     }

		//     this.ongletOpen.isCaracteristiqueOpen = true;
		//     validity = false;
		//     this.$scope.$broadcast('personneCaracteristiquesValidations');
		// }
		return validity;
	}

	async getDonneesFiltreesUtilisateur() {
		// this.startLoading();
		const data = {
			id: this.personne.id
		};
		if (data.id) {
			try {
				this.donneeFiltreeUtilisateur = await this.DonneeFiltreeUtilisateursService.getDonneeFiltreeUtilisateur(
					data.id,
					this.MassiaApplicationService.getApplication()
				);
			} catch (ex) {
				console.log(ex);
				this.notification.error(ex.data);
			}
		}
		// this.stopLoading();
	}

	async checkMetiersAndFamille() {
		let warning = '';
		if (this.personne.isUtilisateur) {
			if (this.donneeFiltreeUtilisateur?.idMetiers?.length === 0 || this.donneeFiltreeUtilisateur?.idFamilles?.length === 0) {
				warning = this.$translate.instant('FILTRES.WARNINGS_FAMILLE_METIER');
			}

			if (warning.length > 0) {
				const modalInstance = this.ModalService.confirm({
					modalTitle: this.$translate.instant('FILTRES.WARNING'),
					modalMsg: this.$translate.instant(warning),
					headerClass: 'modal-danger'
				});
				return modalInstance.result.then(async function () {
					// startLoading();
					try {
						return true;
					} catch (ex) {
						return false;
						//                            notification.error(ex.data);
					}
				});
			}
		}
		return true;
	}
	// boutons de validation
	async sauvegarder() {
		let tmp;
		if (
			(await this.checkMetiersAndFamille()) &&
			(this.personne.isChauffeur || this.personne.isUtilisateur || this.personne.isPersonne || this.personne.isContact)
		) {
			this.setSelectedProfils();
			if (this.checkValidity()) {
				if (!this.personne.codeValid && !this.isEditMode) {
					this.notification.error('PERSONNES.CODE_SPECIALCHARS_NOK');
					return;
				}
				this.startLoading();
				try {
					if (this.personne.id) {
						this.updatePersonne();
						this.notification.success('PERSONNES.UPDATED');
						return this.personne.id;
					}
					tmp = await this.createPersonne();
					return tmp;
				} catch (ex) {
					console.log('🚀 ~ PersonneFormController ~ sauvegarder ~ ex:', ex);
					this.notification.error(ex.data);
					return false;
				} finally {
					this.stopLoading();
					if (tmp) {
						this.notification.success('PERSONNES.CREATED');
					}
				}
			}
		} else {
			this.notification.error('PERSONNES.GENRE_OBLIGATOIRE');
			return false;
		}
	}

	async valider() {
		const returnedId = await this.sauvegarder();
		if (returnedId) {
			this.$state.go(this.getUrlEdit(), { id: returnedId, genre: this.genre });
		}
	}
	async validerEtFermer() {
		const returnedId = await this.sauvegarder();
		console.log('🚀 ~ PersonneFormController ~ validerEtFermer ~ returnedId:', returnedId);
		if (this.fromDap) {
			console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAH');
			this.RouterHistory.back({ producteurId: returnedId });
			return;
		}

		this.annuler();
	}

	annuler() {
		this.backToPreviousState();
	}

	backToPreviousState() {
		if (!this.RouterHistory.back()) {
			this.$state.go(this.getUrlListe(), { genre: this.$stateParams.genre });
		}
	}

	getUrlListe() {
		switch (this.$stateParams.genre) {
			case 'personne':
				if (this.MassiaApplicationService.getApplication() == 'performance') {
					return 'personnes-perf.list';
				}
				return 'personnes.list';
			case 'contact':
				return 'contacts.list';
			case 'utilisateur':
				return 'utilisateurs.list';
			case 'chauffeur':
				return 'chauffeurs.list';
			default:
				return 'personnes.list';
		}
	}

	getUrlNewType() {
		switch (this.$stateParams.genre) {
			case 'personne':
				if (this.MassiaApplicationService.getApplication() == 'performance') {
					return 'personnes-perf.newType';
				}
				return 'personnes.newType';
			case 'contact':
				return 'contacts.newType';
			case 'utilisateur':
				return 'utilisateurs.newType';
			case 'chauffeur':
				return 'chauffeurs.newType';
			default:
				return 'personnes.newType';
		}
	}

	getUrlEdit() {
		switch (this.$stateParams.genre) {
			case 'personne':
				if (this.MassiaApplicationService.getApplication() == 'performance') {
					return 'personnes-perf.edit';
				}
				return 'personnes.edit';
			case 'contact':
				return 'contacts.edit';
			case 'utilisateur':
				return 'utilisateurs.edit';
			case 'chauffeur':
				return 'chauffeurs.edit';
			default:
				return 'personnes.edit';
		}
	}

	async createPersonne() {
		this.startLoading();
		try {
			console.log('CREATEPERSONNE');
			const returnedId = await this.PersonnesService.createPersonne(this.personne, this.MassiaApplicationService.getApplication());
			this.notification.success('PERSONNES.CREATED');
			return returnedId;
		} catch (ex) {
			console.log('🚀 ~ PersonneFormController ~ createPersonne ~ ex:', ex);
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async updatePersonne() {
		this.startLoading();
		try {
			const returnedId = await this.PersonnesService.updatePersonne(this.personne, this.MassiaApplicationService.getApplication());
			this.notification.success('PERSONNES.UPDATED');
			return returnedId;
		} catch (ex) {
			this.notification.error(ex.data);
			this.reinit();
		} finally {
			this.stopLoading();
		}
	}

	onUpdateCaracteristiques(caracteristiques) {
		this.personne.caracteristiques = caracteristiques;
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	stopCodeLoading() {
		this.codeLoading = false;
	}

	startCodeLoading() {
		this.codeLoading = true;
	}

	startLoginLoading() {
		this.loginLoading = true;
	}

	stopLoginLoading() {
		this.loginLoading = false;
	}

	preselectProfils() {
		if (this.isEditMode && this.personne && this.personne.profilIds && this.personne.profilIds.length > 0 && this.hasProfil) {
			const self = this;

			this.personne.profilIds.forEach(function (userProfilId) {
				const profil = self._.find(self.profils, function (p) {
					return p.id === userProfilId;
				});
				if (profil) {
					profil.selected = true;
				}
			});
		}
	}

	//dans iSteven pour profils, on utilise this.selectedProfils pour garder les profils sélectionné
	setSelectedProfils() {
		this.personne.profilIds = this._.map(this.selectedProfils, 'id');
	}

	filtrer() {
		this.$state.go('donnee-filtree-utilisateurs.list', { id: this.personne.id });
	}

	async selectNewSignature() {
		this.signatures = await this.PersonnesService.getSignatures();
	}

	showPassword() {
		this.hidePassword = !this.hidePassword;
	}
	showPasswordConfirm() {
		this.hidePasswordConfirm = !this.hidePasswordConfirm;
	}
}
