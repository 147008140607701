import { StateService } from '@uirouter/angularjs';
import { RegistresService } from '../../../registres/services/registres.service';

export default class RegistresController {
	$scope: ng.IScope;
	$state: StateService;
	$uibModal: ng.ui.bootstrap.IModalService;
	$translate: any;
	moment: any;
	ModalService: any;
	notification: any;
	registresService: RegistresService;
	PaginationService: any;
	globalizationManagementService: any;
	loading: string | boolean;
	dateFormat: any;
	slideWidth: string;
	slideMargin: { 'margin-right': string; 'transition-duration': string; 'transition-animation': string; height: string };
	unregister: any;
	previousTableState: any;

	registresType = {
		ECO_ORGANISME_REGISTRE_ENRICHI: 'Eco organisme - Registre enrichi',
		DECLARATION_ECOMINERO: 'Déclaration écominéro',
		// ECO_ORGANISME_DECLARATION_MENSUELLE: 'Eco organisme - Déclaration mensuelle',
		RNDTS_TEXS_ENTRANT: 'RNDTS - TEXS Entrant',
		RNDTS_TEXS_SORTANT: 'RNDTS - TEXS Sortant'
	};
	dateDebut: string;
	dateFin: string;
	selectedRegistreType: string;
	societes: unknown;
	idSociete: number;
	sites: any;
	idSite: number;
	ecoOrganismesList: any;
	idEcoOrganisme: any;
	declarationRndts: any;
	getDeclarationRndts: any;
	/* @ngInject */
	constructor(
		$scope: ng.IScope,
		$state: StateService,
		$uibModal: ng.ui.bootstrap.IModalService,
		registresService: RegistresService,
		globalizationManagementService: any,
		PaginationService: any,
		notification: any,
		$translate: any,
		moment: any,
		ModalService: any
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$translate = $translate;
		this.$uibModal = $uibModal;
		this.notification = notification;
		this.moment = moment;
		this.ModalService = ModalService;
		this.registresService = registresService;
		this.PaginationService = PaginationService;
		this.loading = false;
		this.unregister = [];
		this.slideWidth = '500px';
		this.slideMargin = {
			'margin-right': '0px',
			'transition-duration': '0.5s',
			'transition-animation': 'margin-right',
			height: '100%'
		};

		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
		this.declarationRndts = [];
		this.societes = [];
		this.sites = [];
	}

	async $onInit() {
		await this.loadSocietes();

		await this.loadEcoOrganismes();
		this.setLastMonthDate();

		this.unregister.push(
			this.$scope.$watch(
				() => this.idSociete,
				() => {
					if (this.idSociete && this.idSociete > 0) {
						this.declarationRndts = [];
						this.sites = [];
						this.idSite = null;
						// this.idEcoOrganisme = null;
						this.loadSitesBySocieteId(this.idSociete);
					}
				},
				true
			)
		);
		this.unregister.push(
			this.$scope.$watch(
				() => this.selectedRegistreType,
				() => {
					this.idSociete = null;
					this.sites = [];
					this.idSite = null;
					this.idEcoOrganisme = null;
					this.declarationRndts = [];
				},
				true
			)
		);
		this.unregister.push(
			this.$scope.$watch(
				() => this.idSite,
				() => {
					if (this.idSite && this.idSite > 0) {
						this.declarationRndts = [];
					}
				},
				true
			)
		);
	}

	$onDestroy(): void {
		for (const un of this.unregister) {
			un();
		}
	}

	private setLastMonthDate() {
		const maDate = new Date();
		const dates = this.getPreviousMonthDates(maDate);
		this.dateDebut = dates.start;
		this.dateFin = dates.end;
	}

	async loadSocietes() {
		this.startLoading();
		try {
			this.societes = await this.registresService.getSocietes();
			if (this.societes.length == 1) {
				this.idSociete = this.societes[0].id;
				console.log(this.idSociete);
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}
	async loadSitesBySocieteId(idSociete: number) {
		this.startLoading();
		try {
			this.sites = await this.registresService.getSitesBySocieteId(idSociete);
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async loadEcoOrganismes() {
		this.startLoading();
		try {
			this.ecoOrganismesList = await this.registresService.getAllEcoOrganismes();
		} catch (ex) {
			this.notification.error(ex);
		} finally {
			this.stopLoading();
		}
	}

	async exportRegistreEcoOrganismes() {
		if (this.idSociete && this.dateDebut && this.dateFin) {
			this.startLoading();
			const form: any = {
				idSociete: this.idSociete,
				idSite: this.idSite || -1,
				idEcoOrganisme: this.idEcoOrganisme || -1,
				dateDebut: this.dateDebut,
				dateFin: this.dateFin
			};
			try {
				const fileData: any =
					this.selectedRegistreType === 'ECO_ORGANISME_REGISTRE_ENRICHI'
						? await this.registresService.exportRegistreEcoOrganismes(form)
						: await this.registresService.exportRegistreDeclarationEcominero(form);
				if (fileData) {
					const fileName =
						this.selectedRegistreType === 'ECO_ORGANISME_REGISTRE_ENRICHI'
							? 'registre-eco-organismes.xlsx'
							: 'registre-declaration-ecominero.xlsx';
					const blob = new Blob([fileData.data], { type: fileData.headers()['content-type'] + ';charset=UTF-8' });
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = fileName;

					link.dispatchEvent(
						new MouseEvent('click', {
							view: window,
							bubbles: true,
							cancelable: false
						})
					);
				}
			} catch (ex) {
				this.notification.error(this.$translate.instant('REGISTRES.ERROR.NO_BL'));
			} finally {
				this.stopLoading();
			}
		} else {
			this.notification.error(this.$translate.instant('REGISTRES.ERROR.MISSING_FIELDS'));
		}
	}

	// Arrow fx for bind
	loadDeclarationRndts = async () => {
		this.startLoading();

		const filters = this.PaginationService.getFilters(this.previousTableState);
		const sorts = this.PaginationService.getSorts(this.previousTableState);

		this.declarationRndts = [];
		this.getDeclarationRndts = {};

		try {
			const data: any = await this.registresService.getDeclarationRndts(
				filters,
				sorts,
				null,
				this.idSociete,
				this.idSite,
				this.dateDebut,
				this.dateFin,
				this.getTypeEntreeSortie()
			);
			this.getDeclarationRndts = data;
			this.declarationRndts = data.declarationRndts.items;

			if (this.previousTableState) {
				this.PaginationService.setTableState(data.skip, data.take, data.total, this.previousTableState);
			}
			console.log('Declaration Count', this.declarationRndts);
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	};

	getTypeEntreeSortie() {
		let typeEntreeSortie = 0;
		if (this.selectedRegistreType == 'RNDTS_TEXS_SORTANT') typeEntreeSortie = 1;
		return typeEntreeSortie;
	}

	redirectToDAP(id: any) {
		const url = this.$state.href('daps.edit', { id: id });
		window.open(url, '_dap');
	}

	redirectToTicket(id: any) {
		const url = this.$state.href('tickets.detail', { id: id });
		window.open(url, '_tickets');
	}

	async printDeclarationRndts() {
		if (this.dateDebut && this.dateFin && (this.idSite || this.idSociete)) {
			this.startLoading();
			const fileName = Date.now();
			const resultat = await this.registresService.printDeclarationRndts(
				this.idSociete,
				this.idSite,
				this.dateDebut,
				this.dateFin,
				this.getTypeEntreeSortie(),
				fileName
			);

			const data: any = resultat.data;
			let headers: any = resultat.headers;

			headers = headers();

			const contentType = headers['content-type'];

			const linkElement = document.createElement('a');
			try {
				const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
				const url = window.URL.createObjectURL(blob);
				linkElement.setAttribute('href', url);
				linkElement.setAttribute('download', fileName + '.csv');

				const clickEvent = new MouseEvent('click', {
					view: window,
					bubbles: true,
					cancelable: false
				});
				linkElement.dispatchEvent(clickEvent);
			} catch (ex) {
				console.error(ex);
			} finally {
				this.notification.info(this.$translate.instant('REGISTRES.EXPORT_MESSAGE'));
				this.stopLoading();
			}
		}
	}

	async teletransmetre() {
		this.notification.info(this.$translate.instant('REGISTRES.API_CALLBACK.SEND_API'));
		const apiResult: any = await this.registresService.postApi(
			this.idSociete,
			this.idSite,
			this.dateDebut,
			this.dateFin,
			this.getTypeEntreeSortie()
		);
		this.registresService.setApiResult(apiResult);
		let shouldOpen = false;
		this.loadDeclarationRndts();
		for (const k in apiResult) {
			shouldOpen = apiResult[k].httpCode > 200;
		}

		if (shouldOpen) {
			this.$uibModal.open({
				template: `<rndts-api-callback modal-instance="$ctrl.uibModalInstance"></rndts-api-callback>`,
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance: any) {
						this.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			});
		}
	}

	openTicket(ticket: any) {
		this.$uibModal.open({
			template: '<ticket-detail modal-instance="$ctrl.uibModalInstance" ticket-from-pre-fa="$ctrl.ticketFromPreFa"></ticket-detail>',
			controller: [
				'$uibModalInstance',
				function ($uibModalInstance: any) {
					this.uibModalInstance = $uibModalInstance;
					this.ticketFromPreFa = ticket;
				}
			],
			controllerAs: '$ctrl',
			size: 'xxl'
		});
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	isForRndts() {
		return (
			this.dateFin &&
			this.dateDebut &&
			this.idSociete &&
			(this.selectedRegistreType === 'RNDTS_TEXS_ENTRANT' || this.selectedRegistreType === 'RNDTS_TEXS_SORTANT')
		);
	}

	isForEcoOrganisme() {
		return (
			this.dateFin &&
			this.dateDebut &&
			(this.selectedRegistreType === 'ECO_ORGANISME_REGISTRE_ENRICHI' || this.selectedRegistreType === 'DECLARATION_ECOMINERO')
		);
	}

	private getPreviousMonthDates(date: Date) {
		const y = date.getFullYear();
		const m = date.getMonth() - 1;
		const firstDay = new Date(y, m, 1);
		const lastDay = new Date(y, m + 1, 0);
		const formattedFirstDay = this.moment(firstDay).format(this.dateFormat);
		const formattedLastDay = this.moment(lastDay).format(this.dateFormat);
		return { start: formattedFirstDay, end: formattedLastDay };
	}
}
