import { cloneDeep } from 'lodash';

export default class ProduitFormSiteLiesController {
	static $inject = ['$scope', 'ProduitsService', 'notification', '_'];

	constructor($scope, ProduitsService, notification, _) {
		this.$scope = $scope;
		this.ProduitsService = ProduitsService;
		this.notification = notification;
		this._ = _;
	}

	$onInit() {
		this.cloned = [];
		this.selected = [];
		this.refreshFiltreSitesCommerciaux();
	}

	$onDestroy() {
		//this.unregister();
	}

	getSelect(index, prop) {
		if (!this.selected[index]) {
			this.selected[index] = {};
		}
	}

	clone(table) {
		if (!this.sites) {
		}
		return cloneDeep(table);
	}

	getLinked(producteur) {
		let model = this.produit.siteLinked.find((x) => x.producteur.id === producteur.id);
		if (model) {
			return model;
		}
		model = {
			producteur: producteur,
			entree: [],
			sortie: []
		};
		this.produit.siteLinked.push(model);
		console.log(this.produit);
		return model;
	}

	/* async loadSiteLies() {
        this.startLoading();

        try {
            if (this.produit) {
                this.siteLies = await this.ProduitsService.getProduitSiteLies(this.produit);
                await this.initiateSiteCommerciale();
            }
        } catch (ex) {
            this.notification.error(ex);
        } finally {
            this.stopLoading();
        }
    } */

	/* async initiateSiteCommerciale() {
        const sitesCommerciaux = await this.refreshFiltreSitesCommerciaux();
        this.inputFiltreSiteEntrees = angular.copy(sitesCommerciaux);
        this.inputFiltreSiteSorties = angular.copy(sitesCommerciaux);
    } */

	/* resetSiteCommerciale() {
        for (let index = 0; index < this.inputFiltreSiteEntrees.length; index++) {
            const element = this.inputFiltreSiteEntrees[index];
            element.selected = false;
        }
        for (let index = 0; index < this.inputFiltreSiteSorties.length; index++) {
            const element = this.inputFiltreSiteSorties[index];
            element.selected = false;
        }
    } */

	/* async preselectFiltreSitesCommerciaux(siteLie) {
        this.resetSiteCommerciale();
        for (let index = 0; index < siteLie.siteEntreeId.length; index++) {
            const element = siteLie.siteEntreeId[index];
            const filtre = this._.find(this.inputFiltreSiteEntrees, { id: element });
            if (filtre) {
                filtre.selected = true;
            }
        }

        for (let index = 0; index < siteLie.siteSortieId.length; index++) {
            const element = siteLie.siteSortieId[index];
            const filtre = this._.find(this.inputFiltreSiteSorties, { id: element });
            if (filtre) {
                filtre.selected = true;
            }
        }
    } */

	/* async saveSelectedSiteEntrees(siteLie) {
        siteLie.siteEntreeId = [];
        for (let i = 0; i < this.outputFiltreSiteEntrees.length; i++) {
            const siteCom = this.outputFiltreSiteEntrees[i];
            siteLie.siteEntreeId.push(siteCom.id);
        }
    }

    async saveSelectedSiteSorties(siteLie) {
        siteLie.siteSortieId = [];
        for (let i = 0; i < this.outputFiltreSiteSorties.length; i++) {
            const siteCom = this.outputFiltreSiteSorties[i];
            siteLie.siteSortieId.push(siteCom.id);
        }
    } */

	async refreshFiltreSitesCommerciaux() {
		let data;
		try {
			data = await this.ProduitsService.getSitesCommerciauxSansSociete();
			console.log(data);
			this.sites = data;
		} catch (ex) {
			data = [];
		}
		return data;
	}

	activeEditMode(siteLie) {
		siteLie.isEditMode = true;
		this.isEditMode = true;
		this.preselectFiltreSitesCommerciaux(siteLie);
	}

	async desactiveEditMode(siteLie) {
		siteLie.isEditMode = false;
		this.isEditMode = false;
		await this.loadSiteLies();
	}

	async updateSiteLie(siteLie) {
		/*  siteLie.isEditMode = false;
        this.isEditMode = false;
        await this.ProduitsService.updateProduitSiteLies(this.produit, siteLie); */
		await this.loadSiteLies();
		// zoneStockage.zoneStockageProduits = [];
		// for (var index = 0; index < this.produit.length; index++) {
		//     let currentProduit = this.produit[index];
		//     let zoneStockageProduit = {
		//         idProduit: currentProduit.idProduit,
		//         nomProduit: currentProduit.nomProduit,
		//         idProducteur: currentProduit.idProducteur,
		//         nomProducteur: currentProduit.nomProducteur,
		//         libelle: currentProduit.nomProduit + ' / ' + currentProduit.nomProducteur
		//     };
		//     zoneStockage.zoneStockageProduits.push(zoneStockageProduit);
		// }
		// zoneStockage.produitProducteurs = zoneStockage.zoneStockageProduits.map(x => x.libelle).join(", ");
		// zoneStockage.isUpdate = true;

		// this.getElementsSelectionnables();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}
