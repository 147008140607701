(function (angular, undefined) {
	angular.module('blocks.smart.table').directive('stFilterDate', [
		'$timeout',
		'moment',
		'globalizationManagementService',
		function ($timeout, moment, globalizationManagementService) {
			return {
				require: '^stTable',
				restrict: 'A',
				transclude: true,
				templateUrl: 'blocks/smart-table/filter-date.html',
				scope: {
					predicate: '=stFilterDate',
					predicateName: '@stFilterDate'
				},
				link: function (scope, element, attr, ctrl) {
					let timer;
					const unregisterDestroyEvent = scope.$on('$destroy', dispose);
					const unregisterFilterSettedEvent = scope.$on('event:stPredicateObjectSetted', predicateObjectUpdated);
					let slideboxEl;
					let pinnedTableEl;
					let first = true;
					scope.filtering = false;
					scope.currentFilter = '';
					element.addClass('st-filter');

					scope.datepickerConfig = { outputMode: 'moment' };

					// pour récupérer l'info quand on modifie le tableState
					scope.$watch(
						function () {
							return ctrl.tableState().search;
						},
						function (newValue, oldValue) {
							if (newValue.predicateObject && newValue.predicateObject[scope.predicateName]) {
								const dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
								scope.predicate = {
									dateMin: moment(newValue.predicateObject[scope.predicateName]).format(dateFormat),
									dateMax: moment(newValue.predicateObject[scope.predicateName]).format(dateFormat)
								};
								if (scope.predicate && first) {
									first = false;
									scope.predicate = {
										dateMin: moment(newValue.predicateObject[scope.predicateName].dateMin).format(dateFormat),
										dateMax: moment(newValue.predicateObject[scope.predicateName].dateMax).format(dateFormat)
									};

									scope.setFilterInfos();
								}
							}
						}
					);

					// On utilise le filtre custom
					ctrl.setFilterFunction('acTableFilter');

					scope.eraseFilter = function () {
						// si une valeur est entrée
						if (scope.predicate) {
							// on l'efface
							scope.predicate.dateMin = undefined;
							scope.predicate.dateMax = undefined;
						}
						scope.currentFilter = '';
						// on lance ensuite la recherche à vide (pour rappatrier tout)
						ctrl.search(undefined, scope.predicateName);
						scope.filtering = false;
						scope.filter.isOpen = false;
					};

					scope.filter = function () {
						if (isFilterContentValid()) {
							scope.setFilterInfos();
							scope.applyFilter();
							scope.filter.isOpen = false;
						}
					};

					scope.setFilterInfos = function () {
						scope.currentFilter = scope.predicate ? angular.copy(scope.predicate) : '';
						scope.filtering = isFilterContentValid();
					};

					scope.applyFilter = function () {
						if (isFilterContentValid()) {
							ctrl.search(
								{
									dateMin: getValidDate(scope.predicate.dateMin),
									dateMax: getValidDate(scope.predicate.dateMax)
								},
								scope.predicateName
							);
						}
					};

					// Dans le cas d'une table pinned, on ferme le dropdown au scroll
					const slidebox = globalHelpers.findAncestorByClassName(element[0], 'slidebox');
					const pinnedTable = globalHelpers.findAncestorByClassName(element[0], 'st-table-pinned');

					if (slidebox) {
						slideboxEl = angular.element(slidebox);
						slideboxEl.bind('scroll', onScroll);
					}

					if (pinnedTable) {
						pinnedTableEl = angular.element(pinnedTable);
						pinnedTableEl.bind('scroll', onScroll);
					}

					function onScroll(e) {
						timer = $timeout(function () {
							scope.filter.isOpen = false;
						});
					}

					function predicateObjectUpdated(evt, predicateObject) {
						if (angular.isObject(predicateObject) && angular.isObject(predicateObject[scope.predicateName])) {
							const dateMin = new Date(predicateObject[scope.predicateName].dateMin);
							const dateMax = new Date(predicateObject[scope.predicateName].dateMax);

							scope.predicate = {
								dateMin: getValidDate(dateMin),
								dateMax: getValidDate(dateMax)
							};
						} else {
							scope.predicate = {};
						}

						scope.setFilterInfos();
					}

					function isValidDate(date) {
						const dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;

						let typedDate = date;
						if (!angular.isDate(typedDate)) {
							typedDate = moment.utc(date, dateFormat).toDate();
							if (!angular.isDate(typedDate)) {
								typedDate = new Date(date);
							}
						}

						return angular.isDate(new Date(typedDate)) && !isNaN(typedDate.getTime());
					}

					function getValidDate(date) {
						if (date != undefined) {
							const dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;

							let typedDate = date;
							if (!angular.isDate(typedDate)) {
								typedDate = moment.utc(date, dateFormat).toDate();
								if (!angular.isDate(typedDate)) {
									typedDate = new Date(date);
								}
							}

							return typedDate;
						}
						return undefined;
					}

					function isFilterContentValid() {
						return (
							scope.predicate &&
							!(scope.predicate.dateMin === undefined && scope.predicate.dateMax === undefined) &&
							(scope.predicate.dateMin === undefined || isValidDate(scope.predicate.dateMin)) &&
							(scope.predicate.dateMax === undefined || isValidDate(scope.predicate.dateMax))
						);
					}

					function dispose() {
						unregisterFilterSettedEvent();
						unregisterDestroyEvent();
						element.removeClass('st-filter');

						if (timer) {
							$timeout.cancel(timer);
						}
						if (slideboxEl !== undefined) {
							slideboxEl.unbind('scroll', onScroll);
						}
						if (pinnedTableEl !== undefined) {
							pinnedTableEl.unbind('scroll', onScroll);
						}
					}
				}
			};
		}
	]);
})(angular);
