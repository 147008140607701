import gestionStocksAjoutProduit from '../gestion-stocks-ajout-produit';
import GestionStocksFiltres from './gestion.stocks.filtres.model';

GestionStocksController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'StocksService',
    'StocksCommunicationService',
    'notification',
    '_',
    'moment',
    '$uibModal',
    'MOPService'
];

export default function GestionStocksController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    StocksService,
    StocksCommunicationService,
    notification,
    _,
    moment,
    $uibModal,
    MOPService
) {
    const vm = this;
    const watchers = [];

    vm.ongletOpen = {
        isFiltreOpen: true,
        isGestionStocksOpen: false
    };

    vm.loadGestionStocks = loadGestionStocks;
    vm.confirmer = confirmer;
    vm.getSites = getSites;
    vm.getEltsNiv1 = getEltsNiv1;
    vm.getEltsNiv2 = getEltsNiv2;
    vm.updateTransfert = updateTransfert;
    vm.updateProdStockee = updateProdStockee;
    vm.updateProdRetraitee = updateProdRetraitee;
    vm.print = print;
    vm.ajoutProduit = ajoutProduit;
    vm.changeSite = changeSite;

    vm.isActive = false;
    vm.MOPService = MOPService;

    async function init() {
        vm.MOPService.setMop([{ title: 'STOCKS.BREADCRUMBS.GESTION_STOCKS', filename: 'GestiondesStocks.pdf', application: 'gestion' }]);
        await getSites();
        getEltsNiv1();
        vm.gestionStocksFiltres = new GestionStocksFiltres();
        if (vm.sites.length == 1) {
            vm.gestionStocksFiltres.idSite = vm.sites[0].id;
        }
    }

    init();

    vm.$onDestroy = () => {
        vm.MOPService.resetMop();
    };

    async function loadGestionStocks() {
        if (vm.gestionStocksFiltres.isValid()) {
            const resultatDateValidation = await checkDateValidation();
            vm.isActive = resultatDateValidation.isDatesOk;
            const dateClotureMin = moment(resultatDateValidation.dateCloture, 'DD/MM/YYYY');
            const dateCloture = moment(resultatDateValidation.dateCloture, 'DD/MM/YYYY').subtract(1, 'days');
            const dateDebut = moment(vm.gestionStocksFiltres.dateDebut, 'DD/MM/YYYY');

            if (dateDebut > dateClotureMin) {
                const modalInstance = ModalService.confirm({
                    modalTitle: $translate.instant('STOCKS.CONFIRMATION_POPUP.TITLE'),
                    modalMsg: $translate.instant('STOCKS.CONFIRMATION_POPUP.MESSAGE', { dateCloture: dateCloture.format('DD/MM/YYYY') , dateChoisie : dateDebut.format('DD/MM/YYYY')}),
                    headerClass: 'modal-danger'
                });

                modalInstance.result.then(getGestionStocks);
            } else {
                getGestionStocks();
            }
        } else {
            $scope.$broadcast('gestionStocksFiltresValidations');
        }
    }

    async function getGestionStocks() {
        startLoading();
        vm.ongletOpen = {
            isFiltreOpen: true,
            isGestionStocksOpen: true
        };

        vm.gestionStock = {};

        try {
            vm.gestionStock = await StocksService.getGestionStocks(
                vm.gestionStocksFiltres.idSite,
                vm.gestionStocksFiltres.dateDebut,
                vm.gestionStocksFiltres.dateFin,
                vm.gestionStocksFiltres.eltNiv1,
                vm.gestionStocksFiltres.eltNiv2
            );
            initProduitsforTransfert();
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    function changeSite() {
        vm.gestionStocksFiltres.idSite = vm.siteSelected.id;
        vm.siteCommercialLibelle = vm.siteSelected.libelle;
    }

    function initProduitsforTransfert() {
        const produitsForTransfert = [];
        for (let index = 0; index < vm.gestionStock.lignes.length; index++) {
            const currentValue = vm.gestionStock.lignes[index];
            const newProduit = {
                producteurId: currentValue.idSiteProducteur,
                producteurLibelle: currentValue.siteProducteur,
                produitId: currentValue.idProduit,
                produitCode: currentValue.codeProduit,
                produitLibelle: currentValue.libelleProduit
            };
            produitsForTransfert.push(newProduit);
        }
        vm.produitsForTransfert = produitsForTransfert;
    }

    function updateTransfert(destinataire) {
        //mettre à jour la ligne destinataire
        let idZone = destinataire.idEmplacement;
        if (!idZone) {
            idZone = 0;
        }
        if (idZone < 0) {
            idZone = 0;
        }
        for (let index = 0; index < this.gestionStock.lignes.length; index++) {
            const ligne = this.gestionStock.lignes[index];
            let idZoneLigne = ligne.idEmplacement;
            if (!idZoneLigne) {
                idZoneLigne = 0;
            }
            if (idZoneLigne < 0) {
                idZoneLigne = 0;
            }
            if (ligne.idProduit == destinataire.idProduit && ligne.idSiteProducteur == destinataire.idProducteur && idZoneLigne == idZone) {
                if (ligne.transfert.valeur) {
                    ligne.transfert.valeur = ligne.transfert.valeur + destinataire.quantite;
                } else {
                    ligne.transfert.valeur = destinataire.quantite;
                }
                if (ligne.variation.valeur) {
                    ligne.variation.valeur = ligne.variation.valeur + destinataire.quantite;
                } else {
                    ligne.variation.valeur = destinataire.quantite;
                }
                if (ligne.stockFinal.valeur) {
                    ligne.stockFinal.valeur = ligne.stockFinal.valeur + destinataire.quantite;
                } else {
                    ligne.stockFinal.valeur = destinataire.quantite;
                }
                const transfDest = {
                    quantite: destinataire.quantite,
                    estSortie: false
                };
                if (!ligne.newTransferts) {
                    ligne.newTransferts = [];
                }
                ligne.newTransferts.push(transfDest);
            }
        }
    }

    function updateProdStockee(composition) {
        //mettre à jour les lignes des composants
        for (let indCompo = 0; indCompo < composition.length; indCompo++) {
            const compo = composition[indCompo];
            let idZoneCompo = compo.idEmplacement;
            if (!idZoneCompo) {
                idZoneCompo = 0;
            }
            if (idZoneCompo < 0) {
                idZoneCompo = 0;
            }
            for (let index = 0; index < this.gestionStock.lignes.length; index++) {
                const ligne = this.gestionStock.lignes[index];
                let idZoneLigne = ligne.idEmplacement;
                if (!idZoneLigne) {
                    idZoneLigne = 0;
                }
                if (idZoneLigne < 0) {
                    idZoneLigne = 0;
                }
                if (ligne.idProduit == compo.idProduit && ligne.idSiteProducteur == compo.idSiteProducteur && idZoneLigne == idZoneCompo) {
                    if (ligne.consoTheorique.valeur) {
                        ligne.consoTheorique.valeur = ligne.consoTheorique - compo.quantite;
                    } else {
                        ligne.consoTheorique.valeur = -compo.quantite;
                    }
                    if (ligne.variation.valeur) {
                        ligne.variation.valeur = ligne.variation.valeur - compo.quantite;
                    } else {
                        ligne.variation.valeur = -compo.quantite;
                    }
                    if (ligne.stockFinal.valeur) {
                        ligne.stockFinal.valeur = ligne.stockFinal.valeur - compo.quantite;
                    } else {
                        ligne.stockFinal.valeur = -compo.quantite;
                    }
                    const consoTheoCompo = {
                        quantite: compo.quantite
                    };
                    if (!ligne.newConsosTheoriques) {
                        ligne.newConsosTheoriques = [];
                    }
                    ligne.newConsosTheoriques.push(consoTheoCompo);
                }
            }
        }
    }

    function updateProdRetraitee(composition) {
        //mettre à jour les lignes des composants
        for (let indCompo = 0; indCompo < composition.length; indCompo++) {
            const compo = composition[indCompo];
            let idZoneCompo = compo.idEmplacement;
            if (!idZoneCompo) {
                idZoneCompo = 0;
            }
            if (idZoneCompo < 0) {
                idZoneCompo = 0;
            }
            for (let index = 0; index < this.gestionStock.lignes.length; index++) {
                const ligne = this.gestionStock.lignes[index];
                let idZoneLigne = ligne.idEmplacement;
                if (!idZoneLigne) {
                    idZoneLigne = 0;
                }
                if (idZoneLigne < 0) {
                    idZoneLigne = 0;
                }
                if (ligne.idProduit == compo.idProduit && ligne.idSiteProducteur == compo.idSiteProducteur && idZoneLigne == idZoneCompo) {
                    if (ligne.retraitement.valeur) {
                        ligne.retraitement.valeur = ligne.retraitement - compo.quantite;
                    } else {
                        ligne.retraitement.valeur = -compo.quantite;
                    }
                    if (ligne.variation.valeur) {
                        ligne.variation.valeur = ligne.variation.valeur - compo.quantite;
                    } else {
                        ligne.variation.valeur = -compo.quantite;
                    }
                    if (ligne.stockFinal.valeur) {
                        ligne.stockFinal.valeur = ligne.stockFinal.valeur - compo.quantite;
                    } else {
                        ligne.stockFinal.valeur = -compo.quantite;
                    }
                    const retraitement = {
                        quantite: compo.quantite
                    };
                    if (!ligne.newRetraitements) {
                        ligne.newRetraitements = [];
                    }
                    ligne.newRetraitements.push(retraitement);
                }
            }
        }
    }

    async function checkDateValidation() {
        let resultat = false;
        try {
            resultat = await StocksService.verifieDatesGestionStock(
                vm.gestionStocksFiltres.idSite,
                vm.gestionStocksFiltres.dateDebut,
                vm.gestionStocksFiltres.dateFin
            );
        } catch (ex) {
            notification.error(ex.data);
        } finally {
        }
        return resultat;
    }

    async function confirmer() {
        startLoading();

        try {
            await StocksService.setGestionStock(vm.gestionStock);
            notification.success('STOCKS.CREATED');
            loadGestionStocks();
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function getSites() {
        try {
            vm.sites = await StocksService.getSites();
        } catch (ex) {
            notification.error(ex.data);
        } finally {
        }
    }

    async function getEltsNiv1() {
        try {
            vm.eltsNiv1 = await StocksService.getElementsNiv1();
        } catch (ex) {
            notification.error(ex.data);
        } finally {
        }
    }

    async function getEltsNiv2() {
        try {
            if (vm.gestionStocksFiltres.eltNiv1) {
                vm.eltsNiv2 = await StocksService.getElementsNiv2(vm.gestionStocksFiltres.eltNiv1);
            } else {
                vm.eltsNiv2 = undefined;
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
        }
    }

    async function print() {
        startLoading();
        const fileName = Date.now();

        const resultat = await StocksService.printGestionStock(
            vm.gestionStocksFiltres.idSite,
            vm.gestionStocksFiltres.dateDebut,
            vm.gestionStocksFiltres.dateFin,
            vm.gestionStocksFiltres.eltNiv1,
            vm.gestionStocksFiltres.eltNiv2
        );

        const data = resultat.data;
        const status = resultat.status;
        let headers = resultat.headers;

        headers = headers();

        const contentType = headers['content-type'];

        const fileExtention = '.xlsx';
        const linkElement = document.createElement('a');
        try {
            const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
            const url = window.URL.createObjectURL(blob);
            linkElement.setAttribute('href', url);
            linkElement.setAttribute('download', fileName + fileExtention);

            const clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false
            });
            linkElement.dispatchEvent(clickEvent);
        } catch (ex) {
        } finally {
            //this.stopLoading();
        }
        stopLoading();
    }

    function ajoutProduit() {
        const _this = vm;
        $uibModal
            .open({
                template:
                    '<gestion-stocks-ajout-produit produits="$ctrl.source" id-site="$ctrl.idSite" site="$ctrl.site" date="$ctrl.date" modal-instance="$ctrl.uibModalInstance"></gestion-stocks-ajout-produit>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.source = _this.gestionStock.produits;
                        $ctrl.site = _this.siteCommercialLibelle;
                        $ctrl.idSite = _this.gestionStocksFiltres.idSite;
                        $ctrl.date = _this.gestionStocksFiltres.dateFin;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl',
                backdrop: false
            })
            .result.then(
                function (result) {
                    if (result) {
                        const ligneProduits = _this.gestionStock.lignes.filter((x) => x.idProduit === result.idProduit);
                        const isAddProduit = !ligneProduits.some((x) => x.idEmplacement === result.idEmplacement);

                        if (isAddProduit) {
                            const produitToDuplicate = ligneProduits.find((x) => {
                                return x.idEmplacement === -1;
                            });
                            const newLigneProduit = {
                                idSiteCommercial: produitToDuplicate.idSiteCommercial,
                                nomSiteCommercial: produitToDuplicate.nomSiteCommercial,
                                idSiteProducteur: produitToDuplicate.idSiteProducteur,
                                date: produitToDuplicate.date,
                                idProduit: produitToDuplicate.idProduit,
                                codeProduit: produitToDuplicate.codeProduit,
                                libelleProduit: produitToDuplicate.libelleProduit,
                                siteProducteur: produitToDuplicate.siteProducteur,
                                emplacement: result.emplacement,
                                idEmplacement: result.idEmplacement,
                                stockInitial: {
                                    isModifiable: false,
                                    type: 0,
                                    valeur: 0
                                },
                                productionNonStockee: {
                                    isModifiable: false,
                                    type: 0,
                                    valeur: null
                                },
                                productionTraitee: {
                                    isModifiable: produitToDuplicate.productionTraitee.isModifiable,
                                    type: 5,
                                    valeur: null
                                },
                                achat: {
                                    isModifiable: false,
                                    type: 0,
                                    valeur: null
                                },
                                cession: {
                                    isModifiable: false,
                                    type: 0,
                                    valeur: null
                                },
                                vente: {
                                    isModifiable: false,
                                    type: 0,
                                    valeur: null
                                },
                                consoTheorique: {
                                    isModifiable: false,
                                    type: 0,
                                    valeur: null
                                },
                                retraitement: {
                                    isModifiable: false,
                                    type: 0,
                                    valeur: null
                                },
                                consoAutomate: {
                                    isModifiable: false,
                                    type: 0,
                                    valeur: null
                                },
                                ecartInventaire: {
                                    isModifiable: false,
                                    type: 0,
                                    valeur: null
                                },
                                variation: {
                                    isModifiable: false,
                                    type: 0,
                                    valeur: null
                                },
                                stockFinal: {
                                    isModifiable: false,
                                    type: 0,
                                    valeur: null
                                },
                                productionStockee: {
                                    isModifiable: produitToDuplicate.productionStockee.isModifiable,
                                    type: 1,
                                    valeur: null
                                },
                                productionRetraitee: {
                                    isModifiable: produitToDuplicate.productionRetraitee.isModifiable,
                                    type: 4,
                                    valeur: null
                                },
                                transfert: {
                                    isModifiable: produitToDuplicate.transfert.isModifiable,
                                    type: 2,
                                    valeur: null
                                },
                                ajustement: {
                                    isModifiable: produitToDuplicate.ajustement.isModifiable,
                                    type: 3,
                                    valeur: null
                                },
                                composition: produitToDuplicate.composition,
                                isVisible: true
                            };
                            vm.gestionStock.lignes.push(newLigneProduit);
                        } else {
                            notification.error('STOCKS.ERROR_ADD_PRODUIT');
                        }
                    }
                },
                function (reason) {
                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                }
            );
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
