import ProduitsService from '../../../services/produits.service';

export default class ProduitFormEcoOrganismeController {
	$scope: ng.IScope;

	ProduitsService: ProduitsService;
	notification: any;
	loading: boolean;

	produit: any;
	ecoOrganismeDechetList: EcoOrganismeDechet[] = [];
	selectedEcoOrganismeDechetList: EcoOrganismeDechet[] = [];
	ecoOrganismeDechetsBySociete: { [key: string]: Dechet[] } = {};

	/* @ngInject */
	constructor($scope: ng.IScope, ProduitsService: ProduitsService, notification: any) {
		this.$scope = $scope;
		this.ProduitsService = ProduitsService;
		this.notification = notification;
	}

	async $onInit() {
		await this.loadEcoOrganismes();

		if (this.produit.ecoOrganismes && this.produit.ecoOrganismes.length > 0) {
			await this.loadSelectedEcoOrganismes();
		}
	}

	$onDestroy = () => {};

	async loadEcoOrganismes() {
		this.startLoading();
		try {
			this.ecoOrganismeDechetList = await this.ProduitsService.getAllEcoOrganismes();

			if (this.ecoOrganismeDechetList.length > 0) {
				this.ecoOrganismeDechetList.forEach((eo: EcoOrganismeDechet) =>
					this.addDechet(eo.societe, {
						id: eo.id,
						societe: eo.societe,
						societeId: eo.societe.id,
						selected: false,
						codeDechet: eo.codeDechet,
						denominationDechet: eo.denominationDechet
					})
				);
			}
		} catch (ex) {
			this.notification.error(ex);
		} finally {
			this.stopLoading();
		}
	}

	async loadSelectedEcoOrganismes() {
		this.produit.ecoOrganismes.map((eo: EcoOrganismeDechet) => {
			this.ecoOrganismeDechetsBySociete[eo.societe.libelle].map((dechet: Dechet) => {
				if (dechet.id === eo.id) {
					dechet.selected = true;
				}
			});
		});
	}

	private addDechet(societe: Societe, dechet: Dechet) {
		if (!this.ecoOrganismeDechetsBySociete[societe.libelle]) {
			this.ecoOrganismeDechetsBySociete[societe.libelle] = [];
		}
		this.ecoOrganismeDechetsBySociete[societe.libelle].push(dechet);
	}

	selectDechets() {
		this.selectedEcoOrganismeDechetList = [];

		for (const societe of Object.values(this.ecoOrganismeDechetsBySociete)) {
			for (const item of societe) {
				if (item.selected === true) {
					this.selectedEcoOrganismeDechetList.push(item);
				}
			}
		}
		this.produit.ecoOrganismes = this.selectedEcoOrganismeDechetList;
	}

	//return key of ecoOrganismeDechetsBySociete
	getSocietesList() {
		return Object.keys(this.ecoOrganismeDechetsBySociete);
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	// debug() {
	//     console.log('🚀: ProduitFormQuotaBitumeController -> debug -> ', this);
	// }
}

interface EcoOrganismeDechet {
	id: number;
	societe: Societe;
	codeDechet: string;
	denominationDechet: string;
}

interface Dechet {
	id: number;
	societe: Societe;
	societeId: number;
	codeDechet: string;
	denominationDechet: string;
	selected: boolean;
}

interface Societe {
	id: number;
	libelle: string;
	code: string;
}
