export default function Configure($stateProvider) {
	$stateProvider.state('transporteurs', {
		parent: 'common',
		url: '/transporteurs',
		views: {
			common: {
				template: '<ui-view></ui-view>'
			}
		},
		ncyBreadcrumb: {
			label: '{{ "LAYOUT.NAVIGATION.TRANSPORT" | translate }}'
		}
	});

	$stateProvider.state('transporteurs.list', {
		url: '/:genre/list',
		template: '<sites></sites>',
		rights: { domain: 'sitetransp', right: 'read', application: 'gestion' },
		ncyBreadcrumb: {
			parent: 'transporteurs',
			label: '{{ "SITES.BREADCRUMBS.SITES_TRANSPORTEURS_LIST" | translate}}'
		},
		search: 'SITES.BREADCRUMBS.SITES_TRANSPORTEURS_LIST'
	});

	$stateProvider.state('transporteurs.niveaux', {
		url: '/:genre/niveaux',
		template: '<site-config-niveaux></site-config-niveaux>',
		rights: { domain: 'nivh', right: 'read' },
		ncyBreadcrumb: {
			// on décide que la liste est le parent des autres states
			// afin d'afficher sites > niveaux
			parent: 'transporteurs.list({genre: "transporteurs"})',
			label: '{{ "SITES.BREADCRUMBS.SITE_NIVEAUX" | translate }}'
		}
	});

	$stateProvider.state('transporteurs.list.detail', {
		url: '/:genre/{id}',
		template: '<site-detail></site-detail>',
		ncyBreadcrumb: {
			parent: 'transporteurs.list({genre: "transporteurs"})',
			label: '{{ "SITES.BREADCRUMBS.SITE_DETAIL" | translate }}'
		}
	});

	$stateProvider.state('transporteurs.new', {
		url: '/:genre/new',
		params: {
			transporteur: null,
			index: null,
			fromDap: false
		},
		template: '<site-form></site-form>',
		rights: { domain: 'sitetransp', right: 'create' },
		ncyBreadcrumb: {
			parent: 'transporteurs.list({genre: "transporteurs"})',
			label: '{{ "SITES.BREADCRUMBS.SITE_NEW_TRANSPORTEUR" | translate}}'
		},
		search: 'SITES.BREADCRUMBS.SITE_NEW_TRANSPORTEUR'
	});

	$stateProvider.state('transporteurs.edit', {
		url: '/:genre/{id}/edit',
		template: '<site-form></site-form>',
		rights: { domain: 'sitetransp', right: 'update' },
		ncyBreadcrumb: {
			parent: 'transporteurs.list({genre: "transporteurs"})',
			label: '{{ "SITES.BREADCRUMBS.SITE_EDIT_TRANSPORTEUR" | translate }}'
		}
	});
}

Configure.$inject = ['$stateProvider'];
