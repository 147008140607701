import PrestationEntete from './prestation.entete.model';

export default class PrestationEnteteController {
	static $inject = ['$stateParams', 'PrestationsCommunicationService', 'PrestationsService', 'notification', '$translate'];

	constructor($stateParams, PrestationsCommunicationService, PrestationsService, notification, $translate) {
		this.$stateParams = $stateParams;
		this.PrestationsCommunicationService = PrestationsCommunicationService;
		this.PrestationsService = PrestationsService;
		this.notification = notification;
		this.$translate = $translate;
	}

	async $onInit() {
		this.loading = false;
		this.codeLoading = false;
		this.isEditMode = false;
		this.unregisterReinit = this.PrestationsCommunicationService.registerReinit(() => this.initForm());
		this.initForm();
		this.modesDeclenchement = [
			{
				id: 1,
				libelle: this.$translate.instant('PRESTATIONS.DECLENCHEMENT.TICKET')
			},
			{
				id: 2,
				libelle: this.$translate.instant('PRESTATIONS.DECLENCHEMENT.FACTURE')
			},
			{
				id: 3,
				libelle: this.$translate.instant('PRESTATIONS.DECLENCHEMENT.IND_REV_FACTURE')
			},
			{
				id: 4,
				libelle: this.$translate.instant('PRESTATIONS.DECLENCHEMENT.IND_REV_PRESTATION')
			},
			{
				id: 5,
				libelle: this.$translate.instant('PRESTATIONS.DECLENCHEMENT.IND_REV_FACTURE_ORIGINE')
			},
			{
				id: 6,
				libelle: this.$translate.instant('PRESTATIONS.DECLENCHEMENT.TICKET_RECHARGEMENT')
			},
			{
				id: 7,
				libelle: this.$translate.instant('PRESTATIONS.DECLENCHEMENT.ECO_CONTRIBUTION_TRANSP')
			},
			{
				id: 8,
				libelle: this.$translate.instant('PRESTATIONS.DECLENCHEMENT.ARRONDI')
			},
			{
				id: 9,
				libelle: this.$translate.instant('PRESTATIONS.DECLENCHEMENT.MINIMUM_FACTURATION')
			}
		];
	}

	$onDestroy() {
		this.unregisterReinit();
	}

	initForm() {
		let entete = {};

		if (this.$stateParams && this.$stateParams.id) {
			this.isEditMode = true;
			entete = this.prestation.entete;
		} else {
			this.isEditMode = false;
		}

		this.prestation.entete = new PrestationEntete(entete);
		this.getUnites();
		this.getGrillesRevision();
		this.getModesCalcul();
	}

	async getUnites() {
		this.startLoading();
		try {
			const res = await this.PrestationsService.getUnites();
			this.unites = res.items;
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getGrillesRevision() {
		this.startLoading();
		try {
			const res = await this.PrestationsService.getGrillesIndicesRevision();
			this.grillesRevision = res.items;
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getModesCalcul() {
		try {
			const res = await this.PrestationsService.getModesCalcul();
			this.modesCalculs = res;
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
		}
	}

	async checkCodeUnicity(code) {
		if (!code) {
			this.prestation.entete.codeExists = null;
			return;
		}
		this.startCodeLoading();
		try {
			if (code.match(/^[a-zA-Z0-9_]*$/)) {
				this.prestation.entete.codeExists = await this.PrestationsService.codeExists(code);
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopCodeLoading();
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	startCodeLoading() {
		this.codeLoading = true;
	}

	stopCodeLoading() {
		this.codeLoading = false;
	}
}
