import CommandesConfig from './_config/commandes.config';
import CommandesRoutes from './_config/commandes.routes';
import CommandesComponent from './components/commandes';
import CommandesService from './services/commandes.service';
import CommandesCommunicationService from './services/commandes.communication.service';

import CommandeFormComponent from './components/commande-form';
import CommandeEnteteComponent from './components/commande-entete';
import CommandeLigneComponent from './components/commande-ligne';
import CommandeComposantComponent from './components/commande-composant';

import CommandeImpressionPrixHelperComponent from './components/commande-form/commande-impression-helper';

import CommandeValidator from './components/commande-form/commande.validator';
import CommandeEnteteValidator from './components/commande-entete/commande.entete.validator';
import CommandeDeclotureValidator from './components/commande-decloture/commande.decloture.validator';

import CommandePopupComponent from './components/commande-popup';
import CommandeDeclotureComponent from './components/commande-decloture';

import TicketsService from '../../common/tickets/services/tickets.service';
import ChantiersService from '../../common/chantiers/services/chantiers.service';

const moduleName = 'app.parametrageGeneral.commandes';

angular
	.module(moduleName, [])
	.config(CommandesConfig)
	.config(CommandesRoutes)
	.service('CommandesService', CommandesService)
	.service('TicketsService', TicketsService)
	.service('CommandesCommunicationService', CommandesCommunicationService)
	.service('ChantiersService', ChantiersService)
	.component('commandes', CommandesComponent)
	.component('commandeForm', CommandeFormComponent)
	.component('commandeEntete', CommandeEnteteComponent)
	.component('commandeLigne', CommandeLigneComponent)
	.component('commandeComposant', CommandeComposantComponent)
	.component('commandePopup', CommandePopupComponent)
	.component('commandeDecloture', CommandeDeclotureComponent)
	.component('commandeImpressionPrixHelperComponent', CommandeImpressionPrixHelperComponent)
	.factory('CommandeValidator', CommandeValidator)
	.factory('CommandeEnteteValidator', CommandeEnteteValidator)
	.factory('CommandeDeclotureValidator', CommandeDeclotureValidator);

export default moduleName;
