import gestionModule from './gestion';
import commonModule from './common';
import massiaModule from './massia';
import '../assets/css/gestion.scss';

const mName = 'massia.gestion';
angular.module(mName, [massiaModule, gestionModule, commonModule]);

export default mName;

function hasCardClass(node) {
	let parent = node.parentNode;

	while (parent !== null && parent !== undefined) {
		if (parent.classList && parent.classList.contains('card')) {
			return true;
		}
		parent = parent.parentNode;
	}

	return false;
}

// Ecrire ici les ID des inputs qui ne seront pas concerné par le blockage
const InputWhitelistId = [];

document.addEventListener('keypress', (e) => {
	// La touche entrée && input
	if (e.keyCode === 13 && e.target.tagName === 'INPUT') {
		// Si dans la whiteliste, on fais rien.
		if (InputWhitelistId.includes(e.target.id)) {
			return;
		}

		// Sinon on bloque
		if (hasCardClass(e.target)) {
			e.preventDefault();
		}
	}
});
