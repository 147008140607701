import CommandeDeclotureController from './commande.decloture.controller';

export default {
	bindings: {
		modalInstance: '=',
		idCommande: '=',
		dateFinChantier: '='
	},
	template: require('./commande.decloture.html'),
	controller: CommandeDeclotureController
};