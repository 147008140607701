export default class CommandePopupController {
	static $inject = [
		'$scope',
		'$stateParams',
		'CommandesService',
		'notification',
		'ModalService',
		'$uibModal',
		'moment',
		'$translate',
		'globalizationManagementService'
	];

	constructor($scope, $stateParams, CommandesService, notification, ModalService, $uibModal, moment, $translate, globalizationManagementService) {
		this.$scope = $scope;
		this.$stateParams = $stateParams;
		this.CommandesService = CommandesService;
		this.notification = notification;
		this.ModalService = ModalService;
		this.$uibModal = $uibModal;
		this.moment = moment;
		this.$translate = $translate;

		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}

	annuler() {
		this.modalInstance.close();
	}

	async confirmer() {
		try {
			const data = await this.CommandesService.transformFacture(this.idCommande, this.dateFacture);
			if (data) {
				this.modalInstance.close(data);
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
		}
	}

	async $onInit() {
		console.log(this.idCommande);
	}
}
