import validatorBuilder from 'validator-builder';

let clientDiversEnteteValidator = null;

export default class ClientDiversEntete {
	constructor(data) {
		data = data || {};
		this.id = data.id;
		this.type = 'clientDivers';
		this.code = data.code;
		this.libelle = data.libelle;
		this.civilite = data.civilite;
		this.adresse = data.adresse;
		this.codePostal = data.codePostal;
		this.ville = data.ville;
		this.telephone = data.telephone;
		this.telephoneMobile = data.telephoneMobile;
		this.mail = data.mail;
		this.web = data.web;
		this.fax = data.fax;
		this.commentaire = data.commentaire;
		this.isBloquagePesee = data.isBloquagePesee;
		this.isOuverture = data.isOuverture;
		this.idSociete = data.idSociete;
		this.idPays = data.idPays;
		this.isEnabled = data.isEnabled;
		this.isDematBLSMS = data.isDematBLSMS;
		this.isDematBLMail = data.isDematBLMail;
		this.siret = data.siret;
	}

	isValid() {
		clientDiversEnteteValidator = clientDiversEnteteValidator || validatorBuilder.getInstanceFor('ClientDiversEntete');
		const validationResults = clientDiversEnteteValidator.validate(this);
		return validationResults.isValid;
	}
}
