class MassiaActionDirective {
	constructor(MassiaRightsService) {
		this.MassiaRightsService = MassiaRightsService;
		this.restrict = 'A';
		this.scope = {
			domain: '@',
			right: '@',
			application: '@',
			typeDirective: '@'
		};
	}

	link(scope, element, attrs) {
		const domain = scope.domain;
		const right = scope.right;
		const application = scope.application;
		const typeDirective = scope.typeDirective;
		// On vérifie si on a le droit d'aller en édition
		let canShow = true;
		canShow = this.MassiaRightsService.userHasRight(domain, right, application);
		// Sinon on retire le ui-sref ou le ng-click et on ne fait rien
		if (!canShow && !typeDirective) {
			element.removeAttr('ui-sref');
			element.off('click');
		} else if (!canShow && typeDirective) {
			element.removeAttr('ng-click');
			element.off('click');
		}
	}

	static create() {
		return new MassiaActionDirective(...arguments);
	}
}

MassiaActionDirective.create.$inject = ['MassiaRightsService'];

export default MassiaActionDirective;
