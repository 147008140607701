import Commande from './commande.model';
import CommandeEntete from '../commande-entete/commande.entete.model';
import CommandeLigne from '../commande-ligne/commande.ligne.model';
import CommandeLigneProduit from '../commande-ligne/commande.ligne.produit.model';

export default class CommandeFormController {
	static $inject = [
		'$scope',
		'$state',
		'$stateParams',
		'CommandesCommunicationService',
		'CommandesService',
		'TicketsService',
		'ChantiersService',
		'ModalService',
		'notification',
		'$location',
		'$anchorScroll',
		'$translate',
		'_',
		'moment',
		'DataShareService',
		'$filter',
		'FacturesService',
		'globalizationManagementService',
		'TemplateImpressionService',
		'$uibModal'
	];

	constructor(
		$scope,
		$state,
		$stateParams,
		CommandesCommunicationService,
		CommandesService,
		TicketsService,
		ChantiersService,
		ModalService,
		notification,
		$location,
		$anchorScroll,
		$translate,
		_,
		moment,
		DataShareService,
		$filter,
		FacturesService,
		globalizationManagementService,
		TemplateImpressionService,
		$uibModal
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.CommandesCommunicationService = CommandesCommunicationService;
		this.CommandesService = CommandesService;
		this.TicketsService = TicketsService;
		this.ChantiersService = ChantiersService;
		this.ModalService = ModalService;
		this.notification = notification;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this.$translate = $translate;
		this._ = _;
		this.moment = moment;
		this.DataShareService = DataShareService;
		this.$filter = $filter;
		this.FacturesService = FacturesService;

		this.totalHT = 0;
		this.totalTVA = 0;
		this.tvaList = [];
		this.hasTransport = false;
		this.isCommandeModifiable = true;
		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
		this.TemplateImpressionService = TemplateImpressionService;
		this.$uibModal = $uibModal;
	}

	async $onInit() {
		this.commande = {};
		this.closeAllOnglets();
		this.openFirstOnglet();
		this.sortedCommandeLignes = [];

		await this.setCommande(this.idCommande ? this.idCommande : this.$stateParams.id);
		this.produits = [];
		this.tarifs = [];
		this.selectedTypeES = '1';
		this.loadSiteCommerciaux();
		this.dateEcheanceFormule = null;
		this.messageFormule = 'Masquer produits avec formules expirées';
		this.tvaList = await this.CommandesService.getTvaList();
		this.recalculeLignes();

		if (this.commande.entete.etat == 2 || this.idCommande) {
			this.isCommandeModifiable = false;
		}
		else {
			this.isCommandeModifiable = true;
		}
	}

	async loadSiteCommerciaux() {
		this.sitesCommerciaux = await this.CommandesService.getSitesCommerciauxCombo(this.commande.entete.societeId);
		this.selectedSiteCommercial = null;
		// this.sitesProducteurs = await this.CommandesService.getSitesProducteursCombo(this.commande.entete.societeId);
		// this.selectedSiteProducteur = null;
	}

	async changeSiteCommercial() {
		this.tarifs = await this.CommandesService.getTarifs(this.selectedSiteCommercial, this.commande.entete.chantierId, this.dateEcheanceFormule);
		this.produits = [];
		// this.selectedSiteProducteur = null;
	}

	async changeSiteProducteur() {
		const tarifsIndex = this._.findIndex(this.tarifs.produits, { producteurId: this.selectedSiteProducteur });
		if (this.tarifs[tarifsIndex]) {
			this.produits = this.tarifs.produits[tarifsIndex].produits;
		} else {
			this.produits = [];
		}
		this.produits = this.tarifs.produits[tarifsIndex].produits;
	}

	changeProduitformule() {
		if (this.dateEcheanceFormule == null) {
			this.dateEcheanceFormule = this.commande.entete.dateFinChantier;
			this.messageFormule = 'Afficher produits avec formules expirées';
		} else {
			this.dateEcheanceFormule = null;
			this.messageFormule = 'Masquer produits avec formules expirées';
		}

		if (this.selectedSiteCommercial) {
			this.changeSiteCommercial();
		}
	}

	async setCommande(commandeId) {
		console.log('🚀 ~ file: commande.form.controller.js ~ line 112 ~ CommandeFormController ~ setCommande ~ commandeId', commandeId);
		if (commandeId) {
			this.commandeId = commandeId;
			this.startLoading();
			try {
				this.isEditMode = true;
				await this.setCommandeEntete(commandeId);
				this.canUpdate = false;
				if (this.commande.entete.etat == 0 || this.commande.entete.etat == 1) {
					this.canUpdate = true;
				}
			} catch (ex) {
				if (ex.status === 404) {
					this.$state.go('commandes.list', {}, { reload: true });
				}
				this.notification.error(ex.data);
			} finally {
				this.stopLoading();
			}
		} else {
			this.isEditMode = false;
			this.commande = new Commande();
			this.canUpdate = true;
		}
	}

	async setCommandeEntete(commandeId) {
		const entete = await this.CommandesService.getCommandeEnteteById(commandeId);
		this.commande.entete = new CommandeEntete(entete);
		this.sortCommandeLignes();
	}

	sortCommandeLignes() {
		const tempSortedCommandeLignes = [];
		console.log(this.commande.entete.commandeLignes);
		angular.forEach(
			this.commande.entete.commandeLignes,
			function (value, key) {
				if (!tempSortedCommandeLignes[value.typeES]) {
					tempSortedCommandeLignes[value.typeES] = [];
				}

				if (!tempSortedCommandeLignes[value.typeES][value.siteCommercialId]) {
					tempSortedCommandeLignes[value.typeES][value.siteCommercialId] = [];
				}

				if (!tempSortedCommandeLignes[value.typeES][value.siteCommercialId][value.producteurId]) {
					tempSortedCommandeLignes[value.typeES][value.siteCommercialId][value.producteurId] = [];
				}

				if (!tempSortedCommandeLignes[value.typeES][value.siteCommercialId][value.producteurId][value.produitId]) {
					tempSortedCommandeLignes[value.typeES][value.siteCommercialId][value.producteurId][value.produitId] = {
						produit: {},
						prestations: [],
						transports: []
					};
				}

				switch (value.typePrestationLie) {
					case 0:
						//Produit
						tempSortedCommandeLignes[value.typeES][value.siteCommercialId][value.producteurId][value.produitId].produit = value;
						break;
					case 1:
						//Transports
						tempSortedCommandeLignes[value.typeES][value.siteCommercialId][value.producteurId][value.produitId].transports.push(value);
						break;
					case 2:
						//Pestations
						tempSortedCommandeLignes[value.typeES][value.siteCommercialId][value.producteurId][value.produitId].prestations.push(value);
						break;
				}
			},
			this
		);
		console.log(tempSortedCommandeLignes);
		angular.forEach(
			tempSortedCommandeLignes,
			function (typeES, key) {
				angular.forEach(
					typeES,
					function (siteCommercial, key) {
						angular.forEach(
							siteCommercial,
							function (siteProducteur, key) {
								angular.forEach(
									siteProducteur,
									function (groupeProduit, key) {
										console.log(groupeProduit);
										this.sortedCommandeLignes.push(groupeProduit);
									},
									this
								);
							},
							this
						);
					},
					this
				);
			},
			this
		);
		console.log(this.sortedCommandeLignes);
	}

	// SL mécanique revue
	async addProduit() {
		// Lorsque le selectBox change vers vide on n'execute pas la fronction
		if (this.selectedProduit == null) {
			return;
		}

		const newProduit = new CommandeLigneProduit(this.selectedProduit[0], this.selectedSiteCommercial, this.selectedTypeES);

		const foundSiteCom = this._.find(this.sitesCommerciaux, { id: this.selectedSiteCommercial });
		if (typeof foundSiteCom !== 'undefined') {
			newProduit.siteCommercial = foundSiteCom.libelle;
		}

		await this.articleSelected(newProduit);

		this.sortedCommandeLignes.push({ produit: newProduit, prestations: [], transports: [] });

		this.selectedProduit = null;
		this.recalculeLignes();
	}

	validateCommande() {
		/*
		 * Verification de la quantité ADO 4415
		 */
		let validate = true;
		for (let i = 0; i < this.sortedCommandeLignes.length; i++) {
			const ligne = this.sortedCommandeLignes[i].produit;
			if (!ligne.quantiteCommandee) {
				validate = false;
			}
		}

		if (!validate) {
			this.notification.error('OFFRES.VALIDATIONS.QUANTITY.NULL');
		}

		return validate;
	}

	async enregistrer() {
		// verifier si la date de creation est bien inferieur a la date de fin du chantier
		let dateChecker = true;
		// changement de format des dates grace a Moment pour pouvoir les comparer
		const creaDate = this.moment(this.commande.entete.dateCreation, this.dateFormat);
		if (!this.commande.entete.dateFinChantier) {
			// OK donc le dateChecker passe a false
			dateChecker = false;
		} else {
			const chanFinDate = this.moment(this.commande.entete.dateFinChantier, this.dateFormat);
			if (creaDate < chanFinDate) {
				// OK donc le dateChecker passe a false
				dateChecker = false;
			}
		}

		// pour avoir le this de l entite dans la modal (this different sinon)
		const _that = this;
		// si le dateChecker est a true, c est que la date de creation est superieure a la date de fin de chantier
		if (this.validateCommande()) {
			if (dateChecker) {
				const modalInstance = this.ModalService.confirm({
					modalTitle: this.$translate.instant('COMMANDES.SAVE.TITLE'),
					modalMsg: this.$translate.instant('COMMANDES.SAVE.MESSAGE'),
					headerClass: 'modal-confirm'
				});
				const idCommande = await modalInstance.result.then(async function () {
					if (_that.commande.entete.id) {
						_that.updateCommande();
						return _that.commande.entete.id;
					}
					const res = await _that.createCommande();
					return res;
				});
				return idCommande;
			}
			if (this.commande.entete.id) {
				this.updateCommande();
				return this.commande.entete.id;
			}
			const res = await this.createCommande();
			return res;
		}

		return null;
	}

	async decloturer(newDate){
		if(newDate)
			this.commande.entete.dateLivraisonFin = newDate;

		let dateFinLiv = this.moment(this.commande.entete.dateLivraisonFin, this.dateFormat);
		let dateChantier = this.moment(this.commande.entete.dateFinChantier, this.dateFormat);
		if(dateFinLiv > dateChantier){
			let chantier = {
				entete : {
					id : this.commande.entete.chantierId,
					dateFin : this.commande.entete.dateLivraisonFin
				}
			}
			await this.ChantiersService.updateChantierDates(chantier);
		}

		if (this.checkValidity()) {
			try {
				const data = await this.TicketsService.getTicketComposant(this.commande.entete.id, 0);
				//recupration des BL associes pour determiner l'etat de la commande
				if(data.tickets && data.tickets.length > 0) {
					this.commande.entete.etat = 1; //En cours
				}
				else {
					this.commande.entete.etat = 0; //confirmee
				}
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
			const id = await this.enregistrer();

			// SL pour eviter de vider le formulaire en create
			if (id != null) {
				this.$state.go('commandes.edit', { id: id });
			}
		}
	}

	async validerEtFermer() {
		if (this.checkValidity()) {
			await this.enregistrer();
			this.backToPreviousState();
		}
	}

	async valider() {
		if (this.checkValidity()) {
			const id = await this.enregistrer();

			// SL pour eviter de vider le formulaire en create
			if (id != null) {
				this.$state.go('commandes.edit', { id: id });
			}
		}
	}

	async createCommande() {
		this.commande.entete.commandeLignes = this.commandeLignesApiFormat();
		let result = null;
		try {
			result = await this.CommandesService.createCommande(this.commande);
		} catch (ex) {
			this.notification.error('COMMANDES.NOT_CREATED');
			return result;
		}

		this.notification.success('COMMANDES.CREATED');

		return result;
	}

	commandeLignesApiFormat() {
		const lignes = [];
		angular.forEach(
			this.sortedCommandeLignes,
			function (groupe, key) {
				lignes.push(groupe.produit);

				angular.forEach(
					groupe.prestations,
					function (prestation, key) {
						lignes.push(prestation);
					},
					this
				);

				angular.forEach(
					groupe.transports,
					function (transport, key) {
						lignes.push(transport);
					},
					this
				);
			},
			this
		);

		return lignes;
	}

	async updateCommande() {
		this.commande.entete.commandeLignes = this.commandeLignesApiFormat();
		try {
			await this.CommandesService.updateCommande(this.commande);
		} catch (ex) {
			this.notification.error('COMMANDES.NOT_UPDATED');
			return;
		}

		this.notification.success('COMMANDES.UPDATED');
		this.$onInit();
	}

	openFirstOnglet() {
		this.ongletOpen = {
			isEnteteOpen: true
		};
	}

	closeAllOnglets() {
		this.ongletOpen = {
			isEnteteOpen: false
		};
	}

	annuler() {
		this.backToPreviousState();
	}

	backToPreviousState() {
		// SL qund on vient de la PréFa, si on veut revenir en arrière
		if (this.$stateParams.tag && this.$stateParams.tag == 'preFa') {
			// on récupère les données permettant de réafficher le résultat de la PréFa
			const resultPreFaFromCommande = this.DataShareService.getDataShare('preFaCommande');
			this.$state.go('prefacturation.detailvente', { obj: resultPreFaFromCommande.dataItSelf });
		} else {
			this.$state.go('commandes.list');
		}
	}

	checkValidity() {
		this.closeAllOnglets();
		let validity = true;
		let firstScroll = true;

		if (!this.commande.entete.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('entete');
				firstScroll = false;
			}
			this.ongletOpen.isEnteteOpen = true;
			validity = false;
			this.$scope.$broadcast('commandeEnteteValidations');
		}

		return validity;
	}

	scrollToOnglet(id) {
		this.$location.hash(id);
		this.$anchorScroll();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	addLigne() {
		const ligne = new CommandeLigne();
		this.commande.entete.lignes.push(ligne);
		this.recalculeLignes();
	}

	recalculeLignes() {
		this.totalHT = 0;
		this.totalTVA = 0;
		this.hasTransport = false;

		this.sortedCommandeLignes.forEach(function (ligne) {
			// Franco et quantitée
			ligne.produit.prixFranco = ligne.produit.prixVente;
			ligne.transports.forEach(function (ligneTransport) {
				if (ligne.produit.uniteId == ligneTransport.uniteId) {
					// Franco
					ligne.produit.prixFranco += ligneTransport.prixVente * 1;
					// Quantité
					ligneTransport.quantiteCommandee = ligne.produit.quantiteCommandee;
				}
			}, this);

			// calcul des HT TTC
			if (ligne.produit.prixVente && ligne.produit.quantiteCommandee && ligne.produit.tauxTVA) {
				this.totalHT += ligne.produit.prixVente * ligne.produit.quantiteCommandee;
				this.totalTVA += (ligne.produit.prixVente * ligne.produit.quantiteCommandee * ligne.produit.tauxTVA) / 100;
				ligne.prestations.forEach(function (ligne) {
					// calcul des HT TTC
					this.totalHT += ligne.prixVente * ligne.quantiteCommandee;
					this.totalTVA += (ligne.prixVente * ligne.quantiteCommandee * ligne.tauxTVA) / 100;
				}, this);
				ligne.transports.forEach(function (ligne) {
					// calcul des HT TTC
					this.totalHT += ligne.prixVente * ligne.quantiteCommandee;
					this.totalTVA += (ligne.prixVente * ligne.quantiteCommandee * ligne.tauxTVA) / 100;
				}, this);
			}

			// Transporté ou pas : si au moins une ligne a du transport
			if (ligne.transports.length > 0 && this.hasTransport != true) {
				this.hasTransport = true;
			}
		}, this);

		if (!this.hasTransport) {
			this.commande.entete.isTransporte = false;
			this.commande.entete.isFranco = false;
		}
	}

	async getElementsSelectionnables(typeArticle) {
		const filtre = {
			societeId: this.commande.entete.societeId,
			siteCommercialId: this.selectedSiteCommercial,
			clientId: this.commande.entete.clientId,
			chantierId: this.commande.entete.chantierId,
			entreeSortie: this.selectedTypeES,
			achatVente: 1,
			date: this.moment(this.commande.entete.dateCreation, this.dateFormat).format(this.dateFormat)
		};

		try {
			switch (typeArticle) {
				case 0:
					this.produitsSelectionnables = await this.CommandesService.getProduitsSelectionnables(filtre);
					this.produitsLies = await this.prepareListeElementsLies(this.produitsSelectionnables);
					break;
			}
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		}
	}

	prepareListeElementsLies(elementsATraiter) {
		const elementsSelectionnablesOrdonnes = this.$filter('orderBy')(elementsATraiter, 'producteurLibelle');
		let tmp = null;
		const temp = [];
		for (let index = 0; index < elementsSelectionnablesOrdonnes.length; index++) {
			const currentValue = elementsSelectionnablesOrdonnes[index];
			const toReturn = [];
			if (currentValue.hasOwnProperty('prestationId')) {
				currentValue.libelle = currentValue.prestationLibelle;
				currentValue.valeur = false;
				currentValue.id = currentValue.prestationId;
				toReturn.push(currentValue);
			} else if (currentValue.hasOwnProperty('transportId')) {
				currentValue.libelle = currentValue.transportLibelle;
				currentValue.valeur = false;
				currentValue.id = currentValue.transportId;
				toReturn.push(currentValue);
			} else {
				//#region Gestion des groupes
				if (tmp == null) {
					//creation du premier groupe
					tmp = currentValue.producteurId;
					toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
				} else {
					if (tmp != currentValue.producteurId) {
						//création des autres groupes
						toReturn.push({ msGroup: false });
						tmp = currentValue.producteurId;
						toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
					}
				}
				//#endregion

				// SL gestion si le produit est déjà dans la commande, on le grise
				// même produit/producteur et même site commercial
				if (this.commande.entete.commandeLignes && this.commande.entete.commandeLignes.length > 0) {
					const myIndex = this._.findIndex(this.commande.entete.commandeLignes, {
						typePrestationLie: 0,
						produitId: currentValue.produitId,
						producteurId: currentValue.producteurId,
						siteCommercialId: this.selectedSiteCommercial
					});
					if (myIndex > -1) {
						currentValue.disabled = true;
					}
				}

				currentValue.libelle = currentValue.produitCode + ' - ' + currentValue.produitLibelle;
				currentValue.valeur = false;
				currentValue.id = currentValue.produitId;

				toReturn.push(currentValue);
				if (index == elementsSelectionnablesOrdonnes.length - 1) {
					toReturn.push({ msGroup: false });
				}
			}
			temp.push(toReturn);
		}
		const sorties = _.flatten(temp);
		return new Promise((resolve) => resolve(sorties));
	}

	getElmsLies(typeArticle) {
		switch (typeArticle) {
			case 0:
				return this.produitsLies;
		}
	}

	async articleSelected(newArticle) {
		// recherche l unite de l article
		let unityObject = null;
		try {
			unityObject = await this.FacturesService.getUniteArticle(newArticle.produitId, newArticle.typePrestationLie);
		} catch (ex) {
			this.notification.error(ex.data);
		}

		if (unityObject != null) {
			newArticle.uniteLibelle = unityObject.symbole;
		}

		// recherche le tarif (Prix Standard)
		let prixArticle = null;
		try {
			prixArticle = await this.FacturesService.getPSArticle(
				newArticle.produitId,
				newArticle.typePrestationLie,
				this.commande.entete.societeId,
				newArticle.siteCommercialId,
				this.commande.entete.clientId,
				this.commande.entete.chantierId,
				newArticle.producteurId
			);
		} catch (ex) {
			// si exception businessrule, mettre 0
			this.notification.warning(ex.data);
			if (ex.status == 404) {
				newArticle.prixStandard = 0;
				newArticle.tvaId = 0;
			}
		}

		if (prixArticle != null) {
			newArticle.prixStandard = prixArticle.prixStandard;
			newArticle.prixVente = prixArticle.prixUnitaire;
			newArticle.tvaId = prixArticle.idTVA;

			if (prixArticle.prixStandard == -1) {
				newArticle.prixStandard = null;
				this.notification.warning(this.$translate.instant('FACTURES.NO_PRIX_FOUND'));
			}
		}

		if (newArticle.tvaId == 0) {
			// recherche taux TVA par défault
			let code = '';
			const filtre = {
				chantierId: this.commande.entete.chantierId,
				siteCommercialId: newArticle.siteCommercialId,
				date: this.commande.entete.dateCreation,
				achatVente: 1
			};

			switch (newArticle.typePrestationLie) {
				case 0:
				case '0': // produit
					code = 'tvaProduits';
					filtre.produitId = newArticle.produitId;
					break;
				default:
					break;
			}

			try {
				const tempTVA = await this.FacturesService.getTVAArticle(code, filtre);

				if (tempTVA.elements.length > 0) {
					const sortedListTVA = _.sortBy(tempTVA.elements, function (tvaItem) {
						return -tvaItem.dateApplication;
					});

					if (sortedListTVA[0].tvaId != null) {
						newArticle.tvaId = sortedListTVA[0].tvaId;
					}
				} else {
					// this.notification.warning(this.$translate.instant('FACTURES.TVANOTFOUND'));
				}
			} catch (ex) {
				this.notification.error(ex.data);
			}
		}

		if (newArticle.tvaId == 0) {
			this.notification.warning(this.$translate.instant('FACTURES.TVANOTFOUND'));
		}
	}

	async actualizeQuantiteLivrees() {
		try {
			await this.CommandesService.actualizeQuantiteLivrees(this.commande.entete.id);
		} catch (ex) {
			this.notification.error(ex.data);
			return;
		}
		this.notification.success('COMMANDES.ACTUALIZE');
		this.$onInit();
	}

	openHelper() {
		this.modalInstance = this.$uibModal.open({
			animation: true,
			size: 'lg',
			component: 'commandeImpressionPrixHelperComponent'
		});
	}

	cancel() {
		this.modalInstance.dismiss();
	}

	async print() {
		const res = await this.TemplateImpressionService.downloadModal('commandes');

		console.log('🚀 ~ file: commande.form.controller.js ~ line 626 ~ CommandeFormController ~ print ~ res', res);
		console.log('🚀 ~ file: commande.form.controller.js ~ line 626 ~ CommandeFormController ~ print ~ res', this);
		if (res !== 'cancel') {
			const data = {
				template: res.filename,
				lignes: this.sortedCommandeLignes,
				totalHT: this.totalHT,
				totalTVA: this.totalTVA,
				offre: this.commande.entete.offreCode,
				proposition: this.commande.entete.propositionLibelle
			};
			const template = await this.CommandesService.printCommande(this.commandeId, data);
			template.pdf = res.pdf;
			await this.TemplateImpressionService.downloadTemplate(template.filename, template, 'commandes');
		}
	}

	openPopup() {
		const _this = this;
		if (this.commande.entete.id) {
			this.$uibModal
				.open({
					template: '<commande-popup id-commande="$ctrl.idCommande" modal-instance="$ctrl.uibModalInstance"></commande-popup>',
					controller: [
						'$uibModalInstance',
						function ($uibModalInstance) {
							const $ctrl = this;
							$ctrl.uibModalInstance = $uibModalInstance;
							$ctrl.idCommande = _this.commande.entete.id;
						}
					],
					controllerAs: '$ctrl',
					size: 'xl',
					backdrop: false
				})
				.result.then(async function (result) {
					if (result) {
						_this.$state.go('factures.edit', { id: result });
					}
				});
		}
	}

	openPopupDecloture() {
		const dateFinChantier = this.moment(this.commande.entete.dateFinChantier, this.dateFormat);
		const dateFinLiv = this.moment(this.commande.entete.dateLivraisonFin, this.dateFormat);
		const dateDuJour = this.moment();
		if(dateFinLiv >= dateDuJour && (!dateFinChantier.isValid()  || dateFinChantier >= dateFinLiv)){
			//aucun conflit dans les dates on peut déclôturer
			this.decloturer();
		}
		else{
			//on ouvre un popup pour resélectionner une date valide
			const _this = this;
			if (this.commande.entete.id) {
				this.$uibModal
					.open({
						template: '<commande-decloture id-commande="$ctrl.idCommande" date-fin-livraison="$ctrl.dateFinLivraison" date-fin-chantier="$ctrl.dateFinChantier" modal-instance="$ctrl.uibModalInstance"></commande-decloture>',
						controller: [
							'$uibModalInstance',
							function ($uibModalInstance) {
								const $ctrl = this;
								$ctrl.uibModalInstance = $uibModalInstance;
								$ctrl.idCommande = _this.commande.entete.id;
								$ctrl.dateFinChantier = dateFinChantier.isValid()? dateFinChantier.format(_this.dateFormat) : null;
							}
						],
						controllerAs: '$ctrl',
						size: 'xl',
						backdrop: false
					})
					.result.then(async function (newDate) {
						if(newDate)
							_this.decloturer(newDate);
					});
			}
		}
	}
}
